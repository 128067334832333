import { TableBlockType } from 'src/components/ParametersRiskTable';

import s from './TableHeader.module.scss';

interface Props {
    type: TableBlockType;
}

export function TableHeader({ type }: Props) {
    const getTableTitle = (type: TableBlockType) => {
        if (type === 'unavailable') {
            return 'Parameters Unavailable at Result Time';
        }
        const title = type === 'increasing' ? 'Increasing' : 'Decreasing';
        return `Parameters ${title} Risk of Sepsis`;
    };

    return (
        <div className={type !== 'unavailable' ? s.tableHeader : s.tableHeaderGrey}>
            <div className={s.scoreImpact}>{getTableTitle(type)}</div>
            <div className={s.parameter}>Parameter</div>
            <div className={s.value}>Value</div>
            <div className={s.collectionTime}>{type !== 'unavailable' ? "Collection Time" : ""}</div>
        </div>
    );
}
