import { DataDriftDetailsTable } from 'src/components/DataDriftDetails/DataDriftDetailsTable';
import { DataDriftReport } from 'src/containers/OrganizationReportList/hooks';
import { formatHumanDateTime } from 'src/utils/date';

import s from './DataDriftDetails.module.scss';
import { AlertFailure } from '../AlertFailure';

interface Props {
    organizationName?: string | null;
    dataDriftReport: DataDriftReport;
}

export const DataDriftDetails = ({ organizationName, dataDriftReport }: Props) => {
    if (dataDriftReport.status === 'registered') {
        return <div>Data drift report registred</div>;
    }

    if (dataDriftReport.status === 'cancelled') {
        return <AlertFailure error={dataDriftReport.error} />;
    }

    return (
        <div>
            <div className={s.header}>
                <div className={s.title}>Data Drift Report</div>
                <div className={s.date}>{formatHumanDateTime(dataDriftReport.date)}</div>
            </div>
            <div className={s.organizationName}>{organizationName}</div>
            <div className={s.periods}>
                <div>
                    <span className={s.periodTitle}>Reference Period:</span>
                    {formatHumanDateTime(dataDriftReport.referencePeriod.start)} -{' '}
                    {formatHumanDateTime(dataDriftReport.referencePeriod.end)}
                </div>
                <div>
                    <span className={s.periodTitle}>Observed Period:</span>
                    {formatHumanDateTime(dataDriftReport.observedPeriod.start)} -{' '}
                    {formatHumanDateTime(dataDriftReport.observedPeriod.end)}
                </div>
            </div>
            <DataDriftDetailsTable dataDriftReport={dataDriftReport} />
        </div>
    );
};
