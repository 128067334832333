import { ReactNode, useState } from 'react';

import s from './SimplePopover.module.scss';

interface Props {
    title?: string;
    content: string;
    children: ReactNode;
}

export function SimplePopover({ title, content, children }: Props) {
    const [visible, setVisible] = useState(false);

    return (
        <>
            <div onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
                {children}
            </div>
            {visible && (
                <div className={s.wrapper}>
                    {title && <h2>{title}</h2>}
                    <p>{content}</p>
                </div>
            )}
        </>
    );
}
