import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DataDriftDetails } from 'src/components/DataDriftDetails';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { DataDriftReport } from 'src/containers/OrganizationReportList/hooks';

import { useOrganizationReport } from './hooks';
import s from './OrganizationReport.module.scss';

export const OrganizationReport = () => {
    const { organizationId, organizationName, dataDriftReportRD } = useOrganizationReport();

    if (!organizationId) {
        console.error('No organizationId');
        return <></>;
    }

    return (
        <BaseLayout showHeader={true} organizationId={organizationId}>
            <div className={s.container}>
                <RenderRemoteData
                    remoteData={dataDriftReportRD}
                    renderLoading={() => <DotPulseLoader />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {(dataDriftReport: DataDriftReport) => (
                        <DataDriftDetails
                            organizationName={organizationName}
                            dataDriftReport={dataDriftReport}
                        />
                    )}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
};
