import { Button, Layout } from 'antd';

import { LogoutIcon } from 'src/icons/LogoutIcon';
import { LogoImage } from 'src/images/LogoImage';

import s from './AppHeader.module.scss';
import { useAppHeader } from './useAppHeader';

const { Header } = Layout;

export function AppHeader() {
    const { userData, dropCap, email, logout } = useAppHeader();

    return (
        <Header className={s.wrapper}>
            <div className={s.leftSide}>
                <span
                    className={s.logo}
                    onClick={() => {
                        window.location.href = `/`;
                    }}
                >
                    <LogoImage />
                </span>
                <span className={s.title}>ImmunoScore</span>
            </div>
            <div className={s.rightSide}>
                {userData && (
                    <>
                        <Button type="primary" className={s.logout} onClick={logout}>
                            <div className={s.logoutInside}>
                                <LogoutIcon />
                            </div>
                            <div className={s.logoutInside}>Log out</div>
                        </Button>
                        <div className={s.nameContainer}>
                            <div className={s.nameInitial}>{dropCap}</div>
                            <span className={s.fullname}>{email}</span>
                        </div>
                    </>
                )}
            </div>
        </Header>
    );
}
