import { Menu, PageHeader } from 'antd';
import { Link } from 'react-router-dom';

import s from './OrganizationHeader.module.scss';
import { useOrganizationHeader } from './useOrganizationHeader';

interface Props {
    organizationId: string;
}

export function OrganizationHeader({ organizationId }: Props) {
    const { navigate, current, menu, defaultRoute, pathname, organizationName } =
        useOrganizationHeader(organizationId);

    return (
        <>
            <PageHeader
                className={s.header}
                onBack={() => navigate('../organizations')}
                title={organizationName}
                subTitle="Settings"
            />
            <Menu className={s.menu} selectedKeys={[current]} mode="horizontal">
                {menu.map(({ path, title, key }) => (
                    <Menu.Item key={key}>
                        <Link to={{ pathname: `${defaultRoute(pathname)}${path}` }}>{title}</Link>
                    </Menu.Item>
                ))}
            </Menu>
        </>
    );
}
