import { ParameterDelta } from 'src/containers/ImmunoScoreDetails/types';
import { DownArrowIcon } from 'src/icons/DownArrowIcon';
import { UpArrowIcon } from 'src/icons/UpArrowIcon';

interface Props {
    delta: ParameterDelta;
}

export function IndicatingArrow({ delta }: Props) {
    if (delta === ParameterDelta.H) {
        return <UpArrowIcon />;
    }
    if (delta === ParameterDelta.L) {
        return <DownArrowIcon />;
    }
    return <></>;
}
