import { CodeableConcept } from 'shared/src/contrib/aidbox';

import { Color } from './color';

export enum RiskStatusCategory {
    ResultPending = 'Result Pending',
    NoResult = 'No Result',
    VeryHigh = 'Very High',
    High = 'High',
    Low = 'Low',
    Medium = 'Medium',
}

const riskStatusCategoryMap = {
    low: RiskStatusCategory.Low,
    medium: RiskStatusCategory.Medium,
    high: RiskStatusCategory.High,
    'very high': RiskStatusCategory.VeryHigh,
};

export interface Score {
    status: OrderStatus;
    value?: number;
    interpretation?: CodeableConcept
}

export enum OrderStatus {
    Success = 'success',
    Waiting = 'waiting',
    Failure = 'failure',
}

export const getRiskStatusCategory = (score: Score) => {
    if (score.interpretation) {
        const category = score.interpretation.coding?.find(
            (item) =>
                (item.system = 'http://prenosis.com/fhir/CodeSystem/immuno-score-interpetation'),
        );
        return riskStatusCategoryMap[category?.code!];
    }
    if (score.status === OrderStatus.Waiting) {
        return RiskStatusCategory.ResultPending;
    }
    return RiskStatusCategory.NoResult;
};

export const getRiskColumnValue = (score: Score) => {
    if (score.status === OrderStatus.Success) {
        return String(score.value);
    }
    if (score.status === OrderStatus.Waiting) {
        return '?';
    }
    if (score.status === OrderStatus.Failure) {
        return '-';
    }
};

export const riskData = {
    [RiskStatusCategory.ResultPending]: {
        color: Color.Grey,
    },
    [RiskStatusCategory.NoResult]: {
        color: Color.Dark,
    },
    [RiskStatusCategory.Low]: {
        color: Color.Green,
    },
    [RiskStatusCategory.Medium]: {
        color: Color.Orange,
    },
    [RiskStatusCategory.High]: {
        color: Color.Red,
    },
    [RiskStatusCategory.VeryHigh]: {
        color: Color.DarkRed,
    },
};
