import { RequiredObservationItem } from 'src/containers/ImmunoScoreDetails/types';

interface Props {
    required: Array<RequiredObservationItem>;
}

export function useWaitingNoResult({ required }: Props) {
    const measurementsToOrder: Array<RequiredObservationItem> = [];

    const awaitingResults: Array<RequiredObservationItem> = [];

    const waitTimeHasExpired: Array<RequiredObservationItem> = [];

    const distribution = () => {
        required &&
            required.sort(sortRequiredObservationItemRequiredFirst).map((serviceRequest) => {
                if (!serviceRequest.isExternal) {
                    measurementsToOrder.push(serviceRequest);
                } else {
                    awaitingResults.push(serviceRequest);
                }
                waitTimeHasExpired.push(serviceRequest);
                return;
            });
    };

    distribution();
    return { measurementsToOrder, awaitingResults, waitTimeHasExpired };
}

function sortRequiredObservationItemRequiredFirst(
    a: RequiredObservationItem,
    b: RequiredObservationItem,
) {
    return Number(b.isRequired) - Number(a.isRequired);
}
