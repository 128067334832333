import { Tabs } from 'antd';

import s from './ArtificialIntelligence.module.scss';
import { TrainingAndValidationDataset } from '../DiagnosticInterpretation';

const { TabPane } = Tabs;

export const ArtificialIntelligence = () => {
    return (
        <div className={s.ai}>
            <Tabs defaultActiveKey="1">
                <TabPane tab="Overview" key="1">
                    <h2>Training Data</h2>
                    <p>
                        The number of patients from each hospital site used for training the Sepsis
                        ImmunoScore AI/ML model.
                    </p>
                    <table>
                        <thead className={s.tableHeader}>
                            <tr>
                                <th className={s.regularCell}>Hospital sites</th>
                                <th className={s.regularCell}>Number of patients</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={s.startCol}>
                                    Order of St. Francis (OSF) – Peoria, IL
                                </td>
                                <td className={s.regularCol}>712</td>
                            </tr>
                            <tr>
                                <td className={s.startCol}>Mercy Health – St. Louis, MO</td>
                                <td className={s.regularCol}>1061</td>
                            </tr>
                            <tr>
                                <td className={s.startCol}>
                                    Carle Foundation Hospital – Urbana, IL
                                </td>
                                <td className={s.regularCol}>593</td>
                            </tr>
                        </tbody>
                    </table>
                    <h2>Validation Data</h2>
                    <p>
                        The clinical validation study for the Sepsis ImmunoScore contained 698
                        patients from 3 hospital institutions disjoint from the algorithm’s training
                        dataset. The clinical validation study utilized a physician-adjudicated
                        chart review for sepsis-3 to determine the ground truth of sepsis.
                    </p>
                    <table>
                        <thead className={s.tableHeader}>
                            <tr>
                                <th className={s.regularCell}>Hospital sites</th>
                                <th className={s.regularCell}>Number of patients</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={s.startCol}>
                                    Beth Israel Deaconess Medical Center – Boston, MA
                                </td>
                                <td className={s.regularCol}>356</td>
                            </tr>
                            <tr>
                                <td className={s.startCol}>Jesse Brown VA – Chicago, IL</td>
                                <td className={s.regularCol}>65</td>
                            </tr>
                            <tr>
                                <td className={s.startCol}>Beaumont – Royal Oak, MI</td>
                                <td className={s.regularCol}>277</td>
                            </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Model Components" key="2">
                    {/*<h2>Model Components</h2>*/}
                    <h3>Trigger Logic</h3>
                    <p>
                        The trigger logic receives streaming data from the patient for each
                        measurement type and determines the following:
                    </p>
                    <ul>
                        <li>when the algorithm has sufficient data to produce a result</li>
                        <li>which measurements to select for use in the algorithm</li>
                    </ul>
                    <p>
                        Some parameters are required for the Sepsis ImmunoScore to generate a
                        result, and some parameters are optional.
                    </p>
                    <p>
                        The following parameters are required:
                        <div className={s.twoColContainer}>
                            <div className={s.col}>
                                <ul>
                                    <li>Systolic Blood Pressure</li>
                                    <li>Diastolic Blood Pressure</li>
                                    <li>Temperature</li>
                                    <li>Respiratory Rate</li>
                                    <li>Heart Rate</li>
                                    <li>Blood oxygen saturation</li>
                                </ul>
                            </div>
                            <div className={s.col}>
                                <ul>
                                    <li>White Blood Cell Count</li>
                                    <li>Platelet Count</li>
                                    <li>Creatinine</li>
                                    <li>Blood Urea Nitrogen</li>
                                    <li>Procalcitonin</li>
                                    <li>C-Reactive Protein</li>
                                </ul>
                            </div>
                        </div>
                    </p>
                    <p>
                        All other parameters are optional. Optional parameters are allowed a grace
                        period before the Sepsis ImmunoScore will trigger without them. The Sepsis
                        ImmunoScore will wait up to 30 minutes for an optional parameter to be
                        ordered, and then up to two hours for an ordered parameter to result.
                    </p>
                    <p>
                        The trigger logic selects measurements for use in the algorithm based on the
                        following selection methodology:
                    </p>

                    <table>
                        <thead className={s.tableHeader}>
                            <tr>
                                <th className={s.regularCell}>Parameter</th>
                                <th className={s.blueCol}>Selection Methodology</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={s.greyCol}>Systolic Blood Pressure</td>
                                <td className={s.regularCol}>Minimum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>Diastolic Blood Pressure</td>
                                <td className={s.regularCol}>Minimum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>Temperature</td>
                                <td className={s.regularCol}>Maximum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>Respiratory Rate</td>
                                <td className={s.regularCol}>Maximum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>Heart Rate</td>
                                <td className={s.regularCol}>Maximum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>Blood Oxygen Saturation</td>
                                <td className={s.regularCol}>Minimum value of last 6 hours</td>
                            </tr>
                            <tr>
                                <td className={s.greyCol}>All Others</td>
                                <td className={s.regularCol}>
                                    Most recently collected value of last 24 hours
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <h3>Imputation</h3>
                    <p>
                        Optional features may be missing when the Trigger Logic decides the
                        algorithm is ready. Those missing features are estimated based on similar
                        patients from the training data set using the available parameters for that
                        patient.
                    </p>
                    <h3>Core Model</h3>
                    <p>
                        The core of the algorithm is a fixed machine learning model trained to
                        identify Sepsis-3 patients. The output is the estimated risk of Sepsis-3.
                        This is called the Sepsis Risk Score.
                    </p>
                    <h3>Risk Category Generation</h3>
                    <p>
                        The output of the Core Model, which is the sepsis risk score, is grouped
                        into Risk Categories: Low, Medium, High, Very High.
                    </p>
                    <h3>Feature Importance</h3>
                    <p>
                        The system identifies the contribution of each feature to the overall
                        estimated Sepsis Risk Score via SHAP values. Parameters that increased the
                        sepsis risk score are depicted with a red bar, and those that decreased it
                        with a green bar. The length of each bar indicates the magnitude of the
                        contribution to the calculation. Parameter importance scores are intended
                        solely to explain the risk score calculation of the algorithm for a
                        particular case and provide transparency into the model. They are restricted
                        to parameters used by the model and are not intended to articulate the
                        clinical or biological significance of those parameters or to correspond to
                        the importance a clinician places on those same parameters in making their
                        clinical judgement.{' '}
                    </p>
                </TabPane>
                <TabPane tab="Training and Validation Demographics" key="3">
                    <TrainingAndValidationDataset />
                </TabPane>
            </Tabs>
        </div>
    );
};
