import { Tooltip } from 'antd';

import s from './ScoreImpactBar.module.scss';
import { useScoreImpactBar } from './useScoreImpactBar';

interface Props {
    scoreImpact: number;
}

export function ScoreImpactBar({ scoreImpact }: Props) {
    const { isPositive, greenScaleStyle, redScaleStyle } = useScoreImpactBar(scoreImpact);

    const roundedScoreImpact =
        scoreImpact >= 0 ? Math.ceil(scoreImpact * 100) / 100 : Math.floor(scoreImpact * 100) / 100;

    return (
        <div className={s.wrapper}>
            <div className={s.fullScaleWrapper}>
                <div className={s.wrapper}>
                    {!isPositive && (
                        <Tooltip placement="left" title={roundedScoreImpact}>
                            <div style={greenScaleStyle} />
                        </Tooltip>
                    )}
                </div>
                <div className={s.wrapper}>{isPositive && <div style={redScaleStyle} />}</div>
            </div>
        </div>
    );
}
