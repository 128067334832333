import React from 'react';

import { sharedOrganization } from 'src/sharedState';

import { AppHeader } from './AppHeader';
import { AppMenu } from './AppMenu';
import { OrganizationHeader } from './OrganizationHeader';
import { OrganizationNotFound } from '../OrganizationNotFound';

interface Props {
    children?: React.ReactChild | React.ReactChild[];
    showHeader?: boolean;
    showMainMenu?: boolean;
    showSubMenu?: boolean;
    organizationId?: string;
    mainContainerStyle?: React.CSSProperties | any; // TODO use right type
}

export function BaseLayout(props: Props) {
    const {
        showHeader = false,
        showMainMenu = false,
        showSubMenu = false,
        organizationId = false,
        mainContainerStyle = {},
    } = props;

    const organization = sharedOrganization.getSharedState();

    if (organization === null) {
        return (
            <>
                <AppHeader />
                <OrganizationNotFound />
            </>
        );
    }

    return (
        <div>
            {showHeader && <AppHeader />}
            {showMainMenu && <AppMenu menuType="main" />}
            {showSubMenu && <AppMenu menuType="organizations" />}
            {organizationId && <OrganizationHeader organizationId={organizationId} />}
            <div style={{ padding: '30px', minWidth: '900px', ...mainContainerStyle }}>
                {props.children}
            </div>
        </div>
    );
}
