import _ from 'lodash';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData/index';
import { RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { objectToDisplay } from 'src/utils/yaml';

import { AlertFailure } from '../AlertFailure';
import { DotPulseLoader } from '../DotPulseLoader';

interface ResultedObservationProps {
    debugResultRD: RemoteData;
}

export function ResultedObservation({ debugResultRD }: ResultedObservationProps) {
    return (
        <RenderRemoteData
            remoteData={debugResultRD}
            renderLoading={() => <DotPulseLoader />}
            renderFailure={(error) => <AlertFailure error={error} />}
        >
            {(debugResult) => (
                <CodeMirror
                    value={objectToDisplay(debugResult as object)}
                    options={{
                        lineNumbers: false,
                        mode: 'yaml',
                        readOnly: true,
                    }}
                />
            )}
        </RenderRemoteData>
    );
}
