import { Form, Input } from 'antd';
import { Control, Controller, ControllerProps, FieldErrors } from 'react-hook-form';

import { formaError, getErrorByFieldName } from '../utils';

interface InputFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    errors: FieldErrors;
    rules?: ControllerProps['rules'];
    style?: React.CSSProperties | any;
}

export function InputField(props: InputFieldProps) {
    const { name, label, control, errors, style, rules = {} } = props;
    const error = getErrorByFieldName(errors, name);

    return (
        <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field }) => (
                <Form.Item
                    label={label}
                    name={name}
                    style={style || {}}
                    validateStatus={error && 'error'}
                    help={formaError(error)}
                >
                    <>
                        <Input {...field} />
                    </>
                </Form.Item>
            )}
        />
    );
}
