import { Alert } from 'antd';

import s from './OrganizationNotFound.module.scss';

export const OrganizationNotFound = () => {
    return (
        <div className={s.wrapper}>
            <Alert message="Error" description={`Organization not found`} type="error" showIcon />
        </div>
    );
};
