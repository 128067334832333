import { Composition } from 'shared/src/contrib/aidbox';

import { NonClinicalIcon } from 'src/icons/NonClinicalIcon';

import s from './UsageMode.module.scss';

interface Props {
    usageMode?: Composition['usageMode'];
}

export function UsageMode({ usageMode }: Props) {
    return (
        <div className={s.modeWrapper}>
            {usageMode === 'non-clinical-use' && (
                <div className={s.nonClinicalContainer}>
                    <div className={s.modeIcon}>
                        <NonClinicalIcon />
                    </div>
                    <div className={s.modeText}>{usageMode?.toUpperCase()}</div>
                </div>
            )}
        </div>
    );
}
