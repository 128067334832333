import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess } from 'aidbox-react/lib/services/service';

import { User } from 'shared/src/contrib/aidbox';

import { getSISResources } from 'src/services/sis';

export function useUserList() {
    const [userListRD, manager] = useService(async () => {
        const response = await getSISResources<User>('User', {
            'id:not': 'admin',
            _sort: '-lastUpdated',
        });

        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }

        return mapSuccess(response, (bundle) => extractBundleResources(bundle).User);
    }, []);

    const reloadUserList = () => {
        manager.reload();
    };

    return { userListRD, reloadUserList };
}
