import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { RecommendationItem } from 'src/components/RiskCategorySummary/RecommendationItem';
import { OrderStatus, Score } from 'src/utils/score';

import { useRiskCategoryHeader } from './hooks';
import s from './RiskCategoryHeader.module.scss';

export function RiskCategoryHeader({ status, value, interpretation }: Score) {
    const { headerText, headerStyle, recommendationList } = useRiskCategoryHeader({
        status,
        value,
        interpretation,
    });

    return (
        <div>
            <div className={headerStyle}>
                {headerText} {status === OrderStatus.Waiting ? <DotPulseLoader /> : <div />}
            </div>
            {status === OrderStatus.Success &&
                recommendationList.map((recommendation) => (
                    <RecommendationItem
                        key={recommendation.diagnostic}
                        percent={recommendation.percent}
                        status={recommendation.status}
                        diagnostic={recommendation.diagnostic}
                    />
                ))}
            {status === OrderStatus.Failure && (
                <div className={s.notResulted}>Required parameters have not resulted</div>
            )}
        </div>
    );
}
