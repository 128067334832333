import { ScoreDetailsSuccess } from 'src/containers/ImmunoScoreDetails/types';

import s from './ParametersRiskTable.module.scss';
import { TableBlock } from './TableBlock';

export type TableBlockType = 'increasing' | 'decreasing' | 'unavailable';

type Props = Required<
    Pick<
        ScoreDetailsSuccess,
        'increasingParameters' | 'decreasingParameters' | 'unavailableParameters'
    >
>;

export function ParametersRiskTable({
    increasingParameters,
    decreasingParameters,
    unavailableParameters,
}: Props) {
    const hasUnavailableParameters = unavailableParameters.length > 0;

    return (
        <div className={s.wrapper}>
            <TableBlock parameters={increasingParameters} type="increasing" />
            <div className={s.separator} />
            <TableBlock parameters={decreasingParameters} type="decreasing" />
            {hasUnavailableParameters && (
                <TableBlock parameters={unavailableParameters} type="unavailable" />
            )}
            <div className={hasUnavailableParameters ? s.bottomStripeGrey : s.bottomStripe} />
        </div>
    );
}
