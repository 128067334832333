import { SVGProps } from 'react';

export const DeleteIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={12} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M11.5 3h-2V1.75a1 1 0 0 0-1-1h-5a1 1 0 0 0-1 1V3h-2a.5.5 0 0 0-.5.5V4c0 .069.056.125.125.125h.944l.386 8.172a1 1 0 0 0 .998.953h7.094a.999.999 0 0 0 .998-.953l.386-8.172h.944A.125.125 0 0 0 12 4v-.5a.5.5 0 0 0-.5-.5ZM8.375 3h-4.75V1.875h4.75V3Z"
            fill="#fff"
        />
    </svg>
);
