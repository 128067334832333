import { Menu } from 'antd';
import { useContext } from 'react';
import { Link } from 'react-router-dom';

import { InitialDataContext } from 'src/containers/App/useApp';
import { getUserRole } from 'src/containers/UserList/utils';

import s from './AppMenu.module.scss';
import { MenuItem, MenuType, useAppMenu } from './useAppMenu';

interface Props {
    menuType: MenuType;
}

export function AppMenu({ menuType }: Props) {
    const { selectedKey, handleClick, menuList } = useAppMenu(menuType);

    const contextData = useContext(InitialDataContext);
    const user = contextData?.user;
    if (!user) {
        return <></>;
    }

    return (
        <Menu
            className={menuType === 'main' ? s.menuMain : s.menuActiveArchived}
            selectedKeys={[selectedKey]}
            mode="horizontal"
            onClick={handleClick}
        >
            {menuList[menuType].map(({ path, title, key, roles }: MenuItem) => {
                const show = !roles || roles.includes(getUserRole(user));
                if (show) {
                    return (
                        <Menu.Item key={key}>
                            <Link to={path}>{title}</Link>
                        </Menu.Item>
                    );
                }
            })}
        </Menu>
    );
}
