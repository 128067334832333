import { Empty, Table, Tag } from 'antd';
import { NavigateFunction } from 'react-router-dom';

import s from './DataDriftListTable.module.scss';

interface DataDriftListTableProps {
    data: {
        id: string;
        date: string;
        observedPeriod: string;
        referencePeriod: string;
    }[];
    navigate: NavigateFunction;
}

export const DataDriftListTable = ({ data, navigate }: DataDriftListTableProps) => {
    const columns = [
        {
            title: 'Report date',
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: 'Observed Period',
            dataIndex: 'observedPeriod',
            key: 'observedPeriod',
        },
        {
            title: 'Reference Period',
            dataIndex: 'referencePeriod',
            key: 'referencePeriod',
        },
        {
            title: 'Status',
            key: 'status',
            width: 70,
            dataIndex: 'status',
            render: (status: string) => <Tag key={status}>{status.toUpperCase()}</Tag>,
        },
    ];

    if (data.length === 0) {
        return (
            <Empty
                style={{ marginTop: 125 }}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 60,
                }}
                description={<span>No reports data</span>}
            />
        );
    }

    return (
        <Table
            rowClassName={s.row}
            columns={columns}
            dataSource={data}
            onRow={(record, rowIndex) => {
                return {
                    onClick: () => navigate(`./${record.id}`),
                };
            }}
        />
    );
};
