import { notification } from 'antd';

import { NormalRange } from 'shared/src/contrib/aidbox';

import { hasOwnProperty } from 'src/utils/common';

import { featureIsGroup, FeatureItem, Features } from '../ImmunoScoreDetails/types';

export function toFormValues(
    features: Features,
    normalRangeList: NormalRange[],
    organizationId: string,
) {
    let output: Features = {};
    Object.entries(features).map(([code, item]) => {
        if (!featureIsGroup(item)) {
            let normalRange = normalRangeList.find(
                (normalRange) => normalRange.prenosisObservation.code === code,
            );
            if (!normalRange) {
                normalRange = prepareNormalRange(organizationId, code, {
                    mode: item.normalRange?.mode,
                });
            }
            item['normalRange'] = normalRange;
        }
        output[code] = item;
    });
    return output;
}

function validateFeature(code: string, item: FeatureItem) {
    if (hasOwnProperty(item, 'normalRange')) {
        const low = item.normalRange.low;
        const high = item.normalRange.high;

        if (low !== undefined && low < 0) {
            notification.error({
                message: `Low value of the code ${code} cannot be less than zero`,
            });
            return 'error';
        }

        if (high !== undefined && high < 0) {
            notification.error({
                message: `High value of the code ${code} cannot be less than zero`,
            });
            return 'error';
        }

        if (high !== undefined && low !== undefined && high < low) {
            notification.error({
                message: `High value of the code ${code} cannot be less than low value`,
            });
            return 'error';
        }

        if (high !== undefined && low !== undefined && high === low) {
            notification.error({
                message: `High value of the code ${code} cannot be equal to low value`,
            });
            return 'error';
        }
    }
}

export function formValuesToResources(formValues: Features, organizationId: string): NormalRange[] {
    const normalRangeList: NormalRange[] = [];
    Object.entries(formValues).forEach(([code, item]) => {
        if (validateFeature(code, item) === 'error') {
            return;
        }
        if (featureIsGroup(item)) {
            return;
        }
        let resourceData = item.normalRange;
        if (!resourceData) {
            return;
        }
        if (!resourceData.id) {
            resourceData = prepareNormalRange(organizationId, code, resourceData);
        }
        normalRangeList.push(resourceData as NormalRange); // TODO refactor type
    });
    return normalRangeList;
}

export function prepareNormalRange(
    organizationId: string,
    code: string,
    partial: Partial<NormalRange>,
): NormalRange {
    const resource: NormalRange = {
        resourceType: 'NormalRange',
        organization: {
            id: organizationId,
            resourceType: 'Organization',
        },
        prenosisObservation: {
            code: code,
        },
    };
    const mode = partial.mode;
    if (mode) {
        resource['mode'] = mode;
    }
    const low = partial.low && +partial.low;
    const high = partial.high && +partial.high;
    if (low !== undefined && !isNaN(low)) {
        resource['low'] = low;
    }
    if (high !== undefined && !isNaN(high)) {
        resource['high'] = high;
    }
    return resource;
}

export function calcDividerValuesArray(valuesArray: any[]) {
    const dividerValuesIndexArray: number[] = [];
    valuesArray.map((el: {}, index) => {
        if (!hasOwnProperty(el[1], 'isGroup')) {
            dividerValuesIndexArray.push(index);
        }
    });
    return dividerValuesIndexArray;
}
