import { NormalizationRule } from 'shared/src/contrib/aidbox';

import { featureIsGroup, Features } from '../ImmunoScoreDetails/types';

export function toFormData(features: Features, ruleList: NormalizationRule[]): Features {
    const formData = {};
    Object.entries(features).forEach(([code, item]) => {
        if (!featureIsGroup(item)) {
            const rulesByCode = ruleList.filter(
                (rule) => rule.prenosisObservation.code === code,
            );
            item['rules'] = rulesByCode.length ? rulesByCode : [];
        }
        formData[code] = item;
    });
    return formData;
}

export function generateRule(prenosisObservationCode: string, organizationId: string): NormalizationRule {
    return {
        resourceType: 'NormalizationRule',
        externalObservation: {
            code: '',
        },
        prenosisObservation: {
            code: prenosisObservationCode,
        },
        organization: {
            id: organizationId,
            resourceType: 'Organization',
        },
        mapping: {
            id: '',
            resourceType: 'Mapping',
        },
    };
}
