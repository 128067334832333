import { Table } from 'antd';

import { DataDriftReport, Result } from 'src/containers/OrganizationReportList/hooks';

import s from './DataDriftDetailsTable.module.scss';

interface Props {
    dataDriftReport: DataDriftReport;
}

interface Measurement {
    pvalue: number;
    isSuccess: string;
    statistic: number;
}

type DataDriftDetailList = {
    id: string;
    featureName: string;
    resultDistribution: JSX.Element;
    resultAvailability: JSX.Element;
    orderAvailability: JSX.Element;
}[];

type DataType =
    | 'measurmentResultDistribution'
    | 'measurmentResultAvailability'
    | 'measurmentOrderAvailability';

export const DataDriftDetailsTable = ({ dataDriftReport }: Props) => {
    const columns = [
        {
            title: 'Feature',
            dataIndex: 'featureName',
            key: 'featureName',
            width: '150px',
        },
        {
            title: 'Measurement result distribution',
            dataIndex: 'resultDistribution',
            key: 'resultDistribution',
        },
        {
            title: 'Measurement result availability',
            dataIndex: 'resultAvailability',
            key: 'resultAvailability',
        },
        {
            title: 'Measurement order availability',
            dataIndex: 'orderAvailability',
            key: 'orderAvailability',
        },
    ];

    const checkPassOrFail = (measurment: Measurement) => {
        if (measurment?.isSuccess === 'true') {
            return 'Pass';
        }

        return 'Fail';
    };

    const roundingValue = (value: number) => Math.round(value * 100) / 100;

    const defineStyle = (passOrFail: 'Pass' | 'Fail') =>
        passOrFail === 'Fail' ? { color: '#ff0000' } : {};

    const displayData = (key: string, result: Result, dataType: DataType) => {
        const passOrFail = checkPassOrFail(result[key][dataType]);

        const text = `${passOrFail} (stat=${roundingValue(
            result[key][dataType].statistic,
        )}, p=${roundingValue(result[key][dataType].pvalue)})`;

        return <div style={defineStyle(passOrFail)}>{text}</div>;
    };

    const result = dataDriftReport.result;

    const calcDataDriftDetailList = () => {
        const dataDriftDetailList: DataDriftDetailList = [];

        for (const key in result) {
            dataDriftDetailList.push({
                id: key,
                featureName: key.toUpperCase(),
                resultDistribution: displayData(key, result, 'measurmentResultDistribution'),
                resultAvailability: displayData(key, result, 'measurmentResultAvailability'),
                orderAvailability: displayData(key, result, 'measurmentOrderAvailability'),
            });
        }

        return dataDriftDetailList.sort((a: { featureName: string }, b: { featureName: string }) =>
            a.featureName.localeCompare(b.featureName),
        );
    };

    return (
        <Table
            columns={columns}
            dataSource={calcDataDriftDetailList()}
            rowClassName={s.row}
            rowKey={(record) => record.featureName}
            style={{ width: '60vw', marginTop: 25 }}
            pagination={false}
        />
    );
};
