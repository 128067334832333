import { useContext } from 'react';

import { InitialDataContext } from 'src/containers/App/useApp';
import { baseLogout, getSessionid } from 'src/services/auth';

const sessionId = getSessionid();

export const useAppHeader = () => {
    const contextData = useContext(InitialDataContext);

    const userData = contextData?.user;

    const email = userData !== null && userData?.email || userData?.id || 'email';

    const dropCap = email && email.length > 0 && email[0].toUpperCase();

    const logout = (): void => {
        console.log(JSON.stringify({ text: 'User logged out', sessionId }));
        baseLogout();
    };

    return { userData, dropCap, email, logout };
};
