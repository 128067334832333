import { notification, RadioChangeEvent } from 'antd';
import { set } from 'lodash';
import { useState } from 'react';

import { isSuccess } from 'aidbox-react/lib/libs/remoteData';

import { User } from 'shared/src/contrib/aidbox';

import { saveSISResource } from 'src/services/sis';

import { getUserRole, UserRoleValue } from './utils';

export function updateUserRole(user: User, role: UserRoleValue): User {
    set(user, ['data', 'admin'], undefined);
    set(user, ['data', 'clinicAdmin'], undefined);
    set(user, ['data', 'immunoScoreViewer'], undefined);

    if (role !== 'unprivileged') {
        user.data[role] = true;
    }
    return user;
}

export function useUserRoleModal(reloadUserList: () => void) {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [currentUser, setCurrentUser] = useState<User | null>(null);
    const [currentRole, setCurrentRole] = useState<UserRoleValue | null>(null);

    const onRoleChange = (e: RadioChangeEvent) => {
        setCurrentRole(e.target.value);
    };

    function showUserRoleModal(user: User) {
        setCurrentUser(user);
        setCurrentRole(getUserRole(user));
        setModalVisible(true);
    }

    function closeModal() {
        setCurrentUser(null);
        setCurrentRole(null);
        setModalVisible(false);
    }

    async function saveUserRole() {
        if (!currentUser) {
            console.error('No current user');
            return;
        }
        if (!currentRole) {
            console.error('No current role');
            return;
        }
        const updatedUser = updateUserRole(currentUser, currentRole);
        const response = await saveSISResource(updatedUser);
        if (isSuccess(response)) {
            notification.success({
                message: 'Updated',
            });
            closeModal();
            reloadUserList();
        }
    }

    return {
        modalVisible,
        showUserRoleModal,
        closeModal,
        currentUser,
        saveUserRole,
        currentRole,
        onRoleChange,
    };
}
