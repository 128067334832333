import { Input } from 'antd';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DataDriftDownload } from 'src/components/DataDriftDownload';
import { DataDriftListTable } from 'src/components/DataDriftListTable';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { SearchIcon } from 'src/icons/SearchIcon';

import { useOrganizationReportList } from './hooks';
import s from './OrganizationReportList.module.scss';

export const OrganizationReportList = () => {
    const {
        organizationId,
        dataDriftListRD,
        navigate,
        searchText,
        setSearchText,
        updateDataDriftList,
        setUpdateDataDriftList,
        updateSearchParams,
    } = useOrganizationReportList();

    if (!organizationId) {
        console.error('No organizationId');
        return <></>;
    }

    return (
        <BaseLayout showHeader={true} organizationId={organizationId}>
            <div className={s.wrapper}>
                <DataDriftDownload
                    updateDataDriftList={updateDataDriftList}
                    setUpdateDataDriftList={setUpdateDataDriftList}
                />
                <div className={s.container}>
                    <Input
                        prefix={
                            <div className={s.searchIcon}>
                                <SearchIcon />
                            </div>
                        }
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        onPressEnter={updateSearchParams}
                        style={{ marginBottom: 24 }}
                        allowClear
                    />
                    <RenderRemoteData
                        remoteData={dataDriftListRD}
                        renderLoading={() => <DotPulseLoader />}
                        renderFailure={(error) => <AlertFailure error={error} />}
                    >
                        {(data) => <DataDriftListTable data={data} navigate={navigate} />}
                    </RenderRemoteData>
                </div>
            </div>
        </BaseLayout>
    );
};
