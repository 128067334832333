import util from 'util';

import { service } from 'aidbox-react/lib/services/service';

// Usage example
// Simple: console.log('some text');
// Nested: console.log(JSON.stringify({ text: 'some text', sessionId: 'someId' }));

type Severity = 'FATAL' | 'ERROR' | 'WARNING' | 'DEBUG' | 'INFO' | 'LOG';

export function sendLog(level: Severity, message: any) {
    const app = 'SPA-sis';
    const version = (window as any).__SPACONFIG__.APP_VERSION;

    service({
        method: 'POST',
        url: '$loggy',
        data: {
            type: `${app}-${level}`,
            v: version,
            message: {
                message: message,
                meta: {
                    version: version,
                    app: app,
                    level: level,
                    logger: app,
                },
            },
        },
    });
}

export function patchConsole() {
    function createConsoleWrapper(level: string): (originalConsoleMethod: () => void) => void {
        return function consoleWrapper(originalConsoleMethod: () => void): () => void {
            let severity: Severity;
            switch (level) {
                case 'debug':
                    severity = 'DEBUG';
                    break;
                case 'error':
                    severity = 'ERROR';
                    break;
                case 'info':
                    severity = 'INFO';
                    break;
                case 'warn':
                    severity = 'WARNING';
                    break;
                default:
                    severity = 'LOG';
            }

            return function (this: typeof console): void {
                sendLog(severity, util.format.apply(undefined, arguments as any));

                originalConsoleMethod.apply(this, arguments as any);
            };
        };
    }

    for (const level of ['debug', 'info', 'warn', 'error', 'log']) {
        const original = console[level] as () => any;

        console[level] = createConsoleWrapper(level)(original);
    }
}

export function patchWebErrorHandler() {
    (global as any).onerror = (...args: any) => {
        sendLog('ERROR', util.format.apply(undefined, args as any));
        return null;
    };
}
