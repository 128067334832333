import { Checkbox, Form } from 'antd';
import { Control, Controller, ControllerProps } from 'react-hook-form';

import { Organization } from 'shared/src/contrib/aidbox';

interface CheckBoxFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    rules?: ControllerProps['rules'];
    style?: React.CSSProperties | any;
}

export function CheckBoxField(props: CheckBoxFieldProps) {
    const { name, label, control, style, rules = {} } = props;

    function fromFormValue(formValue: Organization['mode']) {
        return formValue === 'clinical-use';
    }

    function toFormValue(checkBoxValue: boolean) {
        return checkBoxValue ? 'clinical-use' : 'non-clinical-use';
    }

    return (
        <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field }) => (
                <Form.Item label={label} name={name} style={style || {}}>
                    <>
                        <Checkbox
                            checked={fromFormValue(field.value)}
                            onChange={(e) => field.onChange(toFormValue(e.target.checked))}
                        >
                            Clinical-use
                        </Checkbox>
                    </>
                </Form.Item>
            )}
        />
    );
}
