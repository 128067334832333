import { ParameterDelta, ScoreParameter } from 'src/containers/ImmunoScoreDetails/types';
import { HelpIcon } from 'src/icons/HelpIcon';

import { IndicatingArrow } from './IndicatingArrow';
import s from './ScoreValue.module.scss';
import { SimplePopover } from '../../../SimplePopover';

interface Props {
    parameter: ScoreParameter;
}

export function ScoreValue({ parameter }: Props) {
    const { value, unit, delta, isImputed, stalenessHours } = parameter;

    const popoverTextNoImputable =
        `There was no value within ${stalenessHours} hours of the ImmunoScore\n` +
        'result time. Therefore the value was estimated based on\n' +
        'similar patients from the training data using available\n' +
        'parameters for the patient.';

    if (isImputed) {
        return (
            <div className={s.value}>
                <span>Was unavailable</span>
                <div className={s.help}>
                    <SimplePopover content={popoverTextNoImputable}>
                        <HelpIcon />
                    </SimplePopover>
                </div>
            </div>
        );
    }
    return (
        <div
            className={s.value}
            style={delta !== ParameterDelta.N ? { color: '#ff0000' } : { color: '#000000' }}
        >
            {isImputed && <span className={s.assumedText}>Assumed to be</span>}
            <IndicatingArrow delta={delta} />
            <span style={delta !== ParameterDelta.N ? { marginLeft: '7px' } : {}}>
                {displayValue(value)} {unit}
            </span>
        </div>
    );
}

function displayValue(value: ScoreParameter['value']) {
    if (typeof value === 'number') {
        return value ? +value.toFixed(2) : '';
    }
    return value;
}
