import { ScoreParameter } from 'src/containers/ImmunoScoreDetails/types';

import { CollectionTimeCell } from './CollectionTimeCell';
import { ScoreImpactBar } from './ScoreImpactBar';
import { ScoreParameterCell } from './ScoreParameterCell';
import { ScoreValue } from './ScoreValue';
import s from './TableItem.module.scss';

interface Props {
    parameterItem: ScoreParameter;
}

export function TableItem({ parameterItem }: Props) {
    const { display, code, collectionTime, scoreImpact, isImputed } = parameterItem;

    const showScoreImpactBar = !isImputed;

    return (
        <div className={s.tableItem}>
            <div className={s.scoreImpact}>
                {showScoreImpactBar && <ScoreImpactBar scoreImpact={scoreImpact} />}
            </div>
            <ScoreParameterCell display={display} code={code} />
            <ScoreValue parameter={parameterItem} />
            <CollectionTimeCell collectionTime={collectionTime} />
        </div>
    );
}
