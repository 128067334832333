interface ParameterInfo {
    [key: string]: {
        content: string;
    };
}

export const parameterInfo: ParameterInfo = {
    il6: {
        content: 'IL-6 information',
    },
    crp: {
        content: 'C-Reactive Protein (CRP) is a protein that is produced by the liver whose level rises due to inflammation. Studies have shown that CRP when combined with temperature, can increase the detection of infection',
    },
    pct: {
        content:
            'Procalcitonin (PCT) is released by tissues into the bloodstream during the presence of inflammation. Bacterial infections have shown to have a large impact on PCT synthesis, as bacterial endotoxins and cytokines facilitate host cells’ PCT secretion.',
    },
};
