import * as React from 'react';

export function LogoutIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.563 10.438h-1.099a.246.246 0 00-.192.09 5.527 5.527 0 01-4.266 2.003 5.512 5.512 0 01-3.915-1.62 5.52 5.52 0 01-1.188-1.757A5.477 5.477 0 011.47 7 5.505 5.505 0 013.091 3.09a5.529 5.529 0 013.916-1.62 5.53 5.53 0 014.266 2.003c.046.057.118.09.191.09h1.099c.098 0 .16-.11.104-.192A6.721 6.721 0 006.99.282 6.711 6.711 0 00.282 7.07a6.723 6.723 0 006.725 6.65 6.723 6.723 0 005.66-3.09.125.125 0 00-.104-.192zm1.389-3.536l-2.217-1.75a.125.125 0 00-.203.099v1.187H6.625a.125.125 0 00-.125.125v.875c0 .069.057.125.125.125h4.907v1.188c0 .104.121.164.203.098l2.217-1.75a.124.124 0 00.035-.153.124.124 0 00-.035-.044z"
                fill="#fff"
            />
        </svg>
    );
}
