import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { SearchParams } from 'aidbox-react/lib/services/search';
import { mapSuccess } from 'aidbox-react/lib/services/service';

import { useOrganizationId } from 'src/containers/OrganizationDetails/hooks';
import { getSISResources } from 'src/services/sis';
import { formatHumanDateTime } from 'src/utils/date';
import { convertStringToParams } from 'src/utils/search';

export interface Result {
    measurmentOrderAvailability: {
        pvalue: number;
        isSuccess: string;
        statistic: number;
    };
    measurmentResultAvailability: {
        pvalue: number;
        isSuccess: string;
        statistic: number;
    };
    measurmentResultDistribution: {
        pvalue: number;
        isSuccess: string;
        statistic: number;
    };
}

export interface DataDriftReport {
    date: string;
    observedPeriod: {
        start: string;
        end: string;
    };
    referencePeriod: {
        start: string;
        end: string;
    };
    organization: {
        id: string;
    };
    id: string;
    resourceType: string;
    status: string;
    result?: Result;
    error?: string;
}

export const useOrganizationReportList = () => {
    const organizationId = useOrganizationId();

    const navigate = useNavigate();

    const defaultSearchText = '_count=20&_page=1&_sort=-lastUpdated&date=ge2022-01-01';

    const [searchText, setSearchText] = useState(defaultSearchText);

    const [searchParams, setSearchParams] = useState<SearchParams>(
        convertStringToParams(defaultSearchText),
    );

    const updateSearchParams = useCallback(() => {
        setSearchParams(convertStringToParams(searchText));
    }, [searchText]);

    const [updateDataDriftList, setUpdateDataDriftList] = useState(false);

    const [dataDriftListRD] = useService(async () => {
        const response = await getSISResources<DataDriftReport>('DataDriftReport', {
            organization: organizationId,
            ...searchParams,
        });

        return mapSuccess(response, (bundle) =>
            extractBundleResources(bundle).DataDriftReport.map((report) => ({
                id: report.id,
                date: formatHumanDateTime(report.date),
                observedPeriod: `${formatHumanDateTime(
                    report.observedPeriod.start,
                )} - ${formatHumanDateTime(report.observedPeriod.end)}`,
                referencePeriod: `${formatHumanDateTime(
                    report.referencePeriod.start,
                )} - ${formatHumanDateTime(report.referencePeriod.end)}`,
                status: report.status,
            })),
        );
    }, [searchParams, updateDataDriftList]);

    return {
        organizationId,
        dataDriftListRD,
        navigate,
        searchText,
        setSearchText,
        updateSearchParams,
        updateDataDriftList,
        setUpdateDataDriftList,
    };
};
