import { Alert, Table } from 'antd';
import { NavigateFunction } from 'react-router-dom';

import { ImmunoScoreTableItem } from 'src/containers/ImmunoScoreList/index';
import { formatHumanDateTime } from 'src/utils/date';
import { getRiskColumnValue, getRiskStatusCategory, Score } from 'src/utils/score';

import s from './ImmunoScoreListTable.module.scss';
import { ScoreColumn } from './ScoreColumn';

interface Props {
    tableData: Array<ImmunoScoreTableItem>;
    navigate: NavigateFunction;
}

export function ImmunoScoreListTable({ tableData, navigate }: Props) {
    const columns = [
        {
            title: 'Score',
            dataIndex: 'score',
            width: 64,
            render: (score: Score) => (
                <ScoreColumn
                    score={getRiskColumnValue(score)}
                    category={getRiskStatusCategory(score)}
                />
            ),
        },
        {
            title: 'Risk',
            dataIndex: 'score',
            width: 123,
            render: (score: Score) => <span className={s.text}>{getRiskStatusCategory(score)}</span>,
        },
        {
            title: 'Order Time',
            dataIndex: 'orderTime',
            width: 140,
            render: (text: string) => <span className={s.text}>{text ? formatHumanDateTime(text) : '-'}</span>,
        },
        {
            title: 'Result Time',
            dataIndex: 'resultTime',
            width: 140,
            render: (text: string) => (
                <span className={s.resultTimeColumn}>{text ? formatHumanDateTime(text) : '-'}</span>
            ),
        },
        {
            title: 'Patient ID',
            dataIndex: 'patientId',
            width: 316,
            render: (text: string) => <span className={s.text}>{text}</span>
        },
        {
            title: 'Organization ID',
            dataIndex: 'hospitalId',
            width: 316,
            render: (text: string) => <span className={s.text}>{text}</span>
        },
    ];

    if (tableData.length > 0) {
        return (
            <Table<ImmunoScoreTableItem>
                rowClassName={s.row}
                onRow={(record) => {
                    return {
                        onClick: () => navigate(`./${record.id}`),
                    };
                }}
                columns={columns}
                dataSource={tableData}
                rowKey={(composition) => composition.id!}
                bordered={true}
            />
        );
    }

    return (
        <Alert
            message="No search results"
            description="No results matching your request found"
            type="info"
            showIcon
        />
    );
}
