import * as React from 'react';

export function UpArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={6} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 7L3 0 0 7h2.2v7h1.6V7H6z"
                fill="#FF3F3D"
            />
        </svg>
    );
}
