import { Button, Divider, Form } from 'antd';

import { Organization } from 'shared/src/contrib/aidbox';

import { CheckBoxField } from 'src/components/fields/CheckBoxField';
import { InputField } from 'src/components/fields/InputField';
import { RangePickerField } from 'src/components/fields/RangePickerField';
import { SaveIcon } from 'src/icons/SaveIcon';

import { useOrganizationForm } from './hooks';
import s from './Organization.module.scss';
import { OrganizationConfig } from './types';

export interface OrganizationFormProps {
    organization: Organization | null;
    organizationConfig: OrganizationConfig | null;
}

export const OrganizationForm = ({ organization, organizationConfig }: OrganizationFormProps) => {
    const { control, errors, handleSubmit, onSubmit } = useOrganizationForm({
        organization,
        organizationConfig,
    });

    return (
        <Form onFinish={handleSubmit(onSubmit)} className={s.formContainer}>
            <div className={s.dividerName}>
                <Divider orientation="left">Name</Divider>
            </div>
            <div className={s.nameInput}>
                <InputField
                    name="organization.name"
                    control={control}
                    errors={errors}
                    label={'Name'}
                    rules={{ required: true }}
                />
            </div>

            <div className={s.dividerMode}>
                <Divider orientation="left">Mode</Divider>
            </div>

            <div className={s.mode}>
                <CheckBoxField name="organization.mode" control={control} />
            </div>

            <div className={s.dividerDataDrift}>
                <Divider orientation="left">Data drift report settings</Divider>
            </div>

            <div className={s.dataDriftSettings}>
                <RangePickerField
                    name="organizationConfig.referencePeriod"
                    control={control}
                    label="Reference period"
                    rules={{
                        required: true,
                        validate: {
                            noEmptyRefPeriod: (period) => {
                                if (period.start.length && period.end.length) {
                                    return true;
                                }
                                return false;
                            },
                        },
                    }}
                    errors={errors}
                />
            </div>

            <div className={s.divider}>
                <Divider />
            </div>

            <div className={s.saveContainer}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        <div className={s.saveButton}>
                            <div className={s.saveIcon}>
                                <SaveIcon />
                            </div>
                            <div>Save</div>
                        </div>
                    </Button>
                </Form.Item>
            </div>
        </Form>
    );
};
