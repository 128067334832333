import { useEffect } from 'react';

import { BaseLayout } from 'src/components/BaseLayout';
import { generateRandomString, OAuth2AuthCodeAuthorize } from 'src/services/auth';

import s from './SignIn.module.scss';

export function SignIn() {
    const codeVerifier = generateRandomString(70);
    sessionStorage.setItem('code_verifier', codeVerifier);

    useEffect(() => {
        OAuth2AuthCodeAuthorize({ codeVerifier, state: '' });
    });

    return (
        <BaseLayout showHeader={true} mainContainerStyle={{ padding: 0 }}>
            <div className={s.wrapper}></div>
        </BaseLayout>
    );
}
