import { RequiredObservationItem } from 'src/containers/ImmunoScoreDetails/types';
import { RedWarningIcon } from 'src/icons/RedWarningIcon';
import { WarningIcon } from 'src/icons/WarningIcon';
import { formatHumanDateTime } from 'src/utils/date';

import s from '../WaitingNoResult.module.scss';

interface Props {
    measurementsToOrder: RequiredObservationItem[];
    awaitingResults: RequiredObservationItem[];
}

export const WaitingStatus = ({ measurementsToOrder, awaitingResults }: Props) => (
    <>
        {measurementsToOrder.length ? (
            <div className={s.measurementsContainer}>
                <div className={s.title}>
                    <div>Measurements</div>
                    <div>to Order</div>
                </div>
                <div className={s.items}>
                    {measurementsToOrder.map((item, index) => (
                        <div
                            key={index}
                            className={s.item}
                            style={
                                index !== measurementsToOrder.length - 1
                                    ? {
                                          borderBottom: '1px solid rgba(116, 140, 151, 0.15)',
                                      }
                                    : {}
                            }
                        >
                            <div className={s.display}>{item.display}</div>
                            {item.message && <FeatureResultMessage message={item.message} />}
                            <div className={s.helpText}>{item.helpText}</div>
                        </div>
                    ))}
                </div>
            </div>
        ) : undefined}
        {awaitingResults.length ? (
            <div className={s.awaitingResultsContainer}>
                <div className={s.title}>
                    <div>Awaiting Results</div>
                </div>
                <div className={s.items}>
                    {awaitingResults.map((item, index) => (
                        <div
                            key={index}
                            className={s.item}
                            style={index !== awaitingResults.length - 1 ? borderStyle : {}}
                        >
                            <div className={s.display}>{item.display}</div>
                            {item.orderTime && (
                                <>
                                    {item.message && (
                                        <FeatureResultMessage message={item.message} isRequired={item.isRequired} />
                                    )}
                                    <div className={s.helpText}>
                                        Ordered at {formatHumanDateTime(item.orderTime)}
                                    </div>
                                </>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        ) : undefined}
    </>
);

const borderStyle = {
    borderBottom: '1px solid rgba(116, 140, 151, 0.15)',
};

export interface FeatureResultMessageProps {
    message: string;
    isRequired?: boolean
}

export function FeatureResultMessage({ message }: FeatureResultMessageProps) {
    const isRequired = message === 'Required for ImmunoScore';

    return (
        <div className={s.message}>
            <span className={s.icon}>{isRequired ? <RedWarningIcon /> : <WarningIcon />}</span>
            <span>{message}</span>
        </div>
    );
}
