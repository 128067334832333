import { SetStateAction, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { UserRoleValue } from 'src/containers/UserList/utils';

export interface MenuItem {
    key: string;
    path: string;
    title: string;
    roles?: UserRoleValue[];
}

export type MenuType = 'main' | 'organizations';

interface Event {
    key: SetStateAction<string>;
}

const mainMenu: MenuItem[] = [
    {
        key: 'organizations',
        path: '/organizations',
        title: 'Organizations',
        roles: ['clinicAdmin', 'admin'],
    },
    {
        key: 'immunoscore',
        path: '/immunoscore',
        title: 'ImmunoScore',
        roles: ['immunoScoreViewer', 'clinicAdmin', 'admin'],
    },
    {
        key: 'users',
        path: '/users',
        title: 'Users',
        roles: ['admin'],
    },
];

const organizationsMenu: MenuItem[] = [
    {
        key: 'active',
        path: '/organizations',
        title: 'Active',
        roles: ['clinicAdmin', 'admin'],
    },
    {
        key: 'archived',
        path: '/organizations?archived=true',
        title: 'Archived',
        roles: ['clinicAdmin', 'admin'],
    },
];

const menuList = {
    main: mainMenu,
    organizations: organizationsMenu,
};

export const useAppMenu = (menuType: MenuType) => {
    const { pathname } = useLocation();

    const organizationListType =
        window.location.search === '?archived=true' ? 'archived' : 'active';

    const [subMenuPath, setSubMenuPath] = useState(organizationListType);

    const handleClick = (e: Event) => {
        setSubMenuPath(e.key);
    };

    const selectedKey = menuType === 'main' ? pathname.slice(1) : subMenuPath;

    return { selectedKey, handleClick, menuList };
};
