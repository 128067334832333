import { Button } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import s from './DataDriftDownload.module.scss';
import { useDataDriftDownload } from './hooks';

interface Props {
    updateDataDriftList: boolean;
    setUpdateDataDriftList: (update: boolean) => void;
}

export const DataDriftDownload = ({ updateDataDriftList, setUpdateDataDriftList }: Props) => {
    const {
        showModal,
        isModalVisible,
        handleOk,
        handleCancel,
        getDisabledDate,
        chooseDate,
        RangePicker,
    } = useDataDriftDownload({ updateDataDriftList, setUpdateDataDriftList });

    return (
        <div>
            <Button type="primary" className={s.generateReportButton} onClick={showModal}>
                <div className={s.download}>Generate Report</div>
            </Button>
            <Modal
                title="Generate Data Drift Report"
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Generate"
                width={356}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <RangePicker
                    disabledDate={getDisabledDate}
                    format={'YYYY/MM/DD HH:mm'}
                    className={s.rangePicker}
                    showTime={{ format: 'HH:mm' }}
                    onChange={(date: any, dateString: string[]) => chooseDate(date, dateString)}
                />
            </Modal>
        </div>
    );
};
