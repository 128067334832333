import { SVGProps } from 'react';

export const NonClinicalIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#a)" fill="#fff">
            <path d="M8 0a8 8 0 1 0 .001 16.001A8 8 0 0 0 8 0Zm0 14.643A6.644 6.644 0 0 1 8 1.357a6.644 6.644 0 0 1 0 13.286Z" />
            <path d="M7.143 11.143a.857.857 0 1 0 1.713 0 .857.857 0 0 0-1.713 0Zm.428-2h.857A.143.143 0 0 0 8.571 9V4.143A.143.143 0 0 0 8.428 4h-.857a.143.143 0 0 0-.143.143V9c0 .079.065.143.143.143Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 0h16v16H0z" />
            </clipPath>
        </defs>
    </svg>
);
