import { ParametersRiskTable } from 'src/components/ParametersRiskTable';
import { RiskCategorySummary } from 'src/components/RiskCategorySummary';
import { RiskScoreScale } from 'src/components/RiskScoreScale';
import { WaitingNoResult } from 'src/components/WaitingNoResult';
import {
    ScoreDetails,
    scoreIsExpired,
    scoreIsSuccess,
    scoreIsWaiting,
} from 'src/containers/ImmunoScoreDetails/types';

import s from './ScoreDashboard.module.scss';
import { useScoreConfig } from './useScoreConfig';

interface Props {
    immunoScoreDetails: ScoreDetails;
    setShowHelpCenter: (show: boolean) => void;
}

export function ScoreDashboard({ immunoScoreDetails, setShowHelpCenter }: Props) {
    const details = immunoScoreDetails;

    const scoreConfig = useScoreConfig();

    return (
        <div className={s.main}>
            {scoreIsSuccess(details) && (
                <>
                    <RiskCategorySummary
                        status={details.status}
                        immunoScoreValue={details.immunoScoreValue}
                        orderTime={details.orderTime}
                        resultTime={details.resultTime}
                        usageMode={details.usageMode}
                        interpretation={details.interpretation}
                        setShowHelpCenter={setShowHelpCenter}
                    />
                    <div className={s.scoreScale}>
                        <RiskScoreScale
                            immunoScoreValue={details.immunoScoreValue}
                            scoreConfig={scoreConfig}
                        />
                    </div>
                    <ParametersRiskTable
                        increasingParameters={details.increasingParameters}
                        decreasingParameters={details.decreasingParameters}
                        unavailableParameters={details.unavailableParameters}
                    />
                </>
            )}
            {(scoreIsExpired(details) || scoreIsWaiting(details)) && (
                <>
                    <RiskCategorySummary
                        status={details.status}
                        orderTime={details.orderTime}
                        resultTime={scoreIsExpired(details) ? details.resultTime : undefined}
                        setShowHelpCenter={setShowHelpCenter}
                    />
                    <div className={s.noResultWrapper}>
                        <WaitingNoResult
                            status={details.status}
                            required={details.required ? details.required : []}
                        />
                    </div>
                    <div className={s.stripe} />
                </>
            )}
        </div>
    );
}
