import { Table, Tag, Space, Button, Empty } from 'antd';
import { useNavigate } from 'react-router';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { CreateNewOrganizationIcon } from 'src/icons/CreateNewOrganizationIcon';

import s from './OrganizationList.module.scss';
import { useOrganizationList } from './useOrganizationList';

export interface Record {
    key: string;
    organization: string;
    report: string[];
    status: string[];
}

export function OrganizationList() {
    const { organizationListRD, isActive, archiveOrganization, restoreOrganization } =
        useOrganizationList();

    const navigate = useNavigate();

    const activeOrganizationsColumns = [
        {
            title: 'Organization',
            key: 'organization',
            render: (record: Record) => (
                <a className={s.name} onClick={() => navigate(`/organization/${record.key}`)}>
                    {record.organization}
                </a>
            ),
        },
        {
            title: 'Status',
            key: 'status',
            width: 300,
            align: 'right' as const, // https://stackoverflow.com/questions/61519622/antd-with-typescript-table-with-column-align-right-is-not-compiling
            dataIndex: 'status',
            render: (status: any) => (
                <>
                    {status.map((tag: any) => {
                        let color = tag.length > 5 ? 'geekblue' : 'green';
                        if (tag === 'loser') {
                            color = 'volcano';
                        }
                        return (
                            <Tag color={color} key={tag}>
                                {tag.toUpperCase()}
                            </Tag>
                        );
                    })}
                </>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 300,
            align: 'right' as const,
            render: (record: Record) => (
                <Space size="middle">
                    <a className={s.action} onClick={() => navigate(`/organization/${record.key}`)}>
                        Settings
                    </a>
                    <a className={s.action} onClick={() => archiveOrganization(record)}>
                        Archive
                    </a>
                </Space>
            ),
        },
    ];

    const archivedOrganizationsColumns = [
        {
            title: 'Organization',
            key: 'organization',
            render: (record: Record) => (
                <a className={s.name} onClick={() => navigate(`/organization/${record.key}`)}>
                    {record.organization}
                </a>
            ),
        },
        {
            title: 'Actions',
            key: 'actions',
            width: 187,
            align: 'right' as const,
            render: (record: Record) => (
                <Space size="middle">
                    <a className={s.action} onClick={() => navigate(`/organization/${record.key}`)}>
                        Settings
                    </a>
                    <a className={s.action} onClick={() => restoreOrganization(record)}>
                        Restore
                    </a>
                </Space>
            ),
        },
    ];

    return (
        <BaseLayout showHeader={true} showMainMenu={true} showSubMenu={true}>
            <div className={s.wrapper}>
                <RenderRemoteData
                    remoteData={organizationListRD}
                    renderLoading={() => <DotPulseLoader />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {(tableData) => {
                        if (tableData.length === 0) {
                            return (
                                <Empty
                                    image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                    imageStyle={{
                                        height: 60,
                                    }}
                                    description={<span>No organizations registred yet</span>}
                                >
                                    <Button type="primary" onClick={() => navigate('./new')}>
                                        Create New
                                    </Button>
                                </Empty>
                            );
                        }

                        return (
                            <>
                                {isActive && (
                                    <Button
                                        type="primary"
                                        className={s.createNewOrganizationButton}
                                        onClick={() => navigate('./new')}
                                    >
                                        <div className={s.createNewButtonContent}>
                                            <CreateNewOrganizationIcon />
                                            <span className={s.createNewButtonText}>
                                                Create New
                                            </span>
                                        </div>
                                    </Button>
                                )}
                                <Table
                                    columns={
                                        isActive
                                            ? activeOrganizationsColumns
                                            : archivedOrganizationsColumns
                                    }
                                    dataSource={tableData}
                                />
                            </>
                        );
                    }}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
}
