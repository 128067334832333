import { CSSProperties } from 'react';

import { ExtensionMapperIcon } from 'src/icons/ExtensionMapperIcon';

import s from './ConfigureMapperBody.module.scss';

interface ConfigureMapperBodyProps {
    configureItemSections: {
        component: JSX.Element;
        className: string;
        style?: CSSProperties;
        onClick: () => void;
        title: string;
    }[];
}

export function ConfigureMapperBody({ configureItemSections }: ConfigureMapperBodyProps) {
    return (
        <div className={s.wrapper}>
            {configureItemSections.map((item) => (
                <div
                    key={item.title}
                    className={item.className}
                    style={item.style ? item.style : {}}
                >
                    <div className={s.header} onClick={item.onClick}>
                        <ExtensionMapperIcon />
                        <h2>{item.title}</h2>
                    </div>
                    {item.component}
                </div>
            ))}
        </div>
    );
}
