import { SVGProps } from 'react';

export const NoResultIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={16} height={13} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M8.715 7.85V4.876h-1.43v2.972h1.43Zm0 2.938V9.3h-1.43v1.487h1.43ZM1.723 13a1 1 0 0 1-.868-1.496L7.132.519a1 1 0 0 1 1.736 0l6.277 10.985A1 1 0 0 1 14.277 13H1.723Z"
            fill="#FF7802"
        />
    </svg>
);
