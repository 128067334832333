import { useParams } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { getFHIRResource } from 'aidbox-react/lib/services/fhir';

import { useOrganizationHeader } from 'src/components/BaseLayout/OrganizationHeader/useOrganizationHeader';
import { useOrganizationId } from 'src/containers/OrganizationDetails/hooks';
import { DataDriftReport } from 'src/containers/OrganizationReportList/hooks';

export const useOrganizationReport = () => {
    const organizationId = useOrganizationId();
    const { organizationName } = useOrganizationHeader(organizationId as string);

    const params = useParams();
    const reportId = params.reportId;

    const [dataDriftReportRD] = useService(
        async () =>
            await getFHIRResource<DataDriftReport>({
                resourceType: 'DataDriftReport',
                id: reportId as string,
            }),
    );

    return { organizationId, organizationName, dataDriftReportRD };
};
