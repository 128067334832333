import { CodeableConcept } from 'shared/src/contrib/aidbox';

import { OrderStatus, getRiskStatusCategory, Score } from 'src/utils/score';

import s from './RiskCategoryHeader.module.scss';

export const getHeader = (score: Score): string => {
    const { value, status } = score;

    if (status === OrderStatus.Waiting) {
        return 'Result Pending';
    }

    if (status === OrderStatus.Failure) {
        return 'Wait Time Has Expired';
    }

    if (value) {
        const riskCategory = getRiskStatusCategory(score);
        return `${riskCategory} Risk Category`;
    }

    throw new Error(`Impossible score: ${JSON.stringify(score)}`);
};

const getHeaderStyle = (status: OrderStatus) => {
    if (status === OrderStatus.Waiting) {
        return s.headerWaiting;
    }

    if (status === OrderStatus.Failure) {
        return s.headerFailure;
    }

    return s.header;
};

export const getRecommendationList = (interpretation?: CodeableConcept) => {
    const interpretationArray = interpretation?.coding
        ?.filter(
            (interpretationItem) =>
                interpretationItem.system ===
                    'http://prenosis.com/fhir/CodeSystem/recommendation' &&
                interpretationItem.code !== 'length-of-stay',
        )
        .map((interpretationString) => interpretationString.display);

    if (!interpretationArray) {
        console.error('Error: missing list of interpretations');
        return [];
    }

    const percentArray = interpretationArray.map((interpretationString) =>
        Number(interpretationString?.split('%')[0]),
    );

    const statusArray = interpretationArray.map((interpretationString) => {
        if (!interpretationString) {
            console.error('Error: missing interpretation string');
            return '';
        }
        return interpretationString.split(' patients ')[0].split('of ')[1];
    });

    const diagnosticArray = interpretationArray.map((interpretationString) => {
        if (!interpretationString) {
            console.error('Error: missing interpretation string');
            return '';
        }
        return interpretationString
            .split(' patients ')[1]
            .split(/within|before/)[0]
            .trim();
    });

    return [
        { percent: percentArray[0], status: statusArray[0], diagnostic: diagnosticArray[0] },
        { percent: percentArray[1], status: statusArray[1], diagnostic: diagnosticArray[1] },
        { percent: percentArray[2], status: statusArray[2], diagnostic: diagnosticArray[2] },
    ];
};

export const useRiskCategoryHeader = ({ status, value, interpretation }: Score) => {
    const headerText = getHeader({ status, value, interpretation });

    const headerStyle = getHeaderStyle(status);

    const recommendationList =
        status === OrderStatus.Success ? getRecommendationList(interpretation) : [];

    return { headerText, headerStyle, recommendationList };
};
