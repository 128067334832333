import { riskData, RiskStatusCategory } from './score';

export enum Color {
    Grey = '#748C97',
    Dark = '#1E2226',
    Green = '#02C165',
    Orange = '#FF7802',
    Red = '#FF3F3D',
    DarkRed = '#C2160C',
}

export function getColorByRisk(risk: RiskStatusCategory): Color {
    return riskData[risk].color;
}
