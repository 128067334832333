import * as React from 'react';

export function HelpIcon(props: React.SVGProps<SVGSVGElement>) {
    const [helpFill, setHelpFill] = React.useState('#748C97');

    return (
        <svg
            width={16}
            height={17}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            onMouseOver={() => setHelpFill('#0783C1')}
            onMouseOut={() => setHelpFill('#748C97')}
        >
            <rect x={1} y={1.5} width={14} height={14} rx={7} fill={helpFill} />
            <path
                d="M6.03 5.13a2.717 2.717 0 011.992-.83c.775 0 1.44.277 1.993.83.553.553.83 1.217.83 1.992 0 .598-.354 1.218-1.063 1.86-.708.62-1.062 1.184-1.062 1.693H7.325c0-.51.11-.941.332-1.295.222-.376.465-.642.73-.797.266-.177.51-.387.731-.63a1.2 1.2 0 00.332-.83c0-.377-.144-.698-.431-.964-.288-.288-.62-.431-.997-.431-.376 0-.708.143-.996.431a1.263 1.263 0 00-.431.963H5.2c0-.775.277-1.439.83-1.992zm1.295 7.67v-1.428H8.72V12.8H7.325z"
                fill="#fff"
            />
        </svg>
    );
}
