import { formatHumanDateTime } from 'src/utils/date';

import s from './DatetimeView.module.scss';

type TimeType = 'order' | 'result';

export interface DatetimeViewProps {
    type: TimeType;
    time?: string;
}

export function DatetimeView({ type, time }: DatetimeViewProps) {
    const dateTime = time ? formatHumanDateTime(time) : '—'

    return (
        <div className={s.wrapper}>
            <div className={s.timeType}>{type === 'order' ? 'Order' : 'Result'} Time</div>
            <div className={s.time}>{dateTime}</div>
        </div>
    );
}
