import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData/index';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { NormalizationRuleForm } from 'src/components/NormalizationRuleForm';

import { useOrganizationNormalization } from './hooks';
import s from './OrganizationNormalization.module.scss';
import { toFormData } from './utils';

export function OrganizationNormalization() {
    const { organizationId, remoteData, onSave, onDelete } = useOrganizationNormalization();

    return (
        <BaseLayout showHeader={true} organizationId={organizationId}>
            <div className={s.container}>
                <RenderRemoteData
                    remoteData={remoteData}
                    renderLoading={() => <DotPulseLoader />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {({ features, ruleList }) => {
                        const initialValues = toFormData(features, ruleList);
                        return (
                            <NormalizationRuleForm
                                initialValues={initialValues}
                                organizationId={organizationId!}
                                onSave={onSave}
                                onDelete={onDelete}
                            />
                        );
                    }}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
}
