import { Score } from 'src/utils/score';

import s from './ScoreView.module.scss';

export const ScoreValue = ({ status, value }: Score) => {
    if (status === 'waiting') {
        return <span className={s.waiting}>?</span>;
    }

    if (status === 'success' && value) {
        const valueIs100 = value >= 100;

        return (
            <>
                <span className={valueIs100 ? s.value100 : s.value}>{value}</span>
                <span className={valueIs100 ? s.percent100 : s.percent}>%</span>
            </>
        );
    }

    return <span className={s.noResult}>No Result</span>;
};
