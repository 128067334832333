import './styles.css';
import { Alert } from 'antd';

require('codemirror/mode/yaml/yaml');

import { ConfigureMapperBody } from 'src/components/ConfigureMapperBody';
import { ConfigureMapperHeader } from 'src/components/ConfigureMapperHeader';
import { Normalization } from 'src/components/Normalization';
import { ResultedObservation } from 'src/components/ResultedObservation';
import { SourceObservation } from 'src/components/SourceObservation';

import s from './ConfigureMapper.module.scss';
import { useConfigureMapper } from './useConfigureMapper';

export function ConfigureMapper() {
    const {
        sourceObservationExample,
        onChangeSourceObservation,
        mappingRD,
        debugResultRD,
        mappingId,
        expandable,
        saveNormalization,
        onChangeNormalization,
    } = useConfigureMapper();

    if (!mappingId) {
        return (
            <div style={{ padding: '2.5%' }}>
                <Alert message="Error" description={'Mapping is not found'} type="error" showIcon />
            </div>
        );
    }

    const configureItemSections = [
        {
            component: (
                <SourceObservation
                    sourceObservation={sourceObservationExample}
                    onChangeSourceObservation={onChangeSourceObservation}
                />
            ),
            className: s.sourceObservationContainer,
            style: expandable.sourceObservationStyle,
            onClick: expandable.sourceObservationOnClick,
            title: 'Source Observation',
        },
        {
            component: (
                <Normalization
                    mappingRD={mappingRD}
                    onChangeNormalization={onChangeNormalization}
                />
            ),
            className: s.normalizationContainer,
            style: expandable.normalizationStyle,
            onClick: expandable.normalizationOnClick,
            title: 'Normalization',
        },
        {
            component: <ResultedObservation debugResultRD={debugResultRD} />,
            className: s.resultedObservationContainer,
            style: expandable.resultedObservationStyle,
            onClick: expandable.resultedObservationOnClick,
            title: 'Resulted Observation',
        },
    ];

    return (
        <div>
            <ConfigureMapperHeader saveNormalization={saveNormalization} />
            <ConfigureMapperBody configureItemSections={configureItemSections} />
        </div>
    );
}
