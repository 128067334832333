import confirm from 'antd/lib/modal/confirm';

export const onClickCancel = (icon: any) => {
    confirm({
        title: 'Are you sure you want to delete changes?',
        icon,
        content: 'All the filled data will be deleted',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk() {
            window.history.back();
        },
        onCancel() {},
    });
};

export const buttonStyle = {
    display: 'flex',
    marginRight: 24,
    marginLeft: 8,
    alignItems: 'center',
    fontWeight: 500,
};

export const iconStyle = { color: '#CA1C36' };
