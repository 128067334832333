import { Form, InputNumber } from 'antd';
import { Control, Controller, FieldErrors } from 'react-hook-form';

interface InputFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    errors: FieldErrors;
    rules?: any;
    placeholder: number | string;
}

export function NumericField(props: InputFieldProps) {
    const { name, label, control, errors, rules = {}, placeholder } = props;

    return (
        <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field }) => (
                <Form.Item
                    label={label}
                    name={name}
                    // rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <>
                        <InputNumber {...field} placeholder={String(placeholder)} />
                        {errors[name] && <span>This field is required</span>}
                    </>
                </Form.Item>
            )}
        />
    );
}
