import { SVGProps } from 'react';

export const AddIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.536.572H6.464c-.095 0-.143.048-.143.143v5.607h-5.32c-.096 0-.144.048-.144.143v1.072c0 .095.048.142.143.142h5.321v5.608c0 .095.048.142.143.142h1.072c.095 0 .142-.047.142-.142V7.679H13c.095 0 .143-.047.143-.142V6.465c0-.095-.048-.143-.143-.143H7.678V.715c0-.095-.047-.143-.142-.143Z"
            fill={props.color ? props.color : '#fff'}
        />
    </svg>
);
