import { Layout, PageHeader } from 'antd';
import { useNavigate } from 'react-router';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';
import { RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { HelpCenter } from 'src/components/HelpCenter';
import { ScoreDashboard } from 'src/components/ScoreDashboard';

import s from './ImmunoScoreDetails.module.scss';
import { ScoreDetails } from './types';
import { useImmunoScoreDetails } from './useImmunoScoreDetails';

const { Content } = Layout;

interface ImmunoScoreDetailsProps {
    disableHeader?: boolean;
}

export function ImmunoScoreDetails(props: ImmunoScoreDetailsProps) {
    const { immunoScoreDetailsRD, showHelpCenter, setShowHelpCenter } = useImmunoScoreDetails();

    const navigate = useNavigate();

    return (
        <>
            {showHelpCenter && <HelpCenter setShowHelpCenter={setShowHelpCenter} />}
            {!props.disableHeader && (
                <PageHeader
                    className={s.header}
                    onBack={() => navigate('/immunoscore')}
                    title={'ImmunoScore'}
                    style={{ backgroundColor: '#ffffff', minWidth: 1160 }}
                />
            )}
            <ImmunoScoreDetailsMain
                immunoScoreDetailsRD={immunoScoreDetailsRD}
                setShowHelpCenter={setShowHelpCenter}
            />
            {showHelpCenter && <div className={s.pageMask} />}
        </>
    );
}

interface Props {
    immunoScoreDetailsRD: RemoteData<ScoreDetails, any>;
    setShowHelpCenter: (show: boolean) => void;
}

export const ImmunoScoreDetailsMain = ({ immunoScoreDetailsRD, setShowHelpCenter }: Props) => {
    return (
        <>
            <BaseLayout>
                <Content style={{ backgroundColor: '#eff5f9' }}>
                    <RenderRemoteData
                        remoteData={immunoScoreDetailsRD}
                        renderLoading={() => <DotPulseLoader />}
                        renderFailure={(error) => <AlertFailure error={error} />}
                    >
                        {(immunoScoreDetails) => (
                            <ScoreDashboard
                                immunoScoreDetails={immunoScoreDetails}
                                setShowHelpCenter={setShowHelpCenter}
                            />
                        )}
                    </RenderRemoteData>
                </Content>
            </BaseLayout>
        </>
    );
};
