import { SVGProps } from 'react';

export const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M13.213 12.354 9.155 8.296c.63-.814.97-1.81.97-2.857a4.66 4.66 0 0 0-1.373-3.314A4.653 4.653 0 0 0 5.437.752a4.66 4.66 0 0 0-3.314 1.373A4.65 4.65 0 0 0 .75 5.44c0 1.252.49 2.43 1.373 3.315a4.65 4.65 0 0 0 3.314 1.373 4.65 4.65 0 0 0 2.855-.969l4.058 4.056a.127.127 0 0 0 .09.038.127.127 0 0 0 .091-.038l.681-.68a.13.13 0 0 0 .038-.09.129.129 0 0 0-.037-.09Zm-5.3-4.44A3.481 3.481 0 0 1 5.436 8.94a3.481 3.481 0 0 1-2.475-1.025A3.481 3.481 0 0 1 1.938 5.44c0-.934.365-1.814 1.025-2.475A3.481 3.481 0 0 1 5.438 1.94c.935 0 1.815.363 2.475 1.025A3.481 3.481 0 0 1 8.938 5.44c0 .935-.365 1.815-1.026 2.475Z"
            fill="#000"
            fillOpacity={0.85}
        />
    </svg>
);
