import {SVGProps, useState} from 'react';

import s from './CloseCircleButton.module.scss';

export const CloseCircleButton = (props: SVGProps<SVGSVGElement>) => {
    const colorDefault = '#748C97';
    const colorHover = '#006CA1';
    const [fillColor, setFillColor] = useState(colorDefault);

    return (
        <span
            className={s.closeCircleButton}
            onMouseOver={() => setFillColor(colorHover)}
            onMouseLeave={() => setFillColor(colorDefault)}
        >
            <svg width={32} height={32} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
                <rect x={0.5} y={0.5} width={31} height={31} rx={15.5} stroke={fillColor}/>
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="m17.666 16 5.834-5.833L21.833 8.5 16 14.334 10.167 8.5 8.5 10.167 14.333 16l-5.832 5.833 1.666 1.667L16 17.667l5.833 5.833 1.666-1.667L17.667 16Z"
                    fill={fillColor}
                />
            </svg>
        </span>
    );
};
