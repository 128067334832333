import { notification } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { getFHIRResource } from 'aidbox-react/lib/services/fhir';
import { uuid4 } from 'aidbox-react/lib/utils/uuid';

import { Organization } from 'shared/src/contrib/aidbox';

import { saveSISResource } from 'src/services/sis';
import { sharedOrganization } from 'src/sharedState';

import { showNotificationError } from './helpers';
import { OrganizationFormProps } from './OrganizationForm';
import { OrganizationConfig, OrganizationFormValues } from './types';

export function useOrganizationForm({ organization, organizationConfig }: OrganizationFormProps) {
    const navigate = useNavigate();

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm<OrganizationFormValues>({
        defaultValues: getInitialValues(organization, organizationConfig),
    });

    function getNewOrganization(): Organization {
        return {
            id: uuid4(),
            resourceType: 'Organization',
            mode: 'non-clinical-use',
            name: '',
            active: true,
        };
    }

    function getNewOrganizationConfig(organization: Organization): OrganizationConfig {
        return {
            resourceType: 'OrganizationConfig',
            organization: {
                id: organization.id!,
                resourceType: 'Organization',
            },
            referencePeriod: {
                start: '',
                end: '',
            },
        };
    }

    function getInitialValues(
        organization: Organization | null,
        organizationConfig: OrganizationConfig | null,
    ): OrganizationFormValues {
        if (organization) {
            if (organizationConfig) {
                return {
                    organization,
                    organizationConfig,
                };
            }
            return {
                organization,
                organizationConfig: getNewOrganizationConfig(organization),
            };
        }
        const newOrganization = getNewOrganization();
        return {
            organization: newOrganization,
            organizationConfig: getNewOrganizationConfig(newOrganization),
        };
    }

    async function saveForm(values: OrganizationFormValues) {
        const organizationSaveResponse = await saveSISResource(values.organization);

        if (isSuccess(organizationSaveResponse)) {
            const organizationConfigSaveResponse = await saveSISResource(values.organizationConfig);

            if (isSuccess(organizationConfigSaveResponse)) {
                notification['success']({
                    message: 'Success',
                    description: `Organization "${organizationSaveResponse.data.name}" has been saved`,
                });
                navigate('/organizations');
                return organizationConfigSaveResponse;
            }

            if (isFailure(organizationConfigSaveResponse)) {
                showNotificationError(organizationConfigSaveResponse, 'OrganizationConfig');
                navigate('/organizations');
                return organizationConfigSaveResponse;
            }
        }
    }

    return {
        control,
        errors,
        handleSubmit,
        onSubmit: saveForm,
    };
}

export function useOrganizationId() {
    const params = useParams();
    const id = params.organizationId;

    if (!id) {
        return;
    }

    const organization = sharedOrganization.getSharedState();

    useService(async () => {
        if (organization && organization.id === id) {
            return success(null);
        }
        const response = await getFHIRResource<Organization>({
            resourceType: 'Organization',
            id,
        });
        if (isSuccess(response)) {
            sharedOrganization.setSharedState(response.data);
        }
        if (isFailure(response)) {
            sharedOrganization.setSharedState(null);
        }
        return success(null);
    }, [id]);

    return id;
}
