import { Tabs } from 'antd';

import { CloseCircleButton } from 'src/icons/CloseCircleButton';

import { ArtificialIntelligence } from './ArtificialIntelligence';
import {
    DemographicsPerformance,
    DiagnosticInterpretation,
    Monotonicity,
    PrognosticDiagnostic
} from './DiagnosticInterpretation';
import s from './HelpCenter.module.scss';
import { Labeling } from './Labeling';
import { UserInterfaces } from './UserInterfaces';

interface Props {
    setShowHelpCenter: (show: boolean) => void;
}

const { TabPane } = Tabs;

export const HelpCenter = ({ setShowHelpCenter }: Props) => {
    return (
        <div className={s.helpCenterWrapper}>
            <div className={s.helpCenterContainer}>
                <div className={s.header}>
                    <div className={s.title}>Help Center</div>
                    <div className={s.close} onClick={() => setShowHelpCenter(false)}>
                        <CloseCircleButton />
                    </div>
                </div>
                <div className={s.menuContainer}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="Interpretation" key="1">
                            <DiagnosticInterpretation />
                        </TabPane>
                        <TabPane tab="Labeling" key="2">
                            <Labeling />
                        </TabPane>
                        <TabPane tab="User Interfaces" key="3">
                            <UserInterfaces />
                        </TabPane>
                        <TabPane tab="Artificial Intelligence" key="4">
                            <ArtificialIntelligence />
                        </TabPane>
                        <TabPane tab="Detailed Performance" key="5">
                            <Monotonicity/>
                            <PrognosticDiagnostic/>
                            <DemographicsPerformance/>
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
