import {
    CodeableConcept,
    Coding,
    dateTime,
    NormalizationRule,
    NormalRange,
} from 'shared/src/contrib/aidbox';

import { OrderStatus } from 'src/utils/score';

export enum ParameterDelta {
    // Source: "http://hl7.org/fhir/ValueSet/observation-interpretation"
    H = 'High',
    L = 'Low',
    N = 'Normal',
}

export interface ScoreParameter {
    id: string;
    scoreImpact: number;
    display: string;
    code: string;
    delta: ParameterDelta;
    value: number | string | null;
    unit: string;
    collectionTime?: string; // Empty for imputed values
    isImputed: boolean;
    stalenessHours: string;
}

export type ScoreDetails = ScoreDetailsWaiting | ScoreDetailsSuccess | ScoreDetailsExpired;

export interface RequiredObservationItem {
    code: string;
    display: string;
    message?: string;
    helpText?: string;
    orderTime?: dateTime;
    isExternal?: boolean;
    isRequired?: boolean;
}

interface ScoreDetailsCore {
    orderId: string;
    orderTime: dateTime;
    required?: Array<RequiredObservationItem>;
}

export interface ScoreDetailsWaiting extends ScoreDetailsCore {
    status: OrderStatus.Waiting;
}

export interface ScoreDetailsSuccess extends ScoreDetailsCore {
    status: OrderStatus.Success;
    resultTime: dateTime;
    immunoScoreValue: number;
    interpretation: CodeableConcept;
    increasingParameters: ScoreParameter[];
    decreasingParameters: ScoreParameter[];
    unavailableParameters: ScoreParameter[];
    usageMode: 'non-clinical-use' | 'clinical-use';
}

export interface ScoreDetailsExpired extends ScoreDetailsCore {
    status: OrderStatus.Failure;
    resultTime: dateTime;
}

export function scoreIsWaiting(scoreDetails: ScoreDetails): scoreDetails is ScoreDetailsWaiting {
    return scoreDetails.status === OrderStatus.Waiting;
}

export function scoreIsExpired(scoreDetails: ScoreDetails): scoreDetails is ScoreDetailsExpired {
    return scoreDetails.status === OrderStatus.Failure;
}

export function scoreIsSuccess(scoreDetails: ScoreDetails): scoreDetails is ScoreDetailsSuccess {
    return scoreDetails.status === OrderStatus.Success;
}

export enum CompositionSectionCode {
    Score = 'score',
    ProvidedObservations = 'provided-observations',
    RequiredObservations = 'required-observations',
    UsedObservations = 'used-observations',
}

export enum CodeSystemList {
    OrderSection = 'http://prenosis.com/fhir/CodeSystem/order-sections',
    ObservationCodes = 'http://prenosis.com/fhir/CodeSystem/observation-codes',
    ObservationType = 'http://prenosis.com/fhir/CodeSystem/observation-type',
    FHIRObservationCodes = 'http://hl7.org/fhir/ValueSet/observation-codes',
}

export interface FeatureGroup {
    display: string;
    isGroup: true;
    normalRange: Partial<NormalRange>;
}

export interface Feature {
    group: string;
    parameter_name?: string;
    required?: boolean;
    selection_method?: string;
    staleness_hours: number;
    display: string;
    unit: Coding;
    normalRange: Partial<NormalRange>;
    defaultNormalRange: { low: string | number; high: string | number };
    rules?: NormalizationRule[];
}

export type FeatureItem = FeatureGroup | Feature;

export interface Features {
    [code: string]: FeatureItem;
}

export interface ScoreConfig {
    riskCategoryThreshold: {
        low: number;
        medium: number;
        high: number;
    };
    riskBarWidthPercent: {
        low: number;
        medium: number;
        high: number;
        veryHigh: number;
    };
}

export function featureIsGroup(item: FeatureItem): item is FeatureGroup {
    return (item as FeatureGroup).isGroup;
}
