import { Button } from 'antd';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { OAuthCallback } from 'src/components/OAuthCallback';
import { ConfigureMapper } from 'src/containers/ConfigureMapper';
import { ImmunoScoreDetails } from 'src/containers/ImmunoScoreDetails';
import { ImmunoScoreList } from 'src/containers/ImmunoScoreList';
import { OrganizationDetails } from 'src/containers/OrganizationDetails';
import { OrganizationList } from 'src/containers/OrganizationList';
import { OrganizationNormalization } from 'src/containers/OrganizationNormalization';
import { OrganizationNormalRanges } from 'src/containers/OrganizationNormalRanges';
import { OrganizationReport } from 'src/containers/OrganizationReport';
import { OrganizationReportList } from 'src/containers/OrganizationReportList';
import { SignIn } from 'src/containers/SignIn';
import { UserList } from 'src/containers/UserList';
import { getUserRole } from 'src/containers/UserList/utils';
import { baseLogout } from 'src/services/auth';

import s from './App.module.scss';
import { useApp } from './useApp';

export function App() {
    const { initialDataRD, InitialDataContext } = useApp();

    return (
        <BrowserRouter>
            <RenderRemoteData
                remoteData={initialDataRD}
                renderLoading={() => <DotPulseLoader />}
                renderFailure={(error) => (
                    <>
                        <BaseLayout showHeader={true}>
                            <div className={s.wrapper}>
                                <Button
                                    type="primary"
                                    className={s.buttonLogout}
                                    onClick={() => baseLogout()}
                                >
                                    Log out
                                </Button>
                                <AlertFailure error={error} />
                            </div>
                        </BaseLayout>
                    </>
                )}
            >
                {({ user, features }) => (
                    <InitialDataContext.Provider value={{ user, features }}>
                        <Routes>
                            <Route
                                path="score/:orderId/:accessToken"
                                element={<ImmunoScoreDetails disableHeader={true} />}
                            />
                            {user ? (
                                <>
                                    <Route path="immunoscore" element={<ImmunoScoreList />} />
                                    <Route
                                        path="immunoscore/:orderId"
                                        element={<ImmunoScoreDetails />}
                                    />
                                    {['admin', 'clinicAdmin'].includes(getUserRole(user)) && (
                                        <>
                                            <Route
                                                path="organizations"
                                                element={<OrganizationList />}
                                            />
                                            <Route
                                                path="organization/:organizationId"
                                                element={<OrganizationDetails />}
                                            />
                                            <Route
                                                path="organization/:organizationId/normalization"
                                                element={<OrganizationNormalization />}
                                            />
                                            <Route
                                                path="organization/:organizationId/normalranges"
                                                element={<OrganizationNormalRanges />}
                                            />
                                            <Route
                                                path="organization/:organizationId/:mappingId/:prenosisObservationCode"
                                                element={<ConfigureMapper />}
                                            />
                                            <Route
                                                path="organizations/new"
                                                element={<OrganizationDetails />}
                                            />
                                            <Route
                                                path="organization/:organizationId/reports"
                                                element={<OrganizationReportList />}
                                            />
                                            <Route
                                                path="organization/:organizationId/reports/:reportId"
                                                element={<OrganizationReport />}
                                            />
                                            <Route
                                                path="*"
                                                element={<Navigate to="/organizations" />}
                                            />
                                        </>
                                    )}
                                    {getUserRole(user) === 'admin' && (
                                        <>
                                            <Route path="users" element={<UserList />} />
                                            <Route
                                                path="*"
                                                element={<Navigate to="/organizations" />}
                                            />
                                        </>
                                    )}
                                    <Route path="*" element={<Navigate to="/immunoscore" />} />
                                </>
                            ) : (
                                <>
                                    <Route path="callback" element={<OAuthCallback />} />
                                    <Route path="signin" element={<SignIn />} />
                                    <Route path="*" element={<Navigate to="/signin" />} />
                                </>
                            )}
                        </Routes>
                    </InitialDataContext.Provider>
                )}
            </RenderRemoteData>
        </BrowserRouter>
    );
}
