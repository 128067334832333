export function useScoreImpactBar(scoreImpact: number) {
    const boundaryValue = 0.2;

    const scoreAbsPercent = Math.abs(scoreImpact) * (100 / boundaryValue);

    const width = scoreAbsPercent >= 100 ? '100%' : scoreAbsPercent + '%';

    const isPositive = scoreImpact >= 0;

    const greenScaleStyle = {
        backgroundColor: '#02C165',
        borderRadius: '1px',
        width,
        height: '14px',
        float: 'right' as 'right',
    };

    const redScaleStyle = {
        backgroundColor: '#FF3F3D',
        borderRadius: '1px',
        width,
        height: '14px',
    };

    return { isPositive, greenScaleStyle, redScaleStyle };
}
