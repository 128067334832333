import { RequiredObservationItem } from 'src/containers/ImmunoScoreDetails/types';
import { NoResultIcon } from 'src/icons/NoResultIcon';

import s from '../WaitingNoResult.module.scss';

interface Props {
    waitTimeHasExpired: RequiredObservationItem[];
}

export const NoResultStatus = ({ waitTimeHasExpired }: Props) => (
    <>
        <div className={s.waitTimeHasExpiredContainer}>
            <div className={s.title}>
                <div>Wait Time</div>
                <div>Has Expired</div>
            </div>
            <div className={s.items}>
                {waitTimeHasExpired.map((item, index) => (
                    <div
                        key={index}
                        className={s.item}
                        style={index !== waitTimeHasExpired.length - 1 ? borderStyle : {}}
                    >
                        <div className={s.display}>{item.display}</div>
                        <div className={s.message}>
                            <span>
                                <NoResultIcon />
                            </span>
                            Test has not resulted
                        </div>
                    </div>
                ))}
            </div>
        </div>
    </>
);

const borderStyle = {
    borderBottom: '1px solid rgba(116, 140, 151, 0.15)',
};
