import { SVGProps } from 'react';

export const EditIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={14} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            d="M7.007 5.103c-.467 0-.905.182-1.236.513a1.74 1.74 0 0 0-.512 1.236c0 .467.182.904.512 1.236.331.33.769.512 1.236.512a1.74 1.74 0 0 0 1.236-.512 1.74 1.74 0 0 0 .513-1.236 1.74 1.74 0 0 0-.513-1.236 1.734 1.734 0 0 0-1.236-.513Zm6.442 3.68-1.022-.873a5.618 5.618 0 0 0 0-1.804l1.022-.873a.5.5 0 0 0 .146-.55l-.014-.04a6.914 6.914 0 0 0-1.244-2.152l-.028-.033a.502.502 0 0 0-.549-.148l-1.268.451a5.457 5.457 0 0 0-1.557-.898L8.69.536a.5.5 0 0 0-.403-.401L8.245.127a7.041 7.041 0 0 0-2.482 0l-.042.008a.5.5 0 0 0-.403.401L5.071 1.87a5.522 5.522 0 0 0-1.545.895L2.248 2.31a.5.5 0 0 0-.549.148l-.028.033A6.968 6.968 0 0 0 .427 4.642l-.014.041a.502.502 0 0 0 .146.55l1.034.883a5.493 5.493 0 0 0 0 1.781L.562 8.78a.5.5 0 0 0-.146.55l.015.04c.282.786.7 1.513 1.243 2.152l.028.033a.502.502 0 0 0 .549.148l1.278-.454a5.41 5.41 0 0 0 1.545.895l.247 1.333a.501.501 0 0 0 .403.401l.042.008c.82.148 1.661.148 2.482 0l.042-.008a.5.5 0 0 0 .403-.401l.245-1.327a5.495 5.495 0 0 0 1.557-.898l1.268.451a.5.5 0 0 0 .549-.148l.028-.033a6.97 6.97 0 0 0 1.244-2.151l.014-.041a.504.504 0 0 0-.149-.547Zm-6.442.816a2.747 2.747 0 1 1 0-5.494 2.747 2.747 0 0 1 0 5.494Z"
            fill={props.color ? props.color : '#fff'}
        />
    </svg>
);
