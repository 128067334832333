import { notification } from 'antd';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess, sequenceMap, service } from 'aidbox-react/lib/services/service';

import { NormalRange } from 'shared/src/contrib/aidbox';

import { Features } from 'src/containers/ImmunoScoreDetails/types';
import { getSISResources, saveSISResources } from 'src/services/sis';

import { formValuesToResources, toFormValues } from './utils';

export function useNormalRanges(organizationId: string) {
    const [featuresRD] = useService(async () => {
        return await service<Features>({ url: '/Observation/$features' });
    });

    const [normalRangeListRD, manager] = useService(async () => {
        const response = await getSISResources<NormalRange>('NormalRange', {
            organization: organizationId,
        });
        return mapSuccess(response, (bundle) => {
            return extractBundleResources(bundle).NormalRange;
        });
    }, [organizationId]);

    const mapRD = sequenceMap({ features: featuresRD, normalRangeList: normalRangeListRD });

    const initialValuesRD = mapSuccess(mapRD, ({ features, normalRangeList }) => {
        const values = toFormValues(features, normalRangeList, organizationId);
        return values;
    });

    const reload = manager.reload;

    const submitForm = async (data: Features) => {
        const normalRangeList = formValuesToResources(data, organizationId);
        const response = await saveSISResources(normalRangeList, 'transaction');
        if (isSuccess(response)) {
            reload();
        } else {
            console.error(response.error);
            notification.error({ message: 'Error occured! Please check logs for details' });
        }
        return response;
    };

    return { initialValuesRD, submitForm };
}
