import { Divider, Form } from 'antd';
import { useForm, useFormState } from 'react-hook-form';

import { NormalRangesFormItem } from 'src/components/NormalRangesForm/NormalRangesFormItem';
import { featureIsGroup, Features } from 'src/containers/ImmunoScoreDetails/types';
import { calcDividerValuesArray } from 'src/containers/OrganizationNormalRanges/utils';
import { sharedFeatures } from 'src/sharedState';

import s from './NormalRangesForm.module.scss';
import { NormalRangesSaveButton } from './NormalRangesSaveButton';

interface Props {
    initialValues: Features;
    submitForm: (data: Features) => Promise<unknown>;
}

export function NormalRangesForm({ initialValues, submitForm }: Props) {
    const {
        control,
        handleSubmit,
        getValues,
        formState: { errors },
    } = useForm<Features>({
        defaultValues: initialValues,
    });

    const features = sharedFeatures.getSharedState();

    const featuresWithNormalRanges: any[] = [];

    for (const [key, value] of Object.entries(features)) {
        featuresWithNormalRanges.push([
            key,
            {
                defaultNormalRange: {
                    low: value.normalRange?.low || '-',
                    high: value.normalRange?.high || '-',
                },
            },
        ]);
    }

    const { isSubmitting } = useFormState({ control });

    const valuesArray = Object.entries(getValues());

    const newValuesArray = valuesArray.map((value) => {
        const feature = featuresWithNormalRanges.filter((feature) => feature[0] === value[0]);
        value[1]['defaultNormalRange'] = feature[0][1]['defaultNormalRange'];
        return value;
    });

    return (
        <Form onFinish={handleSubmit(submitForm)} className={s.formWrapper}>
            {newValuesArray.map(([code, item], index) => {
                if (featureIsGroup(item)) {
                    return (
                        <Divider key={code} orientation="left" className={s.titleDivider}>
                            {item.display}
                        </Divider>
                    );
                }
                return (
                    <div className={s.formItemWrapper} key={code}>
                        <NormalRangesFormItem
                            code={code}
                            item={item}
                            control={control}
                            errors={errors}
                        />
                        {calcDividerValuesArray(valuesArray).includes(index + 1) && (
                            <Divider className={s.divider} />
                        )}
                    </div>
                );
            })}
            <NormalRangesSaveButton isSubmitting={isSubmitting} />
        </Form>
    );
}
