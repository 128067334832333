import { Form } from 'antd';
import { Control, FieldErrors } from 'react-hook-form';

import { NumericField } from 'src/components/fields/NumericField';
import { Feature } from 'src/containers/ImmunoScoreDetails/types';
import { RangesArrowDownIcon } from 'src/icons/RangesArrowDownIcon';
import { RangesArrowUpIcon } from 'src/icons/RangesArrowUpIcon';

import s from './NormalRangesFormItem.module.scss';

interface Props {
    code: string;
    item: Feature;
    control: Control;
    errors: FieldErrors;
}

export function NormalRangesFormItem({ code, item, control, errors }: Props) {
    const mode = item.normalRange['mode'];
    const defaultNormalRange = item.defaultNormalRange;

    return (
        <Form.Item key={code}>
            <div key={code} className={s.row}>
                <div className={s.inputLabel}>{item.display}</div>

                <div className={s.parameter}>
                    {mode !== 'highOnly' ? (
                        <div className={s.parameterValue}>
                            <span className={s.arrow}>
                                <RangesArrowDownIcon />
                            </span>
                            <NumericField
                                name={`${code}.normalRange.low`}
                                control={control}
                                errors={errors}
                                placeholder={defaultNormalRange.low}
                            />
                        </div>
                    ) : (
                        <div className={s.emptyParameter} />
                    )}
                </div>

                <div className={s.parameter}>
                    {mode !== 'lowOnly' ? (
                        <div className={s.parameterValue}>
                            <span className={s.arrow}>
                                <RangesArrowUpIcon />
                            </span>
                            <NumericField
                                name={`${code}.normalRange.high`}
                                control={control}
                                errors={errors}
                                placeholder={defaultNormalRange.high}
                            />
                        </div>
                    ) : (
                        <div className={s.emptyParameter} />
                    )}
                </div>

                {item.unit ? (
                    <div className={s.unit}>
                        <div>{item.unit.display}</div>
                    </div>
                ) : (
                    <div className={s.noUnit} />
                )}
            </div>
        </Form.Item>
    );
}
