import { Button, Divider, Form } from 'antd';

import { SaveIcon } from 'src/icons/SaveIcon';

import s from './NormalRangesSaveButton.module.scss';

interface Props {
    isSubmitting: boolean;
}

export const NormalRangesSaveButton = ({ isSubmitting }: Props) => {
    return (
        <div className={s.saveContainer}>
            <Divider className={s.endDivider} />
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={isSubmitting}
                    style={{ width: 86 }}
                >
                    {!isSubmitting && (
                        <div className={s.saveIcon}>
                            <SaveIcon />
                            <div className={s.saveText}>Save</div>
                        </div>
                    )}
                </Button>
            </Form.Item>
        </div>
    );
};
