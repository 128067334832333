import _ from 'lodash';
import { UnControlled as CodeMirror } from 'react-codemirror2';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData/index';
import { RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { objectToDisplay } from 'src/utils/yaml';

import { AlertFailure } from '../AlertFailure';
import { DotPulseLoader } from '../DotPulseLoader';

interface NormalizationProps {
    mappingRD: RemoteData;
    onChangeNormalization: (value: string) => void;
}

export function Normalization({ mappingRD, onChangeNormalization }: NormalizationProps) {
    return (
        <RenderRemoteData
            remoteData={mappingRD}
            renderLoading={() => <DotPulseLoader />}
            renderFailure={(error) => <AlertFailure error={error} />}
        >
            {(mapping) => (
                <CodeMirror
                    value={objectToDisplay(mapping as object)}
                    options={{
                        lineNumbers: false,
                        mode: 'yaml',
                    }}
                    onChange={(_editor, _change, value) => onChangeNormalization(value)}
                />
            )}
        </RenderRemoteData>
    );
}
