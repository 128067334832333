import * as React from 'react';

export function Cursor(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg
            width={34}
            height={38}
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            style={{ marginTop: 3.7 }}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.052 33.727C27.985 32.289 34 25.347 34 17c0-9.389-7.611-17-17-17S0 7.611 0 17c0 8.347 6.015 15.289 13.948 16.727L17 38l3.052-4.273z"
                fill="#1E2226"
            />
        </svg>
    );
}
