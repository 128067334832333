import { Form } from 'antd';
import { Control } from 'react-hook-form';

import { NormalizationRule } from 'shared/src/contrib/aidbox';

import { Feature } from 'src/containers/ImmunoScoreDetails/types';

import { ItemRuleList } from './ItemRuleList';

interface NormalizationFormItemProps {
    code: string;
    item: Feature;
    control: Control;
    organizationId: string;
    onSave: (normalizationRule: NormalizationRule, code: string) => void;
    onDelete: (normalizationRule: NormalizationRule) => void;
}

export function NormalizationFormItem({
    code,
    item,
    control,
    organizationId,
    onSave,
    onDelete,
}: NormalizationFormItemProps) {
    return (
        <Form.Item label={item.display} >
            <ItemRuleList
                code={code}
                control={control}
                onSave={onSave}
                onDelete={onDelete}
                organizationId={organizationId}
            />
        </Form.Item>
    );
}
