import { Input } from 'antd';
import { Dispatch, SetStateAction } from 'react';
import { NavigateFunction } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData/index';
import { RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { AlertFailure } from 'src/components/AlertFailure';
import { SearchIcon } from 'src/icons/SearchIcon';

import s from './ImmunoScoreListSearch.module.scss';
import { DotPulseLoader } from '../DotPulseLoader';
import { ImmunoScoreListTable } from '../ImmunoScoreListTable';

interface Props {
    remoteData: RemoteData<any>;
    searchText: string;
    setSearchText: Dispatch<SetStateAction<string>>;
    updateSearchParams: () => void;
    navigate: NavigateFunction;
}

export function ImmunoScoreListSearch(props: Props) {
    const { remoteData, searchText, setSearchText, updateSearchParams, navigate } = props;
    return (
        <div className={s.wrapper}>
            <div className={s.input}>
                <Input
                    prefix={
                        <div className={s.searchIcon}>
                            <SearchIcon />
                        </div>
                    }
                    value={searchText}
                    onChange={(e) => setSearchText(e.target.value)}
                    onPressEnter={updateSearchParams}
                    allowClear
                />
            </div>
            <RenderRemoteData
                remoteData={remoteData}
                renderLoading={() => <DotPulseLoader />}
                renderFailure={(error) => <AlertFailure error={error} />}
            >
                {(tableData) => <ImmunoScoreListTable tableData={tableData} navigate={navigate} />}
            </RenderRemoteData>
        </div>
    );
}
