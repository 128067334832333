import s from './RecommendationItem.module.scss';

interface Recommendation {
    percent: number;
    status: string;
    diagnostic: string;
}

export function RecommendationItem({ percent, status, diagnostic }: Recommendation) {
    const patientStatus = ` of ${status} patients `;

    const timeCondition = diagnostic === "had a mortality event" ? "before discharge" : "within 24 hours"

    return (
        <div className={s.line}>
            <span className={s.percent}>{percent}%</span>
            <span>{patientStatus}</span>
            <span className={s.diagnostic}>{diagnostic}</span>
            <span> {timeCondition} </span>
        </div>
    );
}
