import { formatHumanDateTime } from 'src/utils/date';

import s from './CollectionTimeCell.module.scss';

interface Props {
    collectionTime?: string;
}

export function CollectionTimeCell({ collectionTime }: Props) {
    return (
        <div className={s.collectionTime}>
            {collectionTime && (
                <div className={s.collectionTime}>{formatHumanDateTime(collectionTime)}</div>
            )}
        </div>
    );
}
