import { RequiredObservationItem } from 'src/containers/ImmunoScoreDetails/types';
import { OrderStatus } from 'src/utils/score';

import { NoResultStatus } from './NoResultStatus';
import { useWaitingNoResult } from './useWaitingNoResult';
import s from './WaitingNoResult.module.scss';
import { WaitingStatus } from './WaitingStatus';

interface Props {
    status: OrderStatus;
    required: Array<RequiredObservationItem>;
}

export function WaitingNoResult({ status, required }: Props) {
    const { measurementsToOrder, awaitingResults, waitTimeHasExpired } = useWaitingNoResult({
        required,
    });

    return (
        <div className={s.wrapper}>
            {status === OrderStatus.Waiting && (
                <WaitingStatus
                    measurementsToOrder={measurementsToOrder}
                    awaitingResults={awaitingResults}
                />
            )}
            {status === OrderStatus.Failure && (
                <NoResultStatus waitTimeHasExpired={waitTimeHasExpired} />
            )}
        </div>
    );
}
