import { useNavigate } from 'react-router-dom';

import { BaseLayout } from 'src/components/BaseLayout';
import { RiskStatusCategory, Score } from 'src/utils/score';

import { useImmunoScoreList } from './useImmunoScoreList';
import { ImmunoScoreListSearch } from '../../components/ImmunoScoreListSearch';

export interface ImmunoScoreTableItem {
    // TODO Relocate types
    id: string;
    score: Score;
    risk?: RiskStatusCategory;
    orderTime: string;
    resultTime?: string;
    patientId: string;
    hospitalId: string;
}

export function ImmunoScoreList() {
    let navigate = useNavigate();
    const { remoteData, searchText, setSearchText, updateSearchParams } = useImmunoScoreList();

    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <ImmunoScoreListSearch
                remoteData={remoteData}
                searchText={searchText}
                setSearchText={setSearchText}
                updateSearchParams={updateSearchParams}
                navigate={navigate}
            />
        </BaseLayout>
    );
}
