import * as React from 'react';

export function DownArrowIcon(props: React.SVGProps<SVGSVGElement>) {
    return (
        <svg width={6} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 7l3 7 3-7H3.8V0H2.2v7H0z"
                fill="#FF3F3D"
            />
        </svg>
    );
}
