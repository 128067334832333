import { notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { mapSuccess, sequenceMap, service } from 'aidbox-react/lib/services/service';

import { NormalizationRule } from 'shared/src/contrib/aidbox';

import { Features } from 'src/containers/ImmunoScoreDetails/types';
import { useOrganizationId } from 'src/containers/OrganizationDetails/hooks';
import { getSISResources, saveSISResource } from 'src/services/sis';

export const useOrganizationNormalization = () => {
    const navigate = useNavigate();

    const organizationId = useOrganizationId();

    const [featuresRD] = useService(async () => {
        const response = await service<Features>({ url: 'Observation/$features' });
        return response;
    });

    const [ruleListRD, ruleManager] = useService(async () => {
        const response = await getSISResources<NormalizationRule>('NormalizationRule', {
            organization: organizationId,
        });
        return mapSuccess(response, (bundle) => {
            return extractBundleResources(bundle).NormalizationRule as NormalizationRule[];
        });
    });

    const reload = ruleManager.reload;

    const remoteData = sequenceMap({
        features: featuresRD,
        ruleList: ruleListRD,
    });

    const onSave = async (normalizationRule: NormalizationRule, code: string) => {
        if (!normalizationRule.externalObservation.code) {
            return;
        }
        if (normalizationRule.id) {
            const response = await saveSISResource(normalizationRule);
            if (isFailure(response)) {
                notification.error({ message: JSON.stringify(response.error) });
            }
            if (isSuccess(response)) {
                const mappingId = normalizationRule.mapping.id;
                navigate(`/organization/${organizationId}/${mappingId}/${code}`);
            }
            return response;
        } else {
            const response = await service({
                method: 'POST',
                url: 'NormalizationRule/$save',
                data: normalizationRule,
            });
            if (isFailure(response)) {
                notification.error({ message: JSON.stringify(response.error) });
            }
            if (isSuccess(response)) {
                const mappingId = response.data.mapping.id;
                navigate(`/organization/${organizationId}/${mappingId}/${code}`);
            }
            return response;
        }
    };

    const onDelete = async (normalizationRule: NormalizationRule) => {
        if (!normalizationRule.id) {
            return;
        }
        const response = await service({
            method: 'POST',
            url: 'NormalizationRule/$delete',
            data: normalizationRule,
        });
        if (isFailure(response)) {
            notification.error({ message: JSON.stringify(response.error) });
        }
        if (isSuccess(response)) {
            reload();
        }
        return response;
    };

    return { organizationId, remoteData, reload, onSave, onDelete };
};
