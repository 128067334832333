import { Tooltip } from 'antd';
import { ImgHTMLAttributes, useState } from 'react';

import { CloseCircleButton } from 'src/icons/CloseCircleButton';

import uiClinicalUseImg from './images/ui-clinical-use.png';
import uiErrorImg from './images/ui-error.jpg';
import uiNonClinicalUseImg from './images/ui-non-clinical-use.png';
import uiNoResultImg from './images/ui-noresult.jpg';
import uiPendingImg from './images/ui-pending.jpg';
import uiResultImg from './images/ui-result.jpg';
import s from './UserInterfaces.module.scss';
import ai from '../ArtificialIntelligence/ArtificialIntelligence.module.scss';

export const UserInterfaces = () => {
    return (
        <div>
            <ExpandableImage src={uiPendingImg} />
            <ExpandableImage src={uiResultImg} />
            <ExpandableImage src={uiNoResultImg} />
            <ClinicalUseModeSection />
            <h2>Error Message</h2>
            <img src={uiErrorImg} className={s.regularImage} />
            <p>
                If this error banner appears, there was an error in the system. Try reloading the
                ImmunoScore or contact your system administrator.
            </p>
        </div>
    );
};

export const ClinicalUseModeSection = () => {
    return (
        <div>
            <h2>Non Clinical Use Mode</h2>
            <p>
                The Non-Clinical Use mode refers to a state of the Sepsis Immunoscore where the
                medical device should not be used to assist with clinical decision making. In this
                mode, results produced by the software will be marked with a "Non-Clinical Use"
                banner in the user interface.
            </p>
            <p>
                The Non-Clinical Use mode may be activated by authorized administrators in the
                following scenarios:
                <ol>
                    <li>
                        During the implementation and validation phases of the software as part of
                        an initial deployment.
                    </li>
                    <li>
                        Post activation of clinical use, if an operational anomaly is identified.
                        Operational anomalies could include, but are not limited to, erroneous
                        results due to data drift, data mapping issues, or other issues identified
                        during performance monitoring.
                    </li>
                </ol>
            </p>
            <div className={ai.twoColContainer}>
                <div className={ai.col}>
                    <img
                        src={uiClinicalUseImg}
                        className={s.regularImage}
                        alt="Clinical Use Example"
                        width="200"
                    />
                </div>
                <div className={ai.col}>
                    <img
                        src={uiNonClinicalUseImg}
                        className={s.regularImage}
                        alt="Non-Clinical Use Example"
                        width="200"
                    />
                </div>
            </div>
        </div>
    );
};

function ExpandableImage({ src }: { src: ImgHTMLAttributes<'src'>['src'] }) {
    const [expanded, setExpanded] = useState<boolean>(false);
    const toggleImageView = () => setExpanded(!expanded);
    return (
        <>
            <Tooltip title="Click to expand" color="#748C97">
                <img src={src} className={s.bigImagePreview} onClick={toggleImageView} />
            </Tooltip>
            {expanded && (
                <div className={s.expandedBackground}>
                    <div className={s.expandedModal}>
                        <CloseCircleButton
                            onClick={toggleImageView}
                            className={s.closeCircleIcon}
                        />
                        <img src={src} className={s.bigImageExpanded} />
                    </div>
                </div>
            )}
        </>
    );
}
