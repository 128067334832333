import { ScoreParameter } from 'src/containers/ImmunoScoreDetails/types';

import { TableBlockType } from '..';

export const useTableBlock = (parameters: ScoreParameter[], type: TableBlockType) => {
    const sortedParams = parameters.sort(function (a, b) {
        if (a.scoreImpact < b.scoreImpact) {
            if (type === 'increasing') return 1;
            return -1;
        }
        if (a.scoreImpact > b.scoreImpact) {
            if (type === 'increasing') return -1;
            return 1;
        }
        return 0;
    });
    return { sortedParams };
};
