import { SimplePopover } from 'src/components/SimplePopover';
import { HelpIcon } from 'src/icons/HelpIcon';

import { parameterInfo } from './parameterInfo';
import s from './ScoreParameterCell.module.scss';

interface Props {
    display: string;
    code: string;
}

export function ScoreParameterCell({ display, code }: Props) {
    return (
        <div className={s.parameter}>
            <div className={s.display}>{display}</div>
            {parameterInfo.hasOwnProperty(code) && (
                <div className={s.help}>
                    <SimplePopover title={display} content={parameterInfo[code].content}>
                        <HelpIcon />
                    </SimplePopover>
                </div>
            )}
        </div>
    );
}
