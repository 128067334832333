import { Button } from 'antd';
import { Control, useFieldArray, useWatch } from 'react-hook-form';

import { NormalizationRule } from 'shared/src/contrib/aidbox';

import { InputField } from 'src/components/fields/InputField';
import s from 'src/containers/OrganizationNormalization/OrganizationNormalization.module.scss';
import { generateRule } from 'src/containers/OrganizationNormalization/utils';
import { AddIcon } from 'src/icons/AddIcon';
import { DeleteIcon } from 'src/icons/DeleteIcon';
import { EditIcon } from 'src/icons/EditIcon';


interface ItemRuleListProps {
    code: string;
    control: Control;
    onSave: (normalizationRule: NormalizationRule, code: string) => void;
    onDelete: (normalizationRule: NormalizationRule) => void;
    organizationId: string;
}

export function ItemRuleList({
    code,
    control,
    onSave,
    onDelete,
    organizationId,
}: ItemRuleListProps) {
    const { fields, append, remove } = useFieldArray({ control, name: `${code}.rules` });

    const value = useWatch({ control });

    return (
        <>
            {fields.map((field: any, index) => {
                const name = `${code}.rules.${index}`;
                const fieldValue = value[code]['rules'][index];
                return (
                    <div key={field.id} className={s.fieldContainer}>
                        <InputField
                            name={`${name}.externalObservation.code`}
                            control={control}
                            errors={{}}
                            style={{ width: '77%' }}
                        />
                        <Button
                            onClick={() => {
                                remove(index);
                                onDelete(fieldValue);
                            }}
                            className={s.removeButton}
                        >
                            <DeleteIcon />
                        </Button>
                        {field.externalObservation.code !== '' ? (
                            <Button
                                onClick={() => {
                                    onSave(fieldValue, code);
                                }}
                                className={s.saveEditButton}
                            >
                                <EditIcon />
                            </Button>
                        ) : (
                            <Button className={s.saveEditButtonDisabled}>
                                <EditIcon color="#00000040" />
                            </Button>
                        )}
                        {fields.length === index + 1 &&
                            (field.externalObservation.code !== '' && field.mapping.id !== '' ? (
                                <Button
                                    onClick={() => {
                                        append(generateRule(code, organizationId));
                                    }}
                                    className={s.saveEditButton}
                                >
                                    <AddIcon />
                                </Button>
                            ) : (
                                <Button className={s.saveEditButtonDisabled}>
                                    <AddIcon color="#00000040" />
                                </Button>
                            ))}
                    </div>
                );
            })}
            {fields.length === 0 && (
                <Button
                    onClick={() => {
                        append(generateRule(code, organizationId));
                    }}
                    className={s.createButton}
                >
                    <AddIcon />
                </Button>
            )}
        </>
    );
}
