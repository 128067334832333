import {Tabs} from "antd";

import s from './DiagnosticInterpretation.module.scss';
import { usePrognosticNumbers } from './usePrognosticNumbers';

const { TabPane } = Tabs;

function formatCI([low, high]: [number, number]) {
    return `[${low}%, ${high}%]`;
}

export const DiagnosticInterpretation = () => {
    const { prognosticNumbers, AUROC } = usePrognosticNumbers();

    return (
        <div>
            <h2>Overall Device Performance</h2>
            <p>
                A clinical validation study assessed the performance of the Sepsis
                ImmunoScore algorithm. The study evaluated the algorithm's overall
                performance by estimating 1) the Area Under the receiver operating curve
                (AUROC) and 2) the likelihood ratios and predictive values associated with
                each risk category.
            </p>
            <table className={s.tableInterpretation}>
                <caption className={s.labCaption}>
                    The area under the receiver operating curve (AUROC) of the Sepsis ImmunoScore AI/ML
                    model
                </caption>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColWide}></th>
                    <th className={s.labColWide}>Sepsis ImmunoScore</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={s.labColFirst}>AUROC</td>
                    <td className={s.labColWide}>{AUROC}</td>
                </tr>
                </tbody>
            </table>

            <p>
                The stratum-specific likelihood ratio (SSLR) of a given risk category is the percentage of all
                septic patients in the validation data whose test result falls into that risk category divided
                by the percentage of all non-septic patients in the validation data whose test result falls into
                that risk category. The predictive value (PV) of a given risk category is the number of septic
                patients in that risk category divided by the total number of patients in that risk category.
            </p>

            <table className={s.tableInterpretation}>
                <caption className={s.labCaption}>
                    SSLR and PVs for each risk category. PVs include an 95% confidence interval (CI)
                </caption>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColWide}></th>
                    <th className={s.labColRegular}>Low</th>
                    <th className={s.labColRegular}>Medium</th>
                    <th className={s.labColRegular}>High</th>
                    <th className={s.labColRegular}>Very High</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={s.labColFirst}>SSLR</td>
                    <td className={s.labColWide}>{prognosticNumbers.low.likelihoodRatio}</td>
                    <td className={s.labColWide}>{prognosticNumbers.medium.likelihoodRatio}</td>
                    <td className={s.labColWide}>{prognosticNumbers.high.likelihoodRatio}</td>
                    <td className={s.labColWide}>
                        {prognosticNumbers.veryHigh.likelihoodRatio}
                    </td>
                </tr>
                <tr>
                    <td className={s.labColFirst}>
                        PV
                        <br/>
                        (95% CI)
                    </td>
                    <td className={s.labColWide}>
                        {prognosticNumbers.low.predictiveValue.value}%
                        <br/>
                        {formatCI(prognosticNumbers.low.predictiveValue.interval)}
                    </td>
                    <td className={s.labColWide}>
                        {prognosticNumbers.medium.predictiveValue.value}%
                        <br/>
                        {formatCI(prognosticNumbers.medium.predictiveValue.interval)}
                    </td>
                    <td className={s.labColWide}>
                        {prognosticNumbers.high.predictiveValue.value}%
                        <br/>
                        {formatCI(prognosticNumbers.high.predictiveValue.interval)}
                    </td>
                    <td className={s.labColWide}>
                        {prognosticNumbers.veryHigh.predictiveValue.value}%
                        <br/>
                        {formatCI(prognosticNumbers.veryHigh.predictiveValue.interval)}
                    </td>
                </tr>
                </tbody>
            </table>
            <p>
                <em>
                    The Sepsis Risk Score is positively correlated with the risk of having or developing
                    sepsis within 24 hours. The Sepsis Risk Score should not be interpreted as a
                    probability, i.e., a patient with a risk score of 20 should not be interpreted
                    as having a 20% probability or chance of developing or having sepsis within 24 hours.
                </em>
            </p>
            <p>
                <em>
                    The Sepsis ImmunoScore is not intended to be used as a continuous monitoring or alert system,
                    or to monitor response to treatment in patients being treated for sepsis. It is intended to
                    simulate a diagnostic test, where an order for the test is placed and a set of outputs is
                    provided as a one-time result
                </em>
            </p>

            <h2 className={s.subHeader}>Device Interpretation</h2>
            <p>
                The table below describes associations between secondary endpoints (length
                of stay, mortality before discharge, ICU transfer within 24 hours, placement
                onto mechanical ventilation within 24 hours, and vasopressor administration
                within 24 hours) obtained from the clinical validation study
            </p>
            <table className={s.table}>
                <thead>
                <tr>
                    <th className={s.categoryTitle}>Risk Stratification Category</th>
                    <th className={s.categoryTitleLow}>Low</th>
                    <th className={s.categoryTitleMedium}>Medium</th>
                    <th className={s.categoryTitleHigh}>High</th>
                    <th className={s.categoryTitleVeryHigh}>Very High</th>
                </tr>
                </thead>
                <div className={s.separator}/>
                <tbody>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <b>Length of stay</b> (median)
                    </td>
                    <td className={s.low}>{prognosticNumbers.low.los} days</td>
                    <td className={s.medium}>{prognosticNumbers.medium.los} days</td>
                    <td className={s.high}>{prognosticNumbers.high.los} days</td>
                    <td className={s.veryHigh}>{prognosticNumbers.veryHigh.los} days</td>
                </tr>
                <div className={s.separator}/>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <b>ICU Transfer</b> within 24 hours
                    </td>
                    <td className={s.low}>{prognosticNumbers.low.icu}%</td>
                    <td className={s.medium}>{prognosticNumbers.medium.icu}%</td>
                    <td className={s.high}>{prognosticNumbers.high.icu}%</td>
                    <td className={s.veryHigh}>{prognosticNumbers.veryHigh.icu}%</td>
                </tr>
                <div className={s.separator}/>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <b>Mortality Event</b> before discharge
                    </td>
                    <td className={s.low}>{prognosticNumbers.low.mortality}%</td>
                    <td className={s.medium}>{prognosticNumbers.medium.mortality}%</td>
                    <td className={s.high}>{prognosticNumbers.high.mortality}%</td>
                    <td className={s.veryHigh}>{prognosticNumbers.veryHigh.mortality}%</td>
                </tr>
                <div className={s.separator}/>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <b>Mechanical Ventilation</b> within 24 hours
                    </td>
                    <td className={s.low}>{prognosticNumbers.low.ventilation}%</td>
                    <td className={s.medium}>{prognosticNumbers.medium.ventilation}%</td>
                    <td className={s.high}>{prognosticNumbers.high.ventilation}%</td>
                    <td className={s.veryHigh}>{prognosticNumbers.veryHigh.ventilation}%</td>
                </tr>
                <div className={s.separator}/>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <b>Vasopressor Administration</b> within 24 hours
                    </td>
                    <td className={s.low}>{prognosticNumbers.low.vasopressors}%</td>
                    <td className={s.medium}>{prognosticNumbers.medium.vasopressors}%</td>
                    <td className={s.high}>{prognosticNumbers.high.vasopressors}%</td>
                    <td className={s.veryHigh}>{prognosticNumbers.veryHigh.vasopressors}%</td>
                </tr>
                <div className={s.separator}/>
                <tr>
                    <td className={s.riskCategoryTitle}>
                        <div className={s.grayText}>Diagnostic Interpretation</div>
                        <div>
                            <b>Possibility of Sepsis</b>
                        </div>
                    </td>
                    <td className={s.lastCellLow}>Not likely</td>
                    <td className={s.lastCellMedium}>Possible</td>
                    <td className={s.lastCellHigh}>Likely</td>
                    <td className={s.lastCellVeryHigh}>Very likely</td>
                </tr>
                </tbody>
            </table>

            <em>
                The sample size of the clinical study was not designed to evaluate endpoints with regards
                to severity of illness or hospital care metrics (e.g., mortality, ICU transfer,
                length of stay, etc.). Therefore, any correlations observed between these metrics and
                the risk categories may not be considered statistically significant.
            </em>
        </div>
    );
};

export const Monotonicity = () => {
    return (
        <div>
            <h2>Risk Score Monotonicity</h2>
            <p>
                The clinical validation study determined a monotonically increasing
                relationship between the sepsis risk score and the proportion of sepsis
                observed.
            </p>
            <table className={s.tableInterpretation}>
                <caption className={s.labCaption}>
                    Discretization of the Sepsis Risk Score for Monotonicity Assessment
                </caption>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColWide}>Risk Stratification Category</th>
                    <th className={s.regularCell}>Subcategory</th>
                    <th className={s.regularCell}>Risk Score Interval</th>
                    <th className={s.regularCell}>Proportion of Patients</th>
                    <th className={s.regularCell}>Observed Sepsis Risk</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={s.labColFirst} rowSpan={2}>Low</td>
                    <td className={s.labColRegular}>1</td>
                    <td className={s.labColRegular}>[0, 0.061)</td>
                    <td className={s.labColRegular}>17%</td>
                    <td className={s.labColRegular}>1%</td>
                </tr>
                <tr>
                    <td className={s.labColRegular}>2</td>
                    <td className={s.labColRegular}>[0.061, 0.122)</td>
                    <td className={s.labColRegular}>17%</td>
                    <td className={s.labColRegular}>5%</td>
                </tr>
                <tr>
                    <td className={s.labColFirst} rowSpan={2}>Medium</td>
                    <td className={s.labColRegular}>3</td>
                    <td className={s.labColRegular}>[0.122, 0.204)</td>
                    <td className={s.labColRegular}>11%</td>
                    <td className={s.labColRegular}>8%</td>
                </tr>
                <tr>
                    <td className={s.labColRegular}>4</td>
                    <td className={s.labColRegular}>[0.204, 0.306)</td>
                    <td className={s.labColRegular}>11%</td>
                    <td className={s.labColRegular}>18%</td>
                </tr>
                <tr>
                    <td className={s.labColFirst} rowSpan={3}>High</td>
                    <td className={s.labColRegular}>5</td>
                    <td className={s.labColRegular}>[0.306, 0.499)</td>
                    <td className={s.labColRegular}>13%</td>
                    <td className={s.labColRegular}>28%</td>
                </tr>
                <tr>
                    <td className={s.labColRegular}>6</td>
                    <td className={s.labColRegular}>[0.499, 0.671)</td>
                    <td className={s.labColRegular}>13%</td>
                    <td className={s.labColRegular}>41%</td>
                </tr>
                <tr>
                    <td className={s.labColRegular}>7</td>
                    <td className={s.labColRegular}>[0.671, 0.872)</td>
                    <td className={s.labColRegular}>14%</td>
                    <td className={s.labColRegular}>42%</td>
                </tr>
                <tr>
                    <td className={s.labColFirst} rowSpan={1}>Very High</td>
                    <td className={s.labColRegular}>8</td>
                    <td className={s.labColRegular}>[0.872, 1)</td>
                    <td className={s.labColRegular}>5%</td>
                    <td className={s.labColRegular}>70%</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export const PrognosticDiagnostic = () => {
    return (
        <div>
            <h2 className={s.subHeader}>Diagnostic and Predictive Performance</h2>
            <p>
                The clinical validation study determined the Sepsis ImmunoScore can diagnose patients who
                have developed sepsis and predict patients who will develop sepsis within 24 hours.
            </p>
            <table className={s.tableInterpretation}>
                <caption className={s.labCaption}>
                    Primary Endpoint Diagnostic and Predictive Analysis of Sepsis ImmunoScore: PVs and SSLRs for Sepsis
                </caption>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColWide}></th>
                    <th className={s.labColRegular} colSpan={2}>Diagnostic</th>
                    <th className={s.labColRegular} colSpan={2}>Predictive</th>
                </tr>
                <tr>
                    <th className={s.labColWide}>Risk Group</th>
                    <th className={s.labColRegular}>PV [95% CI]</th>
                    <th className={s.labColRegular}>SSLR [95% CI]</th>
                    <th className={s.labColRegular}>PV [95% CI]</th>
                    <th className={s.labColRegular}>SSLR [95% CI]</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={s.labColFirst}>Low</td>
                    <td className={s.labColRegular}>0.88% [0.11%, 3.15%]</td>
                    <td className={s.labColRegular}>0.05 [0.01, 0.2]</td>
                    <td className={s.labColRegular}>2.17% [0.71%, 5%]</td>
                    <td className={s.labColRegular}>0.23 [0.1, 0.56]</td>
                </tr>
                <tr>
                    <td className={s.labColFirst}>Medium</td>
                    <td className={s.labColRegular}>8.05% [4.23%, 13.65%]</td>
                    <td className={s.labColRegular}>0.48 [0.27, 0.86]</td>
                    <td className={s.labColRegular}>5.52% [2.41%, 10.58%]</td>
                    <td className={s.labColRegular}>0.61 [0.31, 1.24]</td>
                </tr>
                <tr>
                    <td className={s.labColFirst}>High</td>
                    <td className={s.labColRegular}>29.15% [23.56%, 35.25%]</td>
                    <td className={s.labColRegular}>2.27 [1.79, 2.89]</td>
                    <td className={s.labColRegular}>14.22% [9.73%, 19.77%]</td>
                    <td className={s.labColRegular}>1.74 [1.21, 2.52]</td>
                </tr>
                <tr>
                    <td className={s.labColFirst}>Very High</td>
                    <td className={s.labColRegular}>56.52% [34.49%, 76.81%]</td>
                    <td className={s.labColRegular}>7.18 [3.18, 16.2]</td>
                    <td className={s.labColRegular}>50.00% [27.2%, 72.8%]</td>
                    <td className={s.labColRegular}>10.52 [4.42, 25.01]</td>
                </tr>
                </tbody>
            </table>

        </div>
    );
};

export const DemographicsPerformance = () => {
    return (
        <div>
            <h2 className={s.subHeader}>Demographic Subgroup Performance</h2>
            <p>
                Sepsis ImmunoScore performance results were grouped by varying demographic groups
                for age, sex, race, ethnicity, and immunocompromised group. The clinical study was not adequately
                powered to evaluate differences in demographics and subpopulations; therefore results should
                be interpreted in conjunction with clinical assessments and other laboratory findings.
            </p>


            <Tabs defaultActiveKey="1">
                <TabPane tab="Sex" key="1">
                    <table className={s.tableInterpretation}>
                        <caption className={s.labCaption}>
                            Model performance of Sepsis ImmunoScore™ grouped by risk category and sex.
                        </caption>
                        <thead className={s.tableHeader}>
                        <tr>
                            <th className={s.labColWide}>Sepsis Risk Category</th>
                            <th className={s.labColRegular}>Sex</th>
                            <th className={s.labColRegular}>PV [95% CI]</th>
                            <th className={s.labColRegular}>SSLR [95% CI]</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Low</td>
                            <td className={s.labColRegular}>Female</td>
                            <td className={s.labColRegular}>3.31% [0.91%, 8.25%]</td>
                            <td className={s.labColRegular}>0.15 [0.06, 0.38]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Male</td>
                            <td className={s.labColRegular}>2.70% [0.56%, 7.70%]</td>
                            <td className={s.labColRegular}>0.09 [0.03, 0.27]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Medium</td>
                            <td className={s.labColRegular}>Female</td>
                            <td className={s.labColRegular}>10.17% [3.82%, 20.83%]</td>
                            <td className={s.labColRegular}>0.49 [0.22, 1.08]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Male</td>
                            <td className={s.labColRegular}>14.29% [8.04%, 22.81%]</td>
                            <td className={s.labColRegular}>0.53 [0.32, 0.89]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>High</td>
                            <td className={s.labColRegular}>Female</td>
                            <td className={s.labColRegular}>34.75% [26.22%, 44.06%]</td>
                            <td className={s.labColRegular}>2.29 [1.78, 2.93]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Male</td>
                            <td className={s.labColRegular}>37.97% [30.38%, 46.03%]</td>
                            <td className={s.labColRegular}>1.96 [1.57, 2.45]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Very High</td>
                            <td className={s.labColRegular}>Female</td>
                            <td className={s.labColRegular}>77.78% [39.99%, 97.19%]</td>
                            <td className={s.labColRegular}>15.03 [3.20, 70.46]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Male</td>
                            <td className={s.labColRegular}>66.67% [44.68%, 84.37%]</td>
                            <td className={s.labColRegular}>6.41 [2.83, 14.49]</td>
                        </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Race" key="2">
                    <table className={s.tableInterpretation}>
                        <caption className={s.labCaption}>
                            Model performance of Sepsis ImmunoScore™ grouped by risk category and race.
                        </caption>
                        <thead className={s.tableHeader}>
                        <tr>
                            <th className={s.labColWide}>Sepsis Risk Category</th>
                            <th className={s.labColRegular}>Race</th>
                            <th className={s.labColRegular}>PV [95% CI]</th>
                            <th className={s.labColRegular}>SSLR [95% CI]</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Low</td>
                            <td className={s.labColRegular}>Other</td>
                            <td className={s.labColRegular}>3.00% [0.62%, 8.52%]</td>
                            <td className={s.labColRegular}>0.13 [0.04, 0.40]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>White</td>
                            <td className={s.labColRegular}>3.03% [0.83%, 7.58%]</td>
                            <td className={s.labColRegular}>0.10 [0.04, 0.27]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Medium</td>
                            <td className={s.labColRegular}>Other</td>
                            <td className={s.labColRegular}>10.45% [4.30%, 20.35%]</td>
                            <td className={s.labColRegular}>0.50 [0.24, 1.03]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>White</td>
                            <td className={s.labColRegular}>14.44% [7.92%, 23.43%]</td>
                            <td className={s.labColRegular}>0.55 [0.32, 0.94]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>High</td>
                            <td className={s.labColRegular}>Other</td>
                            <td className={s.labColRegular}>33.04% [24.44%, 42.56%]</td>
                            <td className={s.labColRegular}>2.11 [1.62, 2.74]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>White</td>
                            <td className={s.labColRegular}>39.02% [31.51%, 46.94%]</td>
                            <td className={s.labColRegular}>2.08 [1.68, 2.58]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Very High</td>
                            <td className={s.labColRegular}>Other</td>
                            <td className={s.labColRegular}>72.73% [39.03%, 93.98%]</td>
                            <td className={s.labColRegular}>11.39 [3.12, 41.56]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>White</td>
                            <td className={s.labColRegular}>68.18% [45.13%, 86.14%]</td>
                            <td className={s.labColRegular}>6.96 [2.93, 16.58]</td>
                        </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Ethnicity" key="3">
                    <table className={s.tableInterpretation}>
                        <caption className={s.labCaption}>
                            Model performance of Sepsis ImmunoScore™ grouped by risk category and ethnicity.
                        </caption>
                        <thead className={s.tableHeader}>
                        <tr>
                            <th className={s.labColWide}>Sepsis Risk Category</th>
                            <th className={s.labColRegular}>Ethnicity</th>
                            <th className={s.labColRegular}>PV [95% CI]</th>
                            <th className={s.labColRegular}>SSLR [95% CI]</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Low</td>
                            <td className={s.labColRegular}>Hispanic</td>
                            <td className={s.labColRegular}>2.94% [0.07%, 15.33%]</td>
                            <td className={s.labColRegular}>0.29 [0.05, 1.90]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Non-Hispanic</td>
                            <td className={s.labColRegular}>3.17% [1.17%, 6.78%]</td>
                            <td className={s.labColRegular}>0.10 [0.05, 0.23]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Unknown</td>
                            <td className={s.labColRegular}>0.00% [0.00%, 33.63%]</td>
                            <td className={s.labColRegular}>0.00 [0.00, NaN]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Medium</td>
                            <td className={s.labColRegular}>Hispanic</td>
                            <td className={s.labColRegular}>4.35% [0.11%, 21.95%]</td>
                            <td className={s.labColRegular}>0.44 [0.07, 2.89]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Non-Hispanic</td>
                            <td className={s.labColRegular}>14.75% [8.98%, 22.31%]</td>
                            <td className={s.labColRegular}>0.55 [0.35, 0.87]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Unknown</td>
                            <td className={s.labColRegular}>8.33% [0.21%, 38.48%]</td>
                            <td className={s.labColRegular}>0.40 [0.06, 2.63]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>High</td>
                            <td className={s.labColRegular}>Hispanic</td>
                            <td className={s.labColRegular}>15.79% [6.02%, 31.25%]</td>
                            <td className={s.labColRegular}>1.81 [1.06, 3.10]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Non-Hispanic</td>
                            <td className={s.labColRegular}>40.36% [33.86%, 47.12%]</td>
                            <td className={s.labColRegular}>2.15 [1.79, 2.59]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Unknown</td>
                            <td className={s.labColRegular}>33.33% [11.82%, 61.62%]</td>
                            <td className={s.labColRegular}>2.21 [1.11, 4.43]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Very High</td>
                            <td className={s.labColRegular}>Hispanic</td>
                            <td className={s.labColRegular}>100.00% [2.50%, 100.00%]</td>
                            <td className={s.labColRegular}>Inf [NaN, Inf]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Non-Hispanic</td>
                            <td className={s.labColRegular}>70.00% [50.60%, 85.27%]</td>
                            <td className={s.labColRegular}>7.41 [3.48, 15.80]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>Unknown</td>
                            <td className={s.labColRegular}>50.00% [1.26%, 98.74%]</td>
                            <td className={s.labColRegular}>4.43 [0.31, 62.54]</td>
                        </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Age" key="4">
                    <table className={s.tableInterpretation}>
                        <caption className={s.labCaption}>
                            Model performance of Sepsis ImmunoScore™ grouped by risk category and age.
                        </caption>
                        <thead className={s.tableHeader}>
                        <tr>
                            <th className={s.labColWide}>Sepsis Risk Category</th>
                            <th className={s.labColRegular}>Age</th>
                            <th className={s.labColRegular}>PV [95% CI]</th>
                            <th className={s.labColRegular}>SSLR [95% CI]</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Low</td>
                            <td className={s.labColRegular}>&lt; 65</td>
                            <td className={s.labColRegular}>3.60% [1.18%, 8.19%]</td>
                            <td className={s.labColRegular}>0.19 [0.08, 0.44]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>65-74</td>
                            <td className={s.labColRegular}>1.79% [0.05%, 9.55%]</td>
                            <td className={s.labColRegular}>0.05 [0.01, 0.37]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>75+</td>
                            <td className={s.labColRegular}>2.70% [0.07%, 14.16%]</td>
                            <td className={s.labColRegular}>0.08 [0.01, 0.56]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Medium</td>
                            <td className={s.labColRegular}>&lt; 65</td>
                            <td className={s.labColRegular}>12.12% [5.38%, 22.49%]</td>
                            <td className={s.labColRegular}>0.70 [0.35, 1.37]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>65-74</td>
                            <td className={s.labColRegular}>12.20% [4.08%, 26.20%]</td>
                            <td className={s.labColRegular}>0.40 [0.17, 0.96]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>75+</td>
                            <td className={s.labColRegular}>13.04% [4.94%, 26.26%]</td>
                            <td className={s.labColRegular}>0.42 [0.19, 0.94]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>High</td>
                            <td className={s.labColRegular}>&lt; 65</td>
                            <td className={s.labColRegular}>30.48% [21.87%, 40.22%]</td>
                            <td className={s.labColRegular}>2.22 [1.65, 2.97]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>65-74</td>
                            <td className={s.labColRegular}>50.68% [38.72%, 62.60%]</td>
                            <td className={s.labColRegular}>2.97 [2.18, 4.05]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>75+</td>
                            <td className={s.labColRegular}>32.97% [23.47%, 43.61%]</td>
                            <td className={s.labColRegular}>1.39 [1.03, 1.86]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={3}>Very High</td>
                            <td className={s.labColRegular}>&lt; 65</td>
                            <td className={s.labColRegular}>72.73% [39.03%, 93.98%]</td>
                            <td className={s.labColRegular}>13.48 [3.70, 49.17]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>65-74</td>
                            <td className={s.labColRegular}>40.00% [5.27%, 85.34%]</td>
                            <td className={s.labColRegular}>1.93 [0.33, 11.16]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>75+</td>
                            <td className={s.labColRegular}>76.47% [50.10%, 93.19%]</td>
                            <td className={s.labColRegular}>9.16 [3.13, 26.81]</td>
                        </tr>
                        </tbody>
                    </table>
                </TabPane>
                <TabPane tab="Immunocompromised" key="5">
                    <table className={s.tableInterpretation}>
                        <caption className={s.labCaption}>
                            Model performance of Sepsis ImmunoScore™ grouped by risk category and immunocompromised
                            status.
                        </caption>
                        <thead className={s.tableHeader}>
                        <tr>
                            <th className={s.labColWide}>Sepsis Risk Category</th>
                            <th className={s.labColRegular}>Immunocompromised</th>
                            <th className={s.labColRegular}>PV [95% CI]</th>
                            <th className={s.labColRegular}>SSLR [95% CI]</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Low</td>
                            <td className={s.labColRegular}>Yes</td>
                            <td className={s.labColRegular}>0.00% [0.00%, 10.89%]</td>
                            <td className={s.labColRegular}>0.00 [0.00, NaN]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>No</td>
                            <td className={s.labColRegular}>3.50% [1.42%, 7.08%]</td>
                            <td className={s.labColRegular}>0.15 [0.07, 0.31]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Medium</td>
                            <td className={s.labColRegular}>Yes</td>
                            <td className={s.labColRegular}>4.26% [0.52%, 14.54%]</td>
                            <td className={s.labColRegular}>0.11 [0.03, 0.46]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>No</td>
                            <td className={s.labColRegular}>16.36% [10.00%, 24.62%]</td>
                            <td className={s.labColRegular}>0.82 [0.52, 1.29]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>High</td>
                            <td className={s.labColRegular}>Yes</td>
                            <td className={s.labColRegular}>42.86% [32.90%, 53.25%]</td>
                            <td className={s.labColRegular}>1.94 [1.52, 2.47]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>No</td>
                            <td className={s.labColRegular}>33.15% [26.28%, 40.58%]</td>
                            <td className={s.labColRegular}>2.07 [1.66, 2.59]</td>
                        </tr>
                        <tr>
                            <td className={s.labColFirst} rowSpan={2}>Very High</td>
                            <td className={s.labColRegular}>Yes</td>
                            <td className={s.labColRegular}>69.23% [38.57%, 90.91%]</td>
                            <td className={s.labColRegular}>5.82 [1.87, 18.08]</td>
                        </tr>
                        <tr>
                            <td className={s.labColRegular}>No</td>
                            <td className={s.labColRegular}>70.00% [45.72%, 88.11%]</td>
                            <td className={s.labColRegular}>9.76 [3.85, 24.76]</td>
                        </tr>
                        </tbody>
                    </table>
                </TabPane>
            </Tabs>
        </div>
    );
};

export const TrainingDataset = () => {
    return (
        <table className={s.tableInterpretation}>
            <thead className={s.tableHeader}>
            <tr>
                <th className={s.labColWide}>Demographic Information</th>
                <th className={s.labColRegular}>Derivation (N = 2366)</th>
            </tr>
            <tr>
                <td className={s.labColFirst}>Clinical Site (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td className={s.labColFirst}>Beth Israel Deaconess Medical Center – Boston, MA</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>OSF – Peoria, IL</td>
                <td className={s.labColRegular}>712 (30.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Jesse Brown VA - Chicago, IL</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Mercy Health - St. Louis, MO</td>
                <td className={s.labColRegular}>1061 (44.8)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Beaumont - Royal Oak, MI</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Carle Foundation Hospital – Urbana, IL</td>
                <td className={s.labColRegular}>593 (25.1)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Age (mean (SD))</td>
                <td className={s.labColFirst}>64.20 (16.59)</td>
            </tr>
            </thead>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Male (%)</td>
                <td className={s.labColFirst}>1195 (50.5)</td>
            </tr>
            </thead>
            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Race (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td className={s.labColFirst}>American Indian or Alaska Native</td>
                <td className={s.labColRegular}>1 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Asian</td>
                <td className={s.labColRegular}>12 (0.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Black or African American</td>
                <td className={s.labColRegular}>315 (13.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Native Hawaiian or Other Pacific Islander</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>85 (3.6)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>White</td>
                <td className={s.labColRegular}>1953 (82.5)</td>
            </tr>
            </tbody>


            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Ethnicity (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Hispanic or Latino</td>
                <td className={s.labColRegular}>26 (1.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Not Hispanic or Latino</td>
                <td className={s.labColRegular}>1725 (72.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>615 (26.0)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>High-Risk Comorbidities (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td className={s.labColFirst}>Acute Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>97 (4.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>History of Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>101 (4.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Congestive Heart Failure (%)</td>
                <td className={s.labColRegular}>583 (24.6)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peripheral Vascular Disease (%)</td>
                <td className={s.labColRegular}>225 (9.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Cerebrovascular Disease (%)</td>
                <td className={s.labColRegular}>130 (5.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Chronic Obstructive Pulmonary Disease (%)</td>
                <td className={s.labColRegular}>606 (25.6)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Dementia (%)</td>
                <td className={s.labColRegular}>167 (7.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Paralysis (%)</td>
                <td className={s.labColRegular}>68 (2.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes (%)</td>
                <td className={s.labColRegular}>630 (26.6)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes with complications (%)</td>
                <td className={s.labColRegular}>423 (17.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Renal Disease (%)</td>
                <td className={s.labColRegular}>659 (27.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Mild Liver Disease (%)</td>
                <td className={s.labColRegular}>118 (5.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Moderate and Severe Liver Disease (%)</td>
                <td className={s.labColRegular}>45 (1.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peptic Ulcer Disease (%)</td>
                <td className={s.labColRegular}>45 (1.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Rheumatologic Disease (%)</td>
                <td className={s.labColRegular}>105 (4.4)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>AIDS (%)</td>
                <td className={s.labColRegular}>17 (0.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Immunocompromised (%)</td>
                <td className={s.labColRegular}>470 (19.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>COVID-19 (%)</td>
                <td className={s.labColRegular}>189 (8.0)</td>
            </tr>
            </tbody>
        </table>
    );
};


export const ValidationDataset = () => {
    return (
        <table className={s.tableInterpretation}>
            <thead className={s.tableHeader}>
            <tr>
                <th className={s.labColWide}>Demographic Information</th>
                <th className={s.labColRegular}>Triggerable (N = 698)</th>
            </tr>
            <tr>
                <td className={s.labColFirst}>Clinical Site (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>BIDMC - Boston, MA</td>
                <td className={s.labColRegular}>356 (51.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Jesse Brown VA - Chicago, IL</td>
                <td className={s.labColRegular}>65 (9.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Beaumont - Royal Oak, MI</td>
                <td className={s.labColRegular}>277 (39.7)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Age (median [IQR])</td>
                <td className={s.labColFirst}>66.00 [54,77]</td>
            </tr>
            </thead>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Sex (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Male (%)</td>
                <td className={s.labColRegular}>391 (56.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Female (%)</td>
                <td className={s.labColRegular}>307 (46.0)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Race (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>American Indian or Alaska Native</td>
                <td className={s.labColRegular}>2 (0.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Asian</td>
                <td className={s.labColRegular}>14 (2.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Black or African American</td>
                <td className={s.labColRegular}>154 (22.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Native Hawaiian or Other Pacific Islander</td>
                <td className={s.labColRegular}>1 (0.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>119 (17.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>White</td>
                <td className={s.labColRegular}>408 (58.5)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Ethnicity (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Hispanic or Latino</td>
                <td className={s.labColRegular}>96 (13.8)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Not Hispanic or Latino</td>
                <td className={s.labColRegular}>564 (80.8)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>38 (5.4)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>High-Risk Comorbidities (%)</td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Acute Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>43 (6.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>History of Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>54 (7.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Congestive Heart Failure (%)</td>
                <td className={s.labColRegular}> 170 (24.4)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peripheral Vascular Disease (%)</td>
                <td className={s.labColRegular}>72 (10.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Cerebrovascular Disease (%)</td>
                <td className={s.labColRegular}>65 (9.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Chronic Obstructive Pulmonary Disease (%)</td>
                <td className={s.labColRegular}> 171 (24.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Dementia (%)</td>
                <td className={s.labColRegular}>72 (10.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Paralysis (%)</td>
                <td className={s.labColRegular}>20 (2.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes (%)</td>
                <td className={s.labColRegular}> 156 (22.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes with complications (%)</td>
                <td className={s.labColRegular}> 155 (22.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Renal Disease (%)</td>
                <td className={s.labColRegular}> 216 (30.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Mild Liver Disease (%)</td>
                <td className={s.labColRegular}>94 (13.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Moderate and Severe Liver Disease (%)</td>
                <td className={s.labColRegular}>55 (7.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peptic Ulcer Disease (%)</td>
                <td className={s.labColRegular}>13 (1.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Rheumatologic Disease (%)</td>
                <td className={s.labColRegular}>33 (4.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>AIDS (%)</td>
                <td className={s.labColRegular}>43 (6.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Immunocompromised (%)</td>
                <td className={s.labColRegular}>54 (7.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>COVID-19 (%)</td>
                <td className={s.labColRegular}> 170 (24.4)</td>
            </tr>
            </tbody>
        </table>
    );
};

export const TrainingAndValidationDataset = () => {
    return (
        <table className={s.tableInterpretation}>
            <thead className={s.tableHeader}>
            <tr>
                <th className={s.labColWide} rowSpan={2}>Demographic Information</th>
                <th className={s.labColRegular}>Training Dataset</th>
                <th className={s.labColRegular}>Validation Dataset</th>
            </tr>
            <tr>
                <th className={s.labColRegular}>(N = 2366)</th>
                <th className={s.labColRegular}>(N = 698)</th>

            </tr>
            <tr>
                <td className={s.labColFirst}>Clinical Site (%)</td>
                <td className={s.labColFirst}></td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>

            <tbody>
            <tr>
                <td className={s.labColFirst}>Beth Israel Deaconess Medical Center – Boston, MA</td>
                <td className={s.labColRegular}>0 (0.0)</td>
                <td className={s.labColRegular}>356 (51.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>OSF – Peoria, IL</td>
                <td className={s.labColRegular}>712 (30.1)</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Jesse Brown VA - Chicago, IL</td>
                <td className={s.labColRegular}>0 (0.0)</td>
                <td className={s.labColRegular}>65 (9.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Mercy Health - St. Louis, MO</td>
                <td className={s.labColRegular}>1061 (44.8)</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Beaumont - Royal Oak, MI</td>
                <td className={s.labColRegular}>0 (0.0)</td>
                <td className={s.labColRegular}>277 (39.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Carle Foundation Hospital – Urbana, IL</td>
                <td className={s.labColRegular}>593 (25.1)</td>
                <td className={s.labColRegular}>0 (0.0)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Age (mean (SD))</td>
                <td className={s.labColFirst}>64.20 (16.59)</td>
                <td className={s.labColFirst}>62.80 (17.01)</td>
            </tr>
            </thead>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Sex (%)</td>
                <td className={s.labColFirst}></td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Male (%)</td>
                <td className={s.labColFirst}>1195 (50.5)</td>
                <td className={s.labColFirst}>391 (56.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Female (%)</td>
                <td className={s.labColFirst}>1171 (49.5)</td>
                <td className={s.labColFirst}>307 (44.0)</td>
            </tr>
            </tbody>
            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Race (%)</td>
                <td className={s.labColFirst}></td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>American Indian or Alaska Native</td>
                <td className={s.labColRegular}>1 (0.0)</td>
                <td className={s.labColRegular}>2 (0.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Asian</td>
                <td className={s.labColRegular}>12 (0.5)</td>
                <td className={s.labColRegular}>14 (2.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Black or African American</td>
                <td className={s.labColRegular}>315 (13.3)</td>
                <td className={s.labColRegular}>154 (22.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Native Hawaiian or Other Pacific Islander</td>
                <td className={s.labColRegular}>0 (0.0)</td>
                <td className={s.labColRegular}>1 (0.1)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>85 (3.6)</td>
                <td className={s.labColRegular}>119 (17.0)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>White</td>
                <td className={s.labColRegular}>1953 (82.5)</td>
                <td className={s.labColRegular}>408 (58.5)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>Ethnicity (%)</td>
                <td className={s.labColFirst}></td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Hispanic or Latino</td>
                <td className={s.labColRegular}>26 (1.1)</td>
                <td className={s.labColRegular}>96 (13.8)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Not Hispanic or Latino</td>
                <td className={s.labColRegular}>1725 (72.9)</td>
                <td className={s.labColRegular}>564 (80.8)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Unknown</td>
                <td className={s.labColRegular}>615 (26.0)</td>
                <td className={s.labColRegular}>38 (5.4)</td>
            </tr>
            </tbody>

            <thead className={s.tableHeader}>
            <tr>
                <td className={s.labColFirst}>High-Risk Comorbidities (%)</td>
                <td className={s.labColFirst}></td>
                <td className={s.labColFirst}></td>
            </tr>
            </thead>
            <tbody>
            <tr>
                <td className={s.labColFirst}>Acute Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>97 (4.1)</td>
                <td className={s.labColRegular}>43 (6.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>History of Myocardial Infarction (%)</td>
                <td className={s.labColRegular}>101 (4.3)</td>
                <td className={s.labColRegular}>54 (7.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Congestive Heart Failure (%)</td>
                <td className={s.labColRegular}>583 (24.6)</td>
                <td className={s.labColRegular}>170 (24.4)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peripheral Vascular Disease (%)</td>
                <td className={s.labColRegular}>225 (9.5)</td>
                <td className={s.labColRegular}>72 (10.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Cerebrovascular Disease (%)</td>
                <td className={s.labColRegular}>130 (5.5)</td>
                <td className={s.labColRegular}>65 (9.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Chronic Obstructive Pulmonary Disease (%)</td>
                <td className={s.labColRegular}>606 (25.6)</td>
                <td className={s.labColRegular}>171 (24.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Dementia (%)</td>
                <td className={s.labColRegular}>167 (7.1)</td>
                <td className={s.labColRegular}>72 (10.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Paralysis (%)</td>
                <td className={s.labColRegular}>68 (2.9)</td>
                <td className={s.labColRegular}>20 (2.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes (%)</td>
                <td className={s.labColRegular}>630 (26.6)</td>
                <td className={s.labColRegular}>156 (22.3)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Diabetes with complications (%)</td>
                <td className={s.labColRegular}>423 (17.9)</td>
                <td className={s.labColRegular}>155 (22.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Renal Disease (%)</td>
                <td className={s.labColRegular}>659 (27.9)</td>
                <td className={s.labColRegular}>216 (30.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Mild Liver Disease (%)</td>
                <td className={s.labColRegular}>118 (5.0)</td>
                <td className={s.labColRegular}>94 (13.5)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Moderate and Severe Liver Disease (%)</td>
                <td className={s.labColRegular}>45 (1.9)</td>
                <td className={s.labColRegular}>55 (7.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Peptic Ulcer Disease (%)</td>
                <td className={s.labColRegular}>45 (1.9)</td>
                <td className={s.labColRegular}>13 (1.9)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Rheumatologic Disease (%)</td>
                <td className={s.labColRegular}>105 (4.4)</td>
                <td className={s.labColRegular}>33 (4.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>AIDS (%)</td>
                <td className={s.labColRegular}>17 (0.7)</td>
                <td className={s.labColRegular}>43 (6.2)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>Immunocompromised (%)</td>
                <td className={s.labColRegular}>470 (19.9)</td>
                <td className={s.labColRegular}>54 (7.7)</td>
            </tr>
            <tr>
                <td className={s.labColFirst}>COVID-19 (%)</td>
                <td className={s.labColRegular}>189 (8.0)</td>
                <td className={s.labColRegular}> 170 (24.4)</td>
            </tr>
            </tbody>
        </table>
    );
};