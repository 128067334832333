import { SVGProps } from 'react';

export const RangesArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={8} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 7 4 0 0 7h3.333v7h1.334V7H8Z"
            fill="#000"
            fillOpacity={0.85}
        />
    </svg>
);
