import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import { BackArrowIcon } from 'src/icons/BackArrowIcon';
import { SaveIcon } from 'src/icons/SaveIcon';

import s from './ConfigureMapperHeader.module.scss';
import { buttonStyle, iconStyle, onClickCancel } from './utils';

interface Props {
    saveNormalization: () => void;
}

export function ConfigureMapperHeader({ saveNormalization }: Props) {
    return (
        <div className={s.headerContainer}>
            <div>
                <span className={s.backArrowIcon} onClick={() => window.history.back()}>
                    <BackArrowIcon />
                </span>
                <span className={s.title}>Configure Mapper</span>
            </div>
            <div className={s.buttonsContainer}>
                <Button
                    onClick={() => onClickCancel(<ExclamationCircleOutlined style={iconStyle} />)}
                >
                    Cancel
                </Button>
                <Button type="primary" style={buttonStyle} onClick={saveNormalization}>
                    <div className={s.saveIcon}>
                        <SaveIcon />
                    </div>
                    <div className={s.saveExitTitle}>Save & Exit</div>
                </Button>
            </div>
        </div>
    );
}
