import { SVGProps } from 'react';

export const RangesArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
    <svg width={8} height={14} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="m8 7-4 7-4-7h3.333V0h1.334v7H8Z"
            fill="#000"
            fillOpacity={0.85}
        />
    </svg>
);
