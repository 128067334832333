import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData/index';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { NormalRangesForm } from 'src/components/NormalRangesForm';

import { useNormalRanges } from './hooks';
import s from './OrganizationNormalRanges.module.scss';
import { useOrganizationId } from '../OrganizationDetails/hooks';

export function OrganizationNormalRanges() {
    const organizationId = useOrganizationId();

    if (!organizationId) {
        console.error('No organizationId');
        return <></>;
    }

    const { initialValuesRD, submitForm } = useNormalRanges(organizationId);

    return (
        <BaseLayout showHeader={true} organizationId={organizationId}>
            <div className={s.container}>
                <RenderRemoteData
                    remoteData={initialValuesRD}
                    renderLoading={() => <DotPulseLoader />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {(initialValues) => {
                        return (
                            <NormalRangesForm
                                initialValues={initialValues}
                                submitForm={submitForm}
                            />
                        );
                    }}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
}
