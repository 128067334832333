export const RedWarningIcon = () => (
    //@ts-ignore
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="12" height="12" rx="6" fill="#FF3F3D" />
        <path
            d="M6.67979 5.97284V3H5.25V5.97284H6.67979ZM6.67979 8.91111V7.42469H5.25V8.91111H6.67979Z"
            fill="white"
        />
    </svg>
);
