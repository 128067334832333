import { User } from 'shared/src/contrib/aidbox';


export const userRole = {
    admin: {
        name: 'Administrator',
        description: ['configure organizations', 'manage user permissions', 'view ImmunoScores'],
    },
    clinicAdmin: {
        name: 'Organization Manager',
        description: ['configure organizations', 'view ImmunoScores'],
    },
    immunoScoreViewer: {
        name: 'ImmunoScore Viewer',
        description: ['view ImmunoScores'],
    },
    unprivileged: {
        name: 'Unprivileged',
        description: ['no permissions'],
    },
};

export type UserRoleValue = 'admin' | 'clinicAdmin' | 'immunoScoreViewer' | 'unprivileged';

export function getUserRole(user: User): UserRoleValue {
    if (user.id === 'admin') {
        return 'admin';
    }
    const isAdmin = user.data?.admin;
    if (isAdmin) {
        return 'admin';
    }
    const isClinicAdmin = user.data?.clinicAdmin;
    if (isClinicAdmin) {
        return 'clinicAdmin';
    }
    const isImmunoScoreViewer = user.data?.immunoScoreViewer;
    if (isImmunoScoreViewer) {
        return 'immunoScoreViewer';
    }
    return 'unprivileged';
}
