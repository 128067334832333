import React from 'react';

import { ScoreParameter } from 'src/containers/ImmunoScoreDetails/types';

import { useTableBlock } from './hooks';
import s from './TableBlock.module.scss';
import { TableBlockType } from '..';
import { TableHeader } from '../TableHeader';
import { TableItem } from '../TableItem';

interface TableBlockProps {
    parameters: ScoreParameter[];
    type: TableBlockType;
}

export function TableBlock({ parameters, type }: TableBlockProps) {
    const { sortedParams } = useTableBlock(parameters, type);

    return (
        <div className={type !== 'unavailable' ? s.container : s.containerGrey}>
            <TableHeader type={type} />
            {sortedParams?.map((parameter, index) => (
                <React.Fragment key={index}>
                    <TableItem parameterItem={parameter} />
                    {sortedParams && index !== sortedParams.length - 1 && (
                        <div className={s.separator} />
                    )}
                </React.Fragment>
            ))}
        </div>
    );
}
