import { CSSProperties } from 'react';

import { ScoreConfig } from '../../containers/ImmunoScoreDetails/types';

const riskGroupTitleMainStyle = {
    letterSpacing: '0.06em',
    fontWeight: 500,
};

const valueScoreMainStyle = {
    fontSize: 12,
    fontWeight: 500,
    position: 'absolute' as 'absolute',
    marginTop: -2,
};

const fullScaleWidth = 1032;

const distanceBetweenScales = 2;

const calcPaddingLeft = (
    fromPosition: number,
    toPosition: number,
    minScore: number,
    maxScore: number,
    score: number,
    border: number,
) => {
    const distance = ((toPosition - fromPosition) * (score - minScore)) / (maxScore - minScore + 1);

    return distance + fromPosition - border * 2 + distanceBetweenScales / 2 + 'px';
};

interface Props {
    immunoScoreValue?: number;
    scoreConfig: ScoreConfig;
}

export function useRiskScoreScale({ immunoScoreValue, scoreConfig }: Props) {
    let score = immunoScoreValue;

    const { riskCategoryThreshold, riskBarWidthPercent } = scoreConfig;

    const scalesWidthPixel = {
        low: (fullScaleWidth / 100) * riskBarWidthPercent.low,
        medium: (fullScaleWidth / 100) * riskBarWidthPercent.medium - 2,
        high: (fullScaleWidth / 100) * riskBarWidthPercent.high - 2,
        veryHigh: (fullScaleWidth / 100) * riskBarWidthPercent.veryHigh - 2,
    };

    const lowScaleStyle = {
        position: 'absolute' as 'absolute',
        height: '14px',
        width: scalesWidthPixel.low,
        marginTop: '28px',
        backgroundColor: '#02C165',
        borderTopLeftRadius: 6.5,
        borderBottomLeftRadius: 6.5,
        zIndex: 100,
    };

    const mediumScaleStyle = {
        position: 'absolute' as 'absolute',
        height: '14px',
        width: scalesWidthPixel.medium,
        marginTop: 28,
        marginLeft: scalesWidthPixel.low + distanceBetweenScales,
        backgroundColor: '#FF7802',
        zIndex: 100,
    };

    const highScaleStyle = {
        position: 'absolute' as 'absolute',
        height: '14px',
        width: scalesWidthPixel.high,
        marginTop: 28,
        marginLeft: scalesWidthPixel.low + scalesWidthPixel.medium + distanceBetweenScales * 2,
        backgroundColor: '#FF3F3D',
        zIndex: 100,
    };

    const veryHighScaleStyle = {
        position: 'absolute' as 'absolute',
        height: '14px',
        width: scalesWidthPixel.veryHigh,
        marginTop: 28,
        marginLeft:
            scalesWidthPixel.low +
            scalesWidthPixel.medium +
            scalesWidthPixel.high +
            distanceBetweenScales * 3,
        backgroundColor: '#C2160C',
        borderTopRightRadius: 6.5,
        borderBottomRightRadius: 6.5,
        zIndex: 100,
    };

    const scales = {
        low: {
            fromPosition: 0,
            toPosition: lowScaleStyle.width,
            minScore: 0,
            maxScore: riskCategoryThreshold.low - 1,
        },
        medium: {
            fromPosition: mediumScaleStyle.marginLeft,
            toPosition: mediumScaleStyle.marginLeft + mediumScaleStyle.width,
            minScore: riskCategoryThreshold.low,
            maxScore: riskCategoryThreshold.medium - 1,
        },
        high: {
            fromPosition: highScaleStyle.marginLeft,
            toPosition: highScaleStyle.marginLeft + highScaleStyle.width,
            minScore: riskCategoryThreshold.medium,
            maxScore: riskCategoryThreshold.high - 1,
        },
        veryHigh: {
            fromPosition: veryHighScaleStyle.marginLeft,
            toPosition: veryHighScaleStyle.marginLeft + veryHighScaleStyle.width,
            minScore: riskCategoryThreshold.high,
            maxScore: 100,
        },
        full: {
            fromPosition: 0,
            toPosition: fullScaleWidth,
            minScore: 0,
            maxScore: 100,
            border: 8.5,
        },
    };

    const border = scales.full.border;

    let paddingLeft = '0px';

    if (!score || score < 0 || score > 100) {
        score = 0;
    }

    if (score > scales.low.minScore && score <= scales.low.maxScore) {
        paddingLeft = calcPaddingLeft(
            scales.low.fromPosition,
            scales.low.toPosition,
            scales.low.minScore,
            scales.low.maxScore,
            score,
            border,
        );
    }

    if (score > scales.low.maxScore && score <= scales.medium.maxScore) {
        paddingLeft = calcPaddingLeft(
            scales.medium.fromPosition,
            scales.medium.toPosition,
            scales.medium.minScore,
            scales.medium.maxScore,
            score,
            border,
        );
    }

    if (score > scales.medium.maxScore && score <= scales.high.maxScore) {
        paddingLeft = calcPaddingLeft(
            scales.high.fromPosition,
            scales.high.toPosition,
            scales.high.minScore,
            scales.high.maxScore,
            score,
            border,
        );
    }

    if (score > scales.high.maxScore && score <= scales.veryHigh.maxScore) {
        paddingLeft = calcPaddingLeft(
            scales.veryHigh.fromPosition,
            scales.veryHigh.toPosition,
            scales.veryHigh.minScore,
            scales.veryHigh.maxScore,
            score,
            border,
        );
        if (score === 100) {
            paddingLeft = fullScaleWidth - border * 2 + 'px';
        }
    }

    const riskCategoryThresholdPosition = {
        low: scalesWidthPixel.low - (riskCategoryThreshold.low < 10 ? 1 : 5),
        medium:
            scalesWidthPixel.low +
            scalesWidthPixel.medium -
            (riskCategoryThreshold.medium < 10 ? 1 : 4),
        high:
            scalesWidthPixel.low +
            scalesWidthPixel.medium +
            scalesWidthPixel.high -
            (riskCategoryThreshold.high < 10 ? 0 : 0),
        veryHigh: fullScaleWidth - border - 10,
    };

    const styles = {
        riskGroupLowArrow: {
            position: 'absolute',
            marginTop: 27,
            marginLeft: riskCategoryThresholdPosition.low - scalesWidthPixel.low / 2,
        },
        riskGroupLowTitle: {
            ...riskGroupTitleMainStyle,
            ...{ marginLeft: -10, marginTop: -8 },
        },
        riskGroupMediumArrow: {
            position: 'absolute',
            marginTop: 27,
            marginLeft: riskCategoryThresholdPosition.medium - scalesWidthPixel.medium / 2,
        },
        riskGroupMediumTitle: {
            ...riskGroupTitleMainStyle,
            ...{ marginLeft: -24, marginTop: -8 },
        },
        riskGroupHighArrow: {
            position: 'absolute',
            marginTop: 27,
            marginLeft: riskCategoryThresholdPosition.high - scalesWidthPixel.high / 2,
        },
        riskGroupHighTitle: {
            ...riskGroupTitleMainStyle,
            ...{ marginLeft: -12, marginTop: -8 },
        },
        riskGroupVeryHighArrow: {
            position: 'absolute',
            marginTop: 27,
            marginLeft: riskCategoryThresholdPosition.veryHigh - scalesWidthPixel.veryHigh / 2 + 10,
        },
        riskGroupVeryHighTitle: {
            ...riskGroupTitleMainStyle,
            ...{ marginLeft: -32 - border * 1.5, marginTop: -8 },
        },
        riskCategoryThreshold: {
            zero: { ...valueScoreMainStyle },
            low: {
                ...valueScoreMainStyle,
                ...{ paddingLeft: riskCategoryThresholdPosition?.low },
            },
            medium: {
                ...valueScoreMainStyle,
                ...{ paddingLeft: riskCategoryThresholdPosition?.medium },
            },
            high: {
                ...valueScoreMainStyle,
                ...{ paddingLeft: riskCategoryThresholdPosition?.high },
            },
            veryHigh: {
                ...valueScoreMainStyle,
                ...{ paddingLeft: riskCategoryThresholdPosition?.veryHigh },
            },
        },
        scale: {
            low: lowScaleStyle,
            medium: mediumScaleStyle,
            high: highScaleStyle,
            veryHigh: veryHighScaleStyle,
        },
        immunoScore: score !== 0 ? { paddingLeft } : { marginLeft: -border * 2 },
        align: { offset: [0, 2] },
    };

    const tooltip = {
        low: {
            title: `${riskBarWidthPercent.low}% of patients are low risk`,
        },
        medium: {
            title: `${riskBarWidthPercent.medium}% of patients are medium risk`,
        },
        high: {
            title: `${riskBarWidthPercent.high}% of patients are high risk`,
        },
        veryHigh: {
            title: `${riskBarWidthPercent.veryHigh}% of patients are very high risk`,
        },
    };

    const tooltipArray = [
        { title: tooltip.low.title, style: styles.scale.low },
        { title: tooltip.medium.title, style: styles.scale.medium },
        { title: tooltip.high.title, style: styles.scale.high },
        { title: tooltip.veryHigh.title, style: styles.scale.veryHigh },
    ];

    const riskGroup = [
        {
            title: 'LOW',
            arrowStyle: styles.riskGroupLowArrow as CSSProperties,
            titleStyle: styles.riskGroupLowTitle,
        },
        {
            title: 'MEDIUM',
            arrowStyle: styles.riskGroupMediumArrow as CSSProperties,
            titleStyle: styles.riskGroupMediumTitle,
        },
        {
            title: 'HIGH',
            arrowStyle: styles.riskGroupHighArrow as CSSProperties,
            titleStyle: styles.riskGroupHighTitle,
        },
        {
            title: 'VERY HIGH',
            arrowStyle: styles.riskGroupVeryHighArrow as CSSProperties,
            titleStyle: styles.riskGroupVeryHighTitle,
        },
    ];

    const riskCategoryThresholdArray = [
        {
            value: 0,
            style: styles.riskCategoryThreshold.zero,
        },
        {
            value: Math.round(riskCategoryThreshold.low),
            style: styles.riskCategoryThreshold.low,
        },
        {
            value: Math.round(riskCategoryThreshold.medium),
            style: styles.riskCategoryThreshold.medium,
        },
        {
            value: Math.round(riskCategoryThreshold.high),
            style: styles.riskCategoryThreshold.high,
        },
        {
            value: 100,
            style: styles.riskCategoryThreshold.veryHigh,
        },
    ];

    return { styles, tooltipArray, riskGroup, riskCategoryThresholdArray };
}
