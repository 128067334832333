import { useContext, useState } from 'react';
import { useParams } from 'react-router';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources, WithId } from 'aidbox-react/lib/services/fhir';
import { mapSuccess, sequenceMap, service } from 'aidbox-react/lib/services/service';

import {
    Bundle,
    Coding,
    Composition,
    Observation,
    ObservationComponent,
    ServiceRequest,
} from 'shared/src/contrib/aidbox';

import { OrderStatus } from 'src/utils/score';

import {
    CodeSystemList,
    CompositionSectionCode,
    featureIsGroup,
    FeatureItem,
    Features,
    ParameterDelta,
    RequiredObservationItem,
    ScoreConfig,
    ScoreDetails,
    ScoreDetailsExpired,
    ScoreDetailsSuccess,
    ScoreDetailsWaiting,
    ScoreParameter,
} from './types';
import { sharedScoreConfig } from '../../sharedState';
import { InitialDataContext } from '../App/useApp';

export const useImmunoScoreDetails = () => {
    const params = useParams();

    const accessToken = params.accessToken;

    const contextData = useContext(InitialDataContext);
    const features = contextData?.features;

    if (!features) {
        if (!accessToken) {
            throw new Error('features not loaded');
        }
    }

    const [showHelpCenter, setShowHelpCenter] = useState(false);

    const [immunoScoreDetailsRD] = useService(async () => {
        const authConfig = accessToken
            ? {
                  headers: {
                      authorization: `Bearer ${accessToken}`,
                  },
              }
            : {};
        const featuresRD = await service<Features>({
            url: '/Observation/$features',
            ...authConfig,
        });
        const scoreConfigRD = await service<ScoreConfig>({
            url: '/$get-score-config',
            ...authConfig,
        });
        if (isSuccess(scoreConfigRD)) {
            sharedScoreConfig.setSharedState(scoreConfigRD.data);
        }
        const bundleRD = await service<Bundle<WithId<Composition | Observation | ServiceRequest>>>({
            url: `Composition/${params.orderId}/$get-data`,
            ...authConfig,
        });
        const responseRD = sequenceMap({ bundle: bundleRD, features: featuresRD });
        return mapSuccess(responseRD, ({ bundle, features }) => {
            const resourceMap = extractBundleResources(bundle);
            const composition = resourceMap.Composition[0];
            const observationList = resourceMap.Observation;
            const serviceRequestList = resourceMap.ServiceRequest;
            return transform(composition, observationList, serviceRequestList, features);
        });
    });

    return {
        immunoScoreDetailsRD,
        showHelpCenter,
        setShowHelpCenter,
    };
};

export const transform = (
    composition: Composition,
    observationList: Observation[],
    serviceRequestList: ServiceRequest[],
    features: Features,
    simplified: boolean = false,
): ScoreDetails => {
    if (!composition) {
        throw new Error(`ImmunoScore order not found`);
    }

    const immunoScoreObservationId = getImmunoScoreObservationId(composition);

    const immunoScoreObservation = findObservationById(observationList, immunoScoreObservationId);

    if (!immunoScoreObservation) {
        throw new Error(`Observation with id: ${immunoScoreObservationId} is not found`);
    }

    if (!immunoScoreObservation.effective?.dateTime) {
        throw new Error(
            `Observation with id: ${immunoScoreObservationId} has no effective dateTime`,
        );
    }

    const orderStatus = getOrderStatus(composition, immunoScoreObservation); // 'success' | 'waiting' | 'failure'

    let output: Partial<ScoreDetails> = {
        orderId: composition.id!,
        orderTime: immunoScoreObservation.effective?.dateTime,
        status: orderStatus,
    };

    const usageMode = composition.usageMode;
    const resultTime = composition.date;

    if (orderStatus === OrderStatus.Success) {
        output = output as ScoreDetailsSuccess;

        const immunoScoreValue = getImmunoScoreValue(immunoScoreObservation);
        output.interpretation = immunoScoreObservation.interpretation?.[0];
        output.resultTime = resultTime;
        output.immunoScoreValue = immunoScoreValue;
        output.usageMode = usageMode;

        if (!simplified) {
            const scoreParameters = getScoreParameters(composition, observationList, features);
            output.increasingParameters = scoreParameters.increasingParameters;
            output.decreasingParameters = scoreParameters.decreasingParameters;
            output.unavailableParameters = scoreParameters.unavailableParameters;
        }
        return output as ScoreDetailsSuccess;
    }
    output.required = getRequiredData(composition, serviceRequestList, features);

    if (orderStatus === OrderStatus.Waiting) {
        return output as ScoreDetailsWaiting;
    }

    if (orderStatus === OrderStatus.Failure) {
        (output as ScoreDetailsExpired).resultTime = resultTime;
        return output as ScoreDetailsExpired;
    }

    throw new Error(`Impossible transform output for Composition/${composition.id}`);
};

function ensureRequiredObservationItem(item: Partial<RequiredObservationItem>) {
    if (item.hasOwnProperty('code') && item.hasOwnProperty('display')) {
        return item as RequiredObservationItem;
    }
    throw new Error(`ensureRequiredObservationItem check invalid for ${JSON.stringify(item)}`);
}

export function getRequiredData(
    composition: Composition,
    serviceRequestList: ServiceRequest[],
    features: Features,
): ScoreDetails['required'] {
    const result: ScoreDetails['required'] = [];
    const requiredObservationRefs = getSectionEntryByCode(
        composition,
        CompositionSectionCode.RequiredObservations,
    ).map((ref) => ref.id);

    const serviceRequestListRequired = serviceRequestList.filter((sr) => {
        return requiredObservationRefs.includes(sr.id!);
    });

    Object.keys(features).forEach((code) => {
        const srs = serviceRequestListRequired.filter((sr) => {
            return sr.code?.coding?.[0].code === code;
        });
        if (!srs || srs.length === 0) {
            return;
        }
        const sr = srs.sort((prev, next) => (prev.authoredOn! < next.authoredOn! ? -1 : 1))[0];
        if (!code) {
            throw new Error(`There is no code for ServiceRequest/${sr.id}`);
        }
        const display = features[code].display;
        if (!display) {
            throw new Error(`There is no display for ServiceRequest/${sr.id}`);
        }
        const output: Partial<RequiredObservationItem> = {
            code,
            display,
        };
        output.orderTime = sr.authoredOn || sr.occurrence?.dateTime;
        const feature = features[code];
        if (featureIsGroup(feature)) {
            result.push(ensureRequiredObservationItem(output));
            return;
        }
        const isRequired = feature.required;
        const hours = feature.staleness_hours;
        const messages = {
            required: 'Required for ImmunoScore',
            recommended: 'Recommended for ImmunoScore',
        };
        const message = isRequired ? messages.required : messages.recommended;
        const helpText = code === 'age' ? '' : hours ? `No results within ${hours} hours` : '';
        output.message = message;
        output.isRequired = feature.required;
        output.helpText = helpText;
        output.isExternal = !!sr.identifier?.length;
        result.push(ensureRequiredObservationItem(output));
        return;
    });
    return result;
}

export function getScoreParameters(
    composition: Composition,
    observationList: Observation[],
    features: Features,
) {
    const parametersList = getSectionEntryByCode(
        composition,
        CompositionSectionCode.UsedObservations,
    );
    const parameters = parametersList.map((obsRef) => {
        const observation = observationList.find((obs) => obs.id === obsRef.id);
        if (!observation) {
            throw new Error(
                `Observation/${obsRef.id} not found while Composition/${composition.id} has reference to it`,
            );
        }
        return observationToParameterItem(observation, features);
    });
    return {
        increasingParameters: parameters.filter(parameterIsIncreasing),
        decreasingParameters: parameters.filter(parameterIsDecreasing),
        unavailableParameters: parameters.filter(parameterIsUnavailable),
    };
}

function parameterIsIncreasing(p: ScoreParameter) {
    if (p.isImputed) {
        return false;
    }
    return p.scoreImpact >= 0;
}

function parameterIsDecreasing(p: ScoreParameter) {
    if (p.isImputed) {
        return false;
    }
    return p.scoreImpact < 0;
}

function parameterIsUnavailable(p: ScoreParameter) {
    return p.isImputed;
}

export function getMainObservationComponent(componentList: ObservationComponent[]) {
    return componentList[0];
}

export function observationToParameterItem(
    observation: Observation,
    features: Features,
): ScoreParameter {
    if (!observation.component) {
        throw new Error(`No components for Observation/${observation.id}`);
    }

    const collectionTime = observation.effective?.dateTime;

    const mainComponent = getMainObservationComponent(observation.component);

    var value = null;
    var unit = null;

    const valueQuantity = mainComponent?.value?.Quantity;
    const valueCodeableConcept = mainComponent?.value?.CodeableConcept;

    if (!!valueQuantity || !!valueCodeableConcept) {
        if (valueQuantity) {
            value = valueQuantity.value === undefined ? null : valueQuantity.value;
            unit = valueQuantity.unit || null;
        } else {
            value = valueCodeableConcept?.text || valueCodeableConcept?.coding?.[0].display || null;
            unit = null;
        }
    } else {
        if (collectionTime) {
            console.log(observation);
            throw new Error(`No mainComponent for Observation/${observation.id}`);
        }
    }

    if (value === null) {
        if (collectionTime) {
            throw new Error(`No mainComponent value for Observation/${observation.id}`);
        }
    }

    const interpretationString = getObservationInterpretation(observation);
    if (!interpretationString) {
        throw new Error(`No value interpretation for Observation/${observation.id}`);
    }
    const code = getObservationCoding(observation).code;
    if (!code) {
        throw new Error(`Observation code is undefined for Observation/${observation.id}`);
    }

    const outputParameter: ScoreParameter = {
        id: observation.id!,
        code,
        // display: features[code].display!,
        display: getFeatureDisplay(features[code], observation),
        value: value,
        unit: unit || '',
        delta: interpretationString,
        //@ts-ignore
        scoreImpact: observation.shapValue ? +observation.shapValue : null,
        isImputed: collectionTime === undefined,
        stalenessHours: features[code]['staleness_hours'].toString(),
    };

    if (collectionTime) {
        outputParameter.collectionTime = collectionTime;
    }

    return outputParameter;
}

export function getFeatureDisplay(featureItem: FeatureItem, observation: Observation) {
    if (featureIsGroup(featureItem)) {
        return featureItem.display!;
    }
    const display = observation.code.text || observation.code.coding?.[0].display;
    const defaultDisplay =
        observation.component?.[1].code.text || observation.component?.[1].code.coding?.[0].display;
    return display || defaultDisplay || '';
}

export function getObservationCoding(observation: Observation): Coding {
    const codeSystem = CodeSystemList.ObservationCodes;
    const coding = observation.code.coding?.find((c) => c.system === codeSystem);
    if (!coding) {
        throw new Error(`Observation/${observation.id} has no code for system ${codeSystem}`);
    }
    if (!coding.code) {
        throw new Error(
            `No code in coding for system ${codeSystem} in Observation/${observation.id}`,
        );
    }
    return coding;
}

export function getObservationInterpretation(observation: Observation) {
    const interpretation = observation.interpretation?.[0]?.coding?.[0].code;

    let result = ParameterDelta.N;

    if (interpretation === 'L') {
        result = ParameterDelta.L;
    }

    if (interpretation === 'H') {
        result = ParameterDelta.H;
    }
    return result;
}

export function getSectionEntryByCode(
    composition: Composition,
    sectionCode: CompositionSectionCode,
) {
    return (
        composition.section?.find((section) => section?.code?.coding?.[0].code === sectionCode)
            ?.entry || []
    );
}

export function getImmunoScoreObservationId(composition: Composition): string {
    const immunoScoreSectionEntry = getSectionEntryByCode(
        composition,
        CompositionSectionCode.Score,
    );
    return immunoScoreSectionEntry && immunoScoreSectionEntry[0]?.id;
}

export function findObservationById(observationList: Observation[], observationId: string) {
    return observationList.find((observation) => observation.id === observationId);
}

export function getOrderStatus(
    composition: Composition,
    immunoScoreObservation: Observation,
): OrderStatus {
    if (composition.status === 'registered') {
        return OrderStatus.Waiting;
    }
    if (composition.status === 'final') {
        if (immunoScoreObservation.value?.string) {
            return OrderStatus.Success;
        }
        return OrderStatus.Failure;
    }
    throw new Error(
        `Cannot calculate order status for Composition/${composition.id} and Observation/${immunoScoreObservation.id}. Status ${composition.status} is not supported.`,
    );
}

export function getImmunoScoreValue(observation: Observation) {
    return observation.value?.string ? +(+observation.value?.string * 100).toFixed(0) : undefined;
}
