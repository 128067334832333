import { Divider, Form } from 'antd';
import { useForm } from 'react-hook-form';

import { NormalizationRule } from 'shared/src/contrib/aidbox';

import { featureIsGroup, Features } from 'src/containers/ImmunoScoreDetails/types';

import { NormalizationFormItem } from './NormalizationFormItem';
import s from './NormalizationRuleForm.module.scss';

interface NormalizationRuleFormProps {
    initialValues: Features;
    organizationId: string;
    onSave: (normalizationRule: NormalizationRule, code: string) => void;
    onDelete: (normalizationRule: NormalizationRule) => void;
}

const formLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 16 },
};

export function NormalizationRuleForm({
    initialValues,
    organizationId,
    onSave,
    onDelete,
}: NormalizationRuleFormProps) {
    const { control, watch } = useForm({ defaultValues: initialValues });

    const watchAllFields = watch();

    return (
        <Form {...formLayout}>
            {Object.entries(watchAllFields).map(([code, item]) => {
                if (featureIsGroup(item)) {
                    return (
                        <Divider key={code} orientation="left" className={s.divider}>
                            {item.display}
                        </Divider>
                    );
                }

                return (
                    <NormalizationFormItem
                        key={code}
                        code={code}
                        item={item}
                        control={control}
                        organizationId={organizationId}
                        onSave={onSave}
                        onDelete={onDelete}
                    />
                );
            })}
        </Form>
    );
}
