import { getColorByRisk } from 'src/utils/color';
import { getRiskStatusCategory, Score } from 'src/utils/score';

import { ScoreValue } from './ScoreValue';
import s from './ScoreView.module.scss';

export function ScoreView(props: Score) {
    const { status, value } = props;

    const riskCategory = getRiskStatusCategory(props);

    const wrapperStyle = { backgroundColor: getColorByRisk(riskCategory) };

    const descriptionStyle = status === 'failure' ? { color: '#748C97' } : {};

    return (
        <div className={s.wrapper} style={wrapperStyle}>
            <div className={s.percentValue}>
                <ScoreValue status={status} value={value} />
            </div>
            <div className={s.separator} />
            <div className={s.description} style={descriptionStyle}>
                <div>score for sepsis within 24 hours</div>
            </div>
        </div>
    );
}
