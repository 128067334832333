import { Tooltip } from 'antd';
import { CSSProperties } from 'react';

import { RiskGroupArrowIcon } from 'src/icons/RiskGroupArrowIcon';

import { Cursor } from './Cursor';
import s from './RiskScoreScale.module.scss';
import { useRiskScoreScale } from './useRiskScoreScale';
import { ScoreConfig } from '../../containers/ImmunoScoreDetails/types';

interface Props {
    immunoScoreValue?: number;
    scoreConfig: ScoreConfig;
}

export function RiskScoreScale(props: Props) {
    const { immunoScoreValue, scoreConfig } = props;

    const { styles, tooltipArray, riskGroup, riskCategoryThresholdArray } = useRiskScoreScale({
        immunoScoreValue,
        scoreConfig,
    });

    return (
        <div className={s.wrapper}>
            {tooltipArray.map((tooltip, index) => (
                <Tooltip key={index} placement="top" title={tooltip.title} align={styles.align}>
                    <div style={tooltip.style} />
                </Tooltip>
            ))}
            <div className={s.cursorContainer}>
                <div className={s.cursor} style={styles.immunoScore}>
                    <div className={s.riskCount}>{immunoScoreValue}</div>
                    <Cursor />
                </div>
            </div>
            <div className={s.scaleWrapper}>
                <div className={s.boundaryValues}>
                    {riskCategoryThresholdArray.map((score, index) => (
                        <div key={index} style={score.style}>
                            {score.value}
                        </div>
                    ))}
                </div>
                {riskGroup.map((group, index) => (
                    <div key={index} style={group.arrowStyle as CSSProperties}>
                        <RiskGroupArrowIcon />
                        <div style={group.titleStyle}>{group.title}</div>
                    </div>
                ))}
            </div>
        </div>
    );
}
