import { PageHeader } from 'antd';
import { useNavigate } from 'react-router-dom';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';
import { useService } from 'aidbox-react/lib/hooks/service';
import { RemoteData, success } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources, getFHIRResource, WithId } from 'aidbox-react/lib/services/fhir';
import { mapSuccess, sequenceMap } from 'aidbox-react/lib/services/service';

import { Organization } from 'shared/src/contrib/aidbox';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';
import { getSISResources } from 'src/services/sis';

import { useOrganizationId } from './hooks';
import s from './Organization.module.scss';
import { OrganizationForm } from './OrganizationForm';
import { OrganizationConfig } from './types';

export function OrganizationDetails() {
    const navigate = useNavigate();

    const organizationId = useOrganizationId();

    const [organizationRD] = useService(async () => {
        if (!organizationId) {
            return success(null);
        }
        const response = await getFHIRResource<Organization>({
            resourceType: 'Organization',
            id: organizationId as string,
        });

        return response;
    });

    const [organizationConfigRD] = useService(async () => {
        if (!organizationId) {
            return success(null);
        }

        const response = await getSISResources<OrganizationConfig>('OrganizationConfig', {
            organization: organizationId,
        });

        return mapSuccess(
            response,
            (bundle) => extractBundleResources(bundle).OrganizationConfig[0],
        );
    });

    const initialDataRD = sequenceMap({
        organization: organizationRD,
        organizationConfig: organizationConfigRD,
    });

    return (
        <BaseLayout
            showHeader={true}
            organizationId={organizationId}
            mainContainerStyle={!organizationId ? baseLayoutStyle : {}}
        >
            {!organizationId ? (
                <>
                    <PageHeader
                        className={s.header}
                        onBack={() => navigate('/organizations')}
                        title={'New Organization'}
                    />
                    <div className={s.wrapperNewOrganization}>
                        <OrganizationData initialDataRD={initialDataRD} />
                    </div>
                </>
            ) : (
                <div className={s.detailsContainer}>
                    <div className={s.wrapper}>
                        <OrganizationData initialDataRD={initialDataRD} />
                    </div>
                </div>
            )}
        </BaseLayout>
    );
}

interface OrganizationDataProps {
    initialDataRD: RemoteData<
        {
            organization: WithId<Organization> | null;
            organizationConfig: WithId<OrganizationConfig> | null;
        },
        unknown[]
    >;
}

const OrganizationData = ({ initialDataRD }: OrganizationDataProps) => (
    <RenderRemoteData
        remoteData={initialDataRD}
        renderLoading={() => <DotPulseLoader />}
        renderFailure={(error) => <AlertFailure error={error} />}
    >
        {({ organization, organizationConfig }) => (
            <OrganizationForm organization={organization} organizationConfig={organizationConfig} />
        )}
    </RenderRemoteData>
);

const baseLayoutStyle = {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    padding: '0',
    alignItems: 'center',
};
