import { notification } from 'antd';
import { useCallback, useState } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess, success, failure } from 'aidbox-react/lib/libs/remoteData';
import { extractBundleResources } from 'aidbox-react/lib/services/fhir';
import { SearchParams } from 'aidbox-react/lib/services/search';

import { Composition, Observation } from 'shared/src/contrib/aidbox';

import { transform } from 'src/containers/ImmunoScoreDetails/useImmunoScoreDetails';
import { baseLogout } from 'src/services/auth';
import { getSISResources } from 'src/services/sis';
import { OrderStatus, Score } from 'src/utils/score';
import { convertStringToParams } from 'src/utils/search';

import { ImmunoScoreTableItem } from '.';

const getPatientId = (composition: Composition): string => {
    let patientId;
    if (composition.subject?.identifier) {
        patientId = composition.subject.identifier.value;
    }
    if (!patientId) {
        throw new Error(`Can't get patient id for Composition/${composition.id}`);
    }
    return patientId;
};

const getHospitalId = (composition: Composition): string => {
    return composition.author[0].id; // TODO think about find by resource type
};

export const transformToImmunoScoreListTable = (
    composition: Composition,
    observations: Array<Observation>,
): ImmunoScoreTableItem => {
    const transformed = transform(composition, observations, [], {}, true);
    const output: Partial<ImmunoScoreTableItem> = {
        id: transformed.orderId,
        orderTime: transformed.orderTime,
        patientId: getPatientId(composition),
        hospitalId: getHospitalId(composition),
    };
    let score: Score = {
        status: transformed.status,
    };
    if (transformed.status === OrderStatus.Success) {
        output.resultTime = transformed.resultTime;
        score.value = transformed.immunoScoreValue;
        score.interpretation = transformed.interpretation;
    }
    if (transformed.status === OrderStatus.Failure) {
        output.resultTime = transformed.resultTime;
    }
    output.score = score;
    return output as ImmunoScoreTableItem;
};

const defaultSearchText = '_count=20&_sort=-date,lastUpdated&_ilike=&author=';

export const useImmunoScoreList = () => {
    const [searchText, setSearchText] = useState(defaultSearchText);

    const [searchParams, setSearchParams] = useState<SearchParams>(
        convertStringToParams(defaultSearchText),
    );

    const updateSearchParams = useCallback(() => {
        const searchParams = convertStringToParams(searchText);
        // max _count value is 200 since we use concatenated string of immunoScoreObservationIdList as a search param
        // and it has max possible length
        // This limitation can be fixed by a custom SQL query if necessary
        const countParam = searchParams['_count'];
        if (countParam && countParam > 200) {
            searchParams['_count'] = 200;
        }
        setSearchParams(searchParams);
    }, [searchText]);

    const [remoteData] = useService(async () => {
        const response = await getSISResources<Composition>('Composition', {
            ...searchParams,
        });
        if (isFailure(response)) {
            console.error(response.error);
            if (response.error?.message === 'JWT is expired') {
                baseLogout();
            }
            notification.error({ message: response.error });
            return response;
        }
        if (isSuccess(response)) {
            const compositionList = extractBundleResources(response.data).Composition;

            const immunoScoreObservationIdList = compositionList.map(
                (composition) => composition.section?.[0].entry?.[0].id,
            );

            const observationListRD = await getSISResources<Observation>('Observation', {
                _id: immunoScoreObservationIdList.join(','),
                _count: 1000,
            });

            if (isSuccess(observationListRD)) {
                const observationList = extractBundleResources(observationListRD.data).Observation;

                let output;
                try {
                    output = compositionList.map((composition) =>
                        transformToImmunoScoreListTable(composition, observationList),
                    );
                } catch (e) {
                    console.error(e);
                    return failure(String(e));
                }

                return success(output);
            }
            return observationListRD;
        }
        return response;
    }, [searchParams]);
    return { remoteData, searchText, setSearchText, updateSearchParams };
};
