import { EditFilled } from '@ant-design/icons';
import { Popover, Radio, Space, Table } from 'antd';
import Modal from 'antd/lib/modal/Modal';

import { RenderRemoteData } from 'aidbox-react/lib/components/RenderRemoteData';

import { User } from 'shared/src/contrib/aidbox';

import { AlertFailure } from 'src/components/AlertFailure';
import { BaseLayout } from 'src/components/BaseLayout';
import { DotPulseLoader } from 'src/components/DotPulseLoader';

import s from './UserList.module.scss';
import { useUserList } from './useUserList';
import { useUserRoleModal } from './useUserRoleModal';
import { getUserRole, userRole } from './utils';

export function UserList() {
    const { userListRD, reloadUserList } = useUserList();
    const {
        modalVisible,
        showUserRoleModal,
        closeModal,
        currentUser,
        saveUserRole,
        currentRole,
        onRoleChange,
    } = useUserRoleModal(reloadUserList);

    const columns = [
        {
            title: 'Email',
            key: 'email',
            dataIndex: 'email',
            render: (record: any, user: User) => user?.email || '-',
            width: '25%',
        },
        {
            title: 'Name',
            key: 'name',
            dataIndex: 'name',
            render: (record: any, user: User) => user?.data?.name || '-',
            width: '25%',
        },
        {
            title: 'Role',
            key: 'role',
            dataIndex: 'role',
            render: (record: any, user: User) => userRole[getUserRole(user)].name,
            width: '25%',
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'actions',
            render: (record: any, user: User) => (
                <Space
                    onClick={() => {
                        showUserRoleModal(user);
                    }}
                    className={s.actionSpace}
                >
                    <EditFilled />
                    <a className={s.actionLink}>Edit</a>
                </Space>
            ),
            width: '25%',
        },
    ];

    return (
        <BaseLayout showHeader={true} showMainMenu={true}>
            <Modal
                title={`Change user role for ${currentUser?.data?.name || 'user'}`}
                visible={modalVisible}
                onOk={saveUserRole}
                onCancel={closeModal}
                okText="Save"
            >
                <p>{currentUser?.email}</p>
                <h4>Choose a role:</h4>
                <Radio.Group onChange={onRoleChange} value={currentRole}>
                    <Space direction="vertical">
                        {Object.entries(userRole).map(([key, { name, description }]) => (
                            <Popover
                                placement={'left'}
                                key={key}
                                content={getUserRoleDescription(description)}
                                title={name}
                            >
                                <Radio key={key} value={key}>
                                    {name}
                                </Radio>
                            </Popover>
                        ))}
                    </Space>
                </Radio.Group>
            </Modal>
            <div className={s.wrapper}>
                <RenderRemoteData
                    remoteData={userListRD}
                    renderLoading={() => <DotPulseLoader />}
                    renderFailure={(error) => <AlertFailure error={error} />}
                >
                    {(tableData) => {
                        return (
                            <Table
                                columns={columns}
                                dataSource={tableData}
                                rowKey={(record) => record.id}
                            />
                        );
                    }}
                </RenderRemoteData>
            </div>
        </BaseLayout>
    );
}

function getUserRoleDescription(description: string[]) {
    return (
        <ul className={s.roleList}>
            {description.map((item) => (
                <li key={item}>{item}</li>
            ))}
        </ul>
    );
}
