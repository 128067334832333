interface PrognosticNumbersCategorySet {
    icu: number;
    mortality: number;
    ventilation: number;
    vasopressors: number;
    los: number;
    likelihoodRatio: number;
    predictiveValue: {
        value: number;
        interval: [number, number];
    };
}

interface PrognosticNumbers {
    veryHigh: PrognosticNumbersCategorySet;
    high: PrognosticNumbersCategorySet;
    medium: PrognosticNumbersCategorySet;
    low: PrognosticNumbersCategorySet;
}

export function usePrognosticNumbers() {
    const prognosticNumbers: PrognosticNumbers = {
        veryHigh: {
            icu: 54.55,
            mortality: 18.18,
            ventilation: 9.09,
            vasopressors: 39.39,
            los: 13.47,
            likelihoodRatio: 8.33,
            predictiveValue: {
                value: 69.70,
                interval: [51.29, 84.41],
            },
        },
        high: {
            icu: 25.72,
            mortality: 8.70,
            ventilation: 6.52,
            vasopressors: 11.59,
            los: 7.66,
            likelihoodRatio: 2.09,
            predictiveValue: {
                value: 36.59,
                interval: [30.90, 42.58],
            },
        },
        medium: {
            icu: 12.74,
            mortality: 1.91,
            ventilation: 3.82,
            vasopressors: 1.91,
            los: 5.68,
            likelihoodRatio: 0.53,
            predictiveValue: {
                value: 12.74,
                interval: [7.96, 18.99],
            },
        },
        low: {
            icu: 4.74,
            mortality: 0.00,
            ventilation: 2.59,
            vasopressors: 0.86,
            los: 4.00,
            likelihoodRatio: 0.11,
            predictiveValue: {
                value: 3.02,
                interval: [1.22, 6.12],
            },
        },
    };
    const AUROC = 0.81;
    return { prognosticNumbers, AUROC };
}