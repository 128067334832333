import { DatePicker, Form } from 'antd';
import moment from 'moment/moment';
import { Control, Controller, ControllerProps, FieldErrors } from 'react-hook-form';

import { OrganizationConfig } from '../../../containers/OrganizationDetails/types';
import { formaError, getErrorByFieldName } from '../utils';

const { RangePicker } = DatePicker;

interface RangePickerFieldProps {
    name: string;
    label?: string;
    control: Control<any>;
    errors: FieldErrors;
    rules?: ControllerProps['rules'];
    style?: React.CSSProperties | any;
}

export const getDisabledDate = (current: any) => {
    return current && current > moment().endOf('day');
};

function formatDateString(dateString: string) {
    return dateString === '' ? undefined : moment(dateString);
}

const getInitialReferencePeriod = (referencePeriod: OrganizationConfig['referencePeriod']) => {
    return [formatDateString(referencePeriod.start), formatDateString(referencePeriod.end)];
};

const chooseReferencePeriod = (dateString: string[]) => {
    if (dateString[0].length === 0 || dateString[1].length === 0) {
        return;
    }
    const start = new Date(dateString[0]).toISOString().slice(0, 19) + 'Z';
    const end = new Date(dateString[1]).toISOString().slice(0, 19) + 'Z';
    return { start, end };
};

export function RangePickerField(props: RangePickerFieldProps) {
    const { name, label, errors, control, style, rules = {} } = props;
    const error = getErrorByFieldName(errors, name);

    return (
        <Controller
            rules={rules}
            control={control}
            name={name}
            render={({ field }) => (
                <Form.Item
                    label={label}
                    name={name}
                    style={style || {}}
                    validateStatus={error && 'error'}
                    help={formaError(error)}
                >
                    <RangePicker
                        disabledDate={getDisabledDate}
                        format={'YYYY/MM/DD HH:mm'}
                        showTime={{ format: 'HH:mm' }}
                        //@ts-ignore
                        defaultValue={getInitialReferencePeriod(field.value)}
                        onChange={(data, dataString) =>
                            field.onChange(chooseReferencePeriod(dataString))
                        }
                    />
                </Form.Item>
            )}
        />
    );
}
