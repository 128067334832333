import { notification } from 'antd';
import { useSearchParams } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import {
    extractBundleResources,
    getFHIRResource,
    patchFHIRResource,
} from 'aidbox-react/lib/services/fhir';
import { mapSuccess } from 'aidbox-react/lib/services/service';

import { Organization } from 'shared/src/contrib/aidbox';

import { getSessionid } from 'src/services/auth';
import { getSISResources } from 'src/services/sis';

import { Record } from '.';

const sessionId = getSessionid();

const getOrganizationData = async (record: Record) => {
    const response = await getFHIRResource<Organization>({
        resourceType: 'Organization',
        id: record.key,
    });

    if (isSuccess(response)) {
        return response.data;
    }

    if (isFailure(response)) {
        notification['error']({
            message: 'Error',
            description: `Failure to receive organization data "${record.organization}"`,
        });
    }
};

export const useOrganizationList = () => {
    const [searchParams] = useSearchParams();

    const isActive = !searchParams.get('archived');

    const [organizationListRD, organizationListManagerRD] = useService(async () => {
        const response = await getSISResources<Organization>('Organization', {
            active: isActive,
            _sort: '-lastUpdated',
        });

        if (isFailure(response)) {
            console.error({ message: JSON.stringify(response.error) });
        }

        return mapSuccess(response, (bundle) =>
            extractBundleResources(bundle).Organization.map((organization) => ({
                key: organization.id,
                organization: organization.name,
                report: ['Download'], // should be empty if return Download icon
                status: [organization.mode],
            })),
        );
    }, [isActive]);

    const archiveOrganization = async (record: Record) => {
        const organizationData = await getOrganizationData(record);

        if (organizationData) {
            const newOrganizationData = { ...organizationData, active: false };

            const response = await patchFHIRResource(newOrganizationData);

            if (isSuccess(response)) {
                notification['success']({
                    message: 'Success',
                    description: `Organization "${record.organization}" archived`,
                });

                console.log(
                    JSON.stringify({
                        text: `Organization "${record.organization}" archived`,
                        response,
                        sessionId,
                    }),
                ); // log to kibana

                organizationListManagerRD.reload();
            }

            if (isFailure(response)) {
                console.log(
                    JSON.stringify({
                        text: `Organization "${record.organization}" archive failure`,
                        response,
                        sessionId,
                    }),
                ); // log to kibana

                notification['error']({
                    message: 'Error',
                    description: `Organization "${record.organization}" archive failure`,
                });
            }
        }
    };

    const restoreOrganization = async (record: Record) => {
        const organizationData = await getOrganizationData(record);

        if (organizationData) {
            const newOrganizationData = { ...organizationData, active: true };

            const response = await patchFHIRResource(newOrganizationData);

            if (isSuccess(response)) {
                notification['success']({
                    message: 'Success',
                    description: `Organization "${record.organization}" restored`,
                });

                console.log(
                    JSON.stringify({
                        text: `Organization "${record.organization}" restored`,
                        response,
                        sessionId,
                    }),
                ); // log to kibana

                organizationListManagerRD.reload();
            }

            if (isFailure(response)) {
                console.log(
                    JSON.stringify({
                        text: `Organization "${record.organization}" restore failure`,
                        response,
                        sessionId,
                    }),
                ); // log to kibana

                notification['error']({
                    message: 'Error',
                    description: `Organization "${record.organization}" restore failure`,
                });
            }
        }
    };

    return { organizationListRD, isActive, archiveOrganization, restoreOrganization };
};
