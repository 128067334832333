import { RiskStatusCategory } from 'src/utils/score';

import s from './ScoreColumn.module.scss';

interface Props {
    score?: string;
    category: RiskStatusCategory;
}

export function ScoreColumn({ score, category }: Props) {
    if (category === RiskStatusCategory.VeryHigh) {
        return <div className={s.veryHigh}>{score}</div>;
    }

    if (category === RiskStatusCategory.High) {
        return <div className={s.high}>{score}</div>;
    }

    if (category === RiskStatusCategory.Medium) {
        return <div className={s.medium}>{score}</div>;
    }

    if (category === RiskStatusCategory.Low) {
        return <div className={s.low}>{score}</div>;
    }

    if (category === RiskStatusCategory.ResultPending) {
        return <div className={s.resultPending}>?</div>;
    }

    if (category === RiskStatusCategory.NoResult) {
        return <div className={s.noResult}>-</div>;
    }

    throw new Error(`No status exists: ${category}`);
}
