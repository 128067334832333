import { UnControlled as CodeMirror } from 'react-codemirror2';

import { objectToDisplay } from 'src/utils/yaml';

interface SourceObservationProps {
    sourceObservation: JSON;
    onChangeSourceObservation: (value: string) => void;
}

export function SourceObservation({
    sourceObservation,
    onChangeSourceObservation,
}: SourceObservationProps) {
    return (
        <CodeMirror
            value={objectToDisplay(sourceObservation)}
            options={{
                lineNumbers: false,
                mode: 'yaml',
            }}
            onChange={(_editor, _change, value) => onChangeSourceObservation(value)}
        />
    );
}
