import { get } from 'lodash';
import { ErrorOption, FieldErrors } from 'react-hook-form';

export function getErrorByFieldName(errors: FieldErrors, name: string) {
    return get(errors, name);
}

export function formaError(error?: ErrorOption) {
    if (error?.type === 'required') {
        return <span>This field is required</span>;
    }
    if (error?.type === 'noEmptyRefPeriod') {
        return <span>{error.message || 'This field is required'}</span>;
    }
}
