import { DatePicker, notification } from 'antd';
import moment from 'moment';
import { useState } from 'react';

import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { service } from 'aidbox-react/lib/services/service';

import { useOrganizationId } from 'src/containers/OrganizationDetails/hooks';

import { getDescription } from '../AlertFailure';

export const createReport = async (organizationId: string, chosenDate: string[]) => {
    const url = `/Organization/${organizationId}/report/data-drift/$generate`;
    const response = await service({
        method: 'GET',
        url,
        params: {
            start: chosenDate[0],
            end: chosenDate[1],
        },
    });
    if (isSuccess(response)) {
        notification.success({ message: 'Data drift created' });
    }
    if (isFailure(response)) {
        notification.error({ message: getDescription(response.error) });
    }
    return response;
};

export const getDisabledDate = (current: any) => {
    return current && current > moment().endOf('day');
};

const { RangePicker } = DatePicker;

interface Props {
    updateDataDriftList: boolean;
    setUpdateDataDriftList: (update: boolean) => void;
}

export const useDataDriftDownload = ({ updateDataDriftList, setUpdateDataDriftList }: Props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [chosenDate, setChosenDate] = useState<string[]>([]);

    const organizationId = useOrganizationId();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = async () => {
        if (organizationId) {
            await createReport(organizationId, chosenDate);
            setIsModalVisible(false);
            setUpdateDataDriftList(!updateDataDriftList);
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const chooseDate = (date: any, dateString: string[]) => {
        if (dateString[0].length === 0 || dateString[1].length === 0) {
            return;
        }
        const start = new Date(dateString[0]).toISOString().slice(0, 19) + 'Z';
        const end = new Date(dateString[1]).toISOString().slice(0, 19) + 'Z';
        setChosenDate([start, end]);
    };

    return {
        showModal,
        isModalVisible,
        handleOk,
        handleCancel,
        getDisabledDate,
        chooseDate,
        RangePicker,
    };
};
