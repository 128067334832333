import { notification } from 'antd';
import { AxiosRequestConfig } from 'axios';
import _ from 'lodash';
import { CSSProperties, useCallback, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess } from 'aidbox-react/lib/libs/remoteData';
import { getFHIRResource } from 'aidbox-react/lib/services/fhir';
import { service } from 'aidbox-react/lib/services/service';

import { saveSISResource } from 'src/services/sis';
import { sharedFeatures } from 'src/sharedState';
import { displayToObject } from 'src/utils/yaml';

import observationExample from './observationExample.json';

enum ExpandableAction {
    SourceObservation = 'sourceObservation',
    Normalization = 'normalization',
    ResultedObservation = 'resultedObservation',
    Default = 'default',
}

interface ExpandableState {
    sourceObservation: boolean;
    normalization: boolean;
    resultedObservation: boolean;
}

export interface Expandable {
    sourceObservationOnClick: () => void;
    normalizationOnClick: () => void;
    resultedObservationOnClick: () => void;
    sourceObservationStyle?: CSSProperties;
    normalizationStyle?: CSSProperties;
    resultedObservationStyle?: CSSProperties;
}

const expandableState: ExpandableState = {
    sourceObservation: false,
    normalization: false,
    resultedObservation: false,
};

function reducer(state: ExpandableState, action: { type: ExpandableAction }) {
    switch (action.type) {
        case ExpandableAction.SourceObservation:
            return {
                sourceObservation: true,
                normalization: false,
                resultedObservation: false,
            };
        case ExpandableAction.Normalization:
            return {
                sourceObservation: false,
                normalization: true,
                resultedObservation: false,
            };
        case ExpandableAction.ResultedObservation:
            return {
                sourceObservation: false,
                normalization: false,
                resultedObservation: true,
            };
        case ExpandableAction.Default:
            return {
                sourceObservation: false,
                normalization: false,
                resultedObservation: false,
            };
        default:
            throw new Error();
    }
}

const largeWidth = { width: '300%' };

export const saveNomrlaizationData = async (normalizationValue: string, mappingId?: string) => {
    if (normalizationValue === '') {
        return window.history.back();
    }
    const mapping = displayToObject(normalizationValue).body;
    const response = await saveSISResource({
        resourceType: 'Mapping',
        id: mappingId,
        body: mapping,
    });
    if (isSuccess(response)) {
        notification.success({
            message: 'Changes saved',
            duration: 2,
            placement: 'bottomRight',
        });
        return window.history.back();
    }
    if (isFailure(response)) {
        console.error(JSON.stringify(response.error));
        return notification.error({ message: JSON.stringify(response.error) });
    }
};

export const useConfigureMapper = () => {
    const [state, dispatch] = useReducer(reducer, expandableState);

    const expandable: Expandable = {
        sourceObservationOnClick: () =>
            state.sourceObservation
                ? dispatch({ type: ExpandableAction.Default })
                : dispatch({ type: ExpandableAction.SourceObservation }),
        normalizationOnClick: () =>
            state.normalization
                ? dispatch({ type: ExpandableAction.Default })
                : dispatch({ type: ExpandableAction.Normalization }),
        resultedObservationOnClick: () =>
            state.resultedObservation
                ? dispatch({ type: ExpandableAction.Default })
                : dispatch({ type: ExpandableAction.ResultedObservation }),
        sourceObservationStyle: state.sourceObservation ? largeWidth : {},
        normalizationStyle: state.normalization ? largeWidth : {},
        resultedObservationStyle: state.resultedObservation ? largeWidth : {},
    };

    const params = useParams();

    const mappingId = params.mappingId;

    const prenosisObservationCode = params.prenosisObservationCode;

    if (!prenosisObservationCode) {
        console.error('No prenosis observation code from params');
        throw new Error();
    }

    const sourceObservationExample = JSON.parse(
        JSON.stringify({ ...observationExample, compositionId: 'demo' }),
    );

    const [sourceObservation, setSourceObservation] = useState(sourceObservationExample);

    const [normalizationValue, setNormalizationValue] = useState('');

    const [mappingRD] = useService(
        async () => await getFHIRResource({ resourceType: 'Mapping', id: mappingId as string }),
    );

    const features = sharedFeatures.getSharedState();

    const unit = features?.[prenosisObservationCode]?.['unit'] || {
        code: '',
        display: '',
        system: '',
    };

    const unitData = {
        unitCode: unit.code,
        unitDisplay: unit.display,
        unitSystem: unit.system,
    };

    const extremeRange = features?.[prenosisObservationCode]?.['extremeRange'];

    const [debugResultRD] = useService(async () => {
        const config: AxiosRequestConfig = normalizationValue.length
            ? {
                  data: {
                      mapping: displayToObject(normalizationValue),
                      scope: {
                          ...sourceObservation,
                          prenosisObservationCode,
                          ...unitData,
                          extremeRange,
                          compositionId: 'demo',
                      },
                  },
                  method: 'POST',
                  url: `/Mapping/$debug`,
              }
            : {
                  data: {
                      ...sourceObservation,
                      prenosisObservationCode,
                      ...unitData,
                      extremeRange,
                  },
                  method: 'POST',
                  url: `/Mapping/${mappingId}/$debug`,
              };
        return await service(config);
    }, [sourceObservation, normalizationValue]);

    const updateSourceObservation = (value: string) => setSourceObservation(displayToObject(value));

    const updateNormalization = (value: string) => setNormalizationValue(value);

    const onChangeSourceObservation = useCallback(_.debounce(updateSourceObservation, 1000), [
        updateSourceObservation,
    ]);

    const onChangeNormalization = useCallback(_.debounce(updateNormalization, 1000), [
        updateNormalization,
    ]);

    const saveNormalization = async () => {
        await saveNomrlaizationData(normalizationValue, mappingId);
    };

    return {
        sourceObservationExample,
        onChangeSourceObservation,
        mappingRD,
        debugResultRD,
        mappingId,
        expandable,
        saveNormalization,
        onChangeNormalization,
    };
};
