import { HelpIcon } from 'src/icons/HelpIcon';

import s from './HelpCenterButton.module.scss';

interface Props {
    setShowHelpCenter: (show: boolean) => void;
}

export const HelpCenterButton = ({ setShowHelpCenter }: Props) => {
    return (
        <div className={s.container}>
            <div className={s.clinicalContainer}>
                <div className={s.modeIcon}>
                    <HelpIcon/>
                </div>
                <div>
                <div className={s.sepsis}>Sepsis ImmunoScore™</div>
                <div className={s.howDoesItWork} onClick={() => setShowHelpCenter(true)}>
                    How does it work and what does it mean?
                </div>
                </div>
            </div>
        </div>
    );
};
