import { notification } from 'antd';

import { isFailure, RemoteData } from 'aidbox-react/lib/libs/remoteData';

import { OperationOutcomeIssue } from 'shared/src/contrib/aidbox';

export const getErrorDescription = (
    resourceType: 'Organization' | 'OrganizationConfig',
    error: string,
) => {
    if (resourceType === 'Organization') {
        return `Organization save failure: ${error}`;
    }

    if (resourceType === 'OrganizationConfig') {
        return `Organization has been saved without data drift settings: ${error}`;
    }
};

export const showNotificationError = (
    response: RemoteData<any>,
    resourceType: 'Organization' | 'OrganizationConfig',
) => {
    if (isFailure(response)) {
        if (response.error === 'Network Error') {
            notification['error']({
                message: 'Error',
                description: getErrorDescription(resourceType, JSON.stringify(response.error)),
            });
            return response;
        }
        const errors: string[] = [];
        response.error.issue.map((error: OperationOutcomeIssue) => {
            errors.push(
                String(
                    error.expression
                        ? String(error.expression?.[0] + ': ' + error.diagnostics)
                        : error.details?.text,
                ),
            );
        });
        notification['error']({
            message: 'Error',
            description: getErrorDescription(resourceType, errors.join('\n')),
        });
        return response;
    }
};
