import sepsisBody from './images/sepsisBody.png';
import sepsisHeart from './images/sepsisHeart.png';
import sepsisMan from './images/sepsisMan.png';
import sepsisVirus from './images/sepsisVirus.png';
import s from './Labeling.module.scss';

export const Labeling = () => {
    return (
        <div className={s.labeling}>
            <h2>Indications for Use</h2>
            <p>
                The Sepsis ImmunoScore is an Artificial Intelligence/Machine Learning (AI/ML)-Based
                Software that identifies patients at risk for having or developing sepsis.
            </p>
            <p>
                The Sepsis ImmunoScore uses up to 22 predetermined inputs from the patient’s electronic
                health record to generate a risk score and to assign the patient to one of four discrete
                risk stratification categories, based on the increasing risk of sepsis.
            </p>
            <p>
                The Sepsis ImmunoScore is intended to be used in conjunction with other laboratory findings
                and clinical assessments to aid in the risk assessment for presence of or progression to
                sepsis within 24 hours of patient assessment. It is intended to be used for patients admitted
                to the Emergency Department (ED) or hospital for whom sepsis is suspected and a blood culture
                was ordered as part of the evaluation for sepsis. It should not be used as the sole basis to
                determine the presence of sepsis or risk of developing sepsis within 24 hours.
            </p>
            <h2>Patient Population</h2>
            <p>
                The Sepsis ImmunoScore is intended to be used for adult patients, 18 years old or
                older, with indicated suspicion of serious infection, as defined by the first order
                of a blood culture in an ED or hospital environment.
            </p>
            <p>
                The Sepsis ImmunoScore has not been validated for use in specific inpatient
                settings such as ICU or Labor and Delivery units.
            </p>
            <h2>Intended User</h2>
            <p>
                The Sepsis ImmunoScore is intended to be used by licensed healthcare providers.
            </p>
            <h2>Definition of Sepsis</h2>
            <p>
                <b>
                    Life-threatening organ dysfunction caused by a dysregulated host response to
                    infection.
                </b>
                <br/>
                <i>
                    – The Third International Consensus Definitions for Sepsis and Septic Shock
                    (Sepsis-3)
                </i>
            </p>
            <p>
                Historically there have been multiple definitions and terminologies used for sepsis
                and septic shock. The Sepsis ImmunoScore uses the most recent definition: The Third
                International Consensus Definitions for Sepsis and Septic Shock (Sepsis-3).
            </p>
            <p>
                The differences between the previous and current definitions are outlined in the
                diagram below.
            </p>
            <table className={s.tableSepsis}>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColNarrow}>Sepsis-3</th>
                    <th className={s.labColRegular}>Sepsis</th>
                    <th className={s.labColRegular}>Septic Shock</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisVirus} alt="Virus" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}>Infection</td>
                    <td className={s.labColRegular}>Infection</td>
                </tr>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisBody} alt="Body" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}>
                        Organ Dysfunction (Acute Change in SOFA Score ≥ 2)
                    </td>
                    <td className={s.labColRegular}>
                        Organ Dysfunction (Acute Change in SOFA Score ≥ 2)
                    </td>
                </tr>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisHeart} alt="Heart" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}></td>
                    <td className={s.labColRegular}>Refactory Hypotension</td>
                </tr>
                </tbody>
            </table>
            <table className={s.tableSepsis}>
                <thead className={s.tableHeader}>
                <tr>
                    <th className={s.labColNarrow}>Sepsis-2</th>
                    <th className={s.labColRegular}>Sepsis</th>
                    <th className={s.labColRegular}>Severe Sepsis</th>
                    <th className={s.labColRegular}>Septic Shock</th>
                </tr>
                </thead>
                <div className={s.separator}/>
                <tbody>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisVirus} alt="Virus" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}>Infection</td>
                    <td className={s.labColRegular}>Infection</td>
                    <td className={s.labColRegular}>Infection</td>
                </tr>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisMan} alt="Man" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}>SIRS ≥ 2</td>
                    <td className={s.labColRegular}>SIRS ≥ 2</td>
                    <td className={s.labColRegular}>SIRS ≥ 2</td>
                </tr>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisBody} alt="Body" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}></td>
                    <td className={s.labColRegular}>
                        Organ Dysfunction (Acute Change in SOFA Score ≥ 2)
                    </td>
                    <td className={s.labColRegular}>
                        Organ Dysfunction (Acute Change in SOFA Score ≥ 2)
                    </td>
                </tr>
                <tr>
                    <td className={s.labColNarrow}>
                        <img src={sepsisHeart} alt="Heart" className={s.illustration}/>
                    </td>
                    <td className={s.labColRegular}></td>
                    <td className={s.labColRegular}></td>
                    <td className={s.labColRegular}>Refactory Hypotension</td>
                </tr>
                </tbody>
            </table>
            <h2>Product Description</h2>
            <p>
                The Sepsis ImmunoScore is an Artificial Intelligence/Machine Learning (AI/ML)-Based
                Software as a Medical Device (SaMD). To meet its intended use, the Sepsis
                ImmunoScore inputs up to 22 parameters, calculates a sepsis risk score using a
                locked algorithm, and outputs the Sepsis Patient View, a screen that displays the
                risk score and supplementary information.
            </p>
        </div>
    );
};
