import React, { useEffect } from 'react';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { resetInstanceToken, setInstanceToken } from 'aidbox-react/lib/services/instance';
import { sequenceMap, service } from 'aidbox-react/lib/services/service';

import { User } from 'shared/src/contrib/aidbox';

import { getToken, getUserInfo, removeToken, setSessionId } from 'src/services/auth';
import { extractErrorCode } from 'src/utils/error';

import { sharedFeatures, sharedScoreConfig } from '../../sharedState';
import { Features, ScoreConfig } from '../ImmunoScoreDetails/types';

const token = getToken();

interface Context {
    user: User | null;
    features: Features | null;
}

export const InitialDataContext = React.createContext<Context | null>(null);

export const useApp = () => {
    const [userRD] = useService(async () => {
        if (!token) {
            return success(null);
        }
        setInstanceToken(JSON.parse(token));
        const response = await getUserInfo();
        if (isFailure(response) && extractErrorCode(response.error) !== 'network_error') {
            resetInstanceToken();
            removeToken();
            location.reload();
            return success(null);
        }

        return response;
    });

    const [featuresRD] = useService(async () => {
        if (!token) {
            return success(null);
        }
        const response = await service<Features>({ url: '/Observation/$features' });
        if (isSuccess(response)) {
            sharedFeatures.setSharedState(response.data);
        }
        return response;
    });
    const [scoreConfigRD] = useService(async () => {
        if (!token) {
            return success(null);
        }
        const response = await service<ScoreConfig>({ url: '/$get-score-config' });
        if (isSuccess(response)) {
            sharedScoreConfig.setSharedState(response.data);
        }
        return response;
    });

    useEffect(() => {
        setSessionId();
    }, []);

    const initialDataRD = sequenceMap({
        user: userRD,
        features: featuresRD,
        scoreConfig: scoreConfigRD,
    });
    return { initialDataRD, InitialDataContext };
};
