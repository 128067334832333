import { CodeableConcept, Composition, dateTime } from 'shared/src/contrib/aidbox';

import { RiskCategoryHeader } from 'src/components/RiskCategorySummary/RiskCategoryHeader';
import { ScoreView } from 'src/components/RiskCategorySummary/ScoreView';
import { ScoreDetails } from 'src/containers/ImmunoScoreDetails/types';
import { OrderStatus } from 'src/utils/score';

import { DatetimeView } from './DatetimeView';
import { HelpCenterButton } from './HelpCenterButton';
import s from './RiskCategorySummary.module.scss';
import { UsageMode } from './UsageMode';

interface Props {
    status: ScoreDetails['status'];
    orderTime: ScoreDetails['orderTime'];
    immunoScoreValue?: number;
    resultTime?: dateTime;
    usageMode?: Composition['usageMode'];
    interpretation?: CodeableConcept;
    setShowHelpCenter: (show: boolean) => void;
}

const getSeparatorStyle = (status: OrderStatus) => {
    if (status === OrderStatus.Waiting) {
        return s.separatorWaiting;
    }

    if (status === OrderStatus.Failure) {
        return s.separatorFailure;
    }

    return s.separator;
};

export function RiskCategorySummary(props: Props) {
    const {
        status,
        immunoScoreValue,
        orderTime,
        resultTime,
        usageMode,
        interpretation,
        setShowHelpCenter,
    } = props;

    return (
        <div className={s.wrapper}>
            <ScoreView status={status} value={immunoScoreValue} interpretation={interpretation}/>
            <div className={s.rightSideWrapper}>
                <RiskCategoryHeader
                    status={status}
                    value={immunoScoreValue}
                    interpretation={interpretation}
                />
                <div className={getSeparatorStyle(status)} />
                <div className={s.timeWrapper}>
                    <DatetimeView type="order" time={orderTime} />
                    <DatetimeView type="result" time={resultTime} />
                </div>
            </div>
            <UsageMode usageMode={usageMode} />
            <HelpCenterButton setShowHelpCenter={setShowHelpCenter} />
        </div>
    );
}
