import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useService } from 'aidbox-react/lib/hooks/service';
import { isFailure, isSuccess, success } from 'aidbox-react/lib/libs/remoteData';
import { getFHIRResource } from 'aidbox-react/lib/services/fhir';

import { Organization } from 'shared/src/contrib/aidbox';

import { sharedOrganization } from 'src/sharedState';

import { MenuItem } from '../AppMenu/useAppMenu';

const menu: MenuItem[] = [
    {
        key: 'organization',
        path: '',
        title: 'Organization',
    },
    {
        key: 'normalization',
        path: 'normalization',
        title: 'Normalization',
    },
    {
        key: 'normalranges',
        path: 'normalranges',
        title: 'Normal Ranges',
    },
    {
        key: 'reports',
        path: 'reports',
        title: 'Data Drift Reports',
    },
];

const defaultRoute = (pathname: string) => {
    const pathArray = pathname.split('/');
    const defaultPathArray = [pathArray[1], pathArray[2]];
    return '/' + defaultPathArray.join('/') + '/';
};

const currentRoute = (pathname: string) => {
    if (pathname.includes('normalization')) {
        return 'normalization';
    }
    if (pathname.includes('normalranges')) {
        return 'normalranges';
    }
    if (pathname.includes('reports')) {
        return 'reports';
    }
    return 'organization';
};

export const useOrganizationHeader = (organizationId: string) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const [current] = useState(currentRoute(pathname));

    const organization = sharedOrganization.getSharedState();

    const [organizationName, setOrganizationName] = useState<string | undefined | null>(
        organization?.name,
    );

    useService(async () => {
        const response = await getFHIRResource<Organization>({
            resourceType: 'Organization',
            id: organizationId,
        });
        if (isSuccess(response) && response.data.name) {
            sharedOrganization.setSharedState(response.data);
            setOrganizationName(response.data.name);
        }
        if (isFailure(response)) {
            sharedOrganization.setSharedState(null);
            setOrganizationName(null);
        }
        return success(null);
    });

    return { navigate, current, menu, defaultRoute, pathname, organizationName };
};
