import { createSharedState } from 'aidbox-react/lib/hooks/shared-state';

import { Organization } from 'shared/src/contrib/aidbox';

import { Features, ScoreConfig } from '../containers/ImmunoScoreDetails/types';

export const sharedOrganization = createSharedState<Partial<Organization> | null>({
    resourceType: 'Organization',
});

export const sharedFeatures = createSharedState<Features>({});

export const sharedScoreConfig = createSharedState<ScoreConfig | null>();
